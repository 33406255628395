import {
  ADMIN_SET_PRODUCT_QUOTA,
  API,
  ASSETS,
  COLLAB_DEBUG,
  CUSTOM_MODELS,
  EDITOR_DEBUG,
  EMAIL_LINK_SIGNIN,
  GENERATE_VIDEO_DEBUG,
  INVITE_CODE,
  LOGIN,
  MOBILE,
  MOBILE_ERROR,
  NEW_CHECKOUT_SESSION,
  NEW_CUSTOM_MODEL,
  NEW_PROJECT,
  ONBOARDING,
  PROJECTS,
  TEMPLATES,
  VIDEOS,
} from "@/components/constants/routes";
import { CreateCustomModel } from "@/components/custom-model/create-custom-model";
import { CustomModelEditor } from "@/components/custom-model/custom-model-editor";
import { ApiPlaygroundRouter } from "@/components/dashboard/api";
import { CreateNewProject } from "@/components/dashboard/create-new-project";
import { Dashboard } from "@/components/dashboard/dashboard";
import { EditorInternal } from "@/components/editor/canvas-editor";
import { EditorRouter } from "@/components/editor/editor-router";
import { NotFound } from "@/components/not-found";
import { GenerateVideoEditorDebug } from "@/components/video/generate-video-editor";
import { editorContextStore } from "@/contexts/editor-context";
import { DashboardType } from "@/core/common/types";
import { AppRoleType } from "@/core/common/types/user-roles";
import { getTeamUserRole } from "@/core/utils/user-role-utils";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { FinishEmailLogin } from "./auth/finish-email-login";
import { FullScreenInviteCodeInput } from "./auth/invite-code";
import { LoginFullscreen } from "./auth/login-fullscreen";
import { MobileRedirectErrorFullscreen, MobileRedirectFullscreen } from "./auth/require-desktop";
import { NewCheckoutSession } from "./checkout/checkout-session";
import { SetProductQuota } from "./checkout/set-product-quotas";
import { CollabDebug } from "./internal/collab-debug";
import { Onboarding } from "./onboard/onboarding";
import { ProtectedRoute } from "./protected-routes";

export function AppRoutes() {
  const userRole = getTeamUserRole(editorContextStore((state) => state));
  const [defaultDashboard, setDefaultDashboard] = useState<DashboardType>(null);
  useEffect(() => {
    const dashboard: DashboardType =
      userRole === AppRoleType.Owner
        ? "templates"
        : userRole === AppRoleType.Writer
          ? "models"
          : userRole === AppRoleType.Reader
            ? "assets"
            : null;

    setDefaultDashboard(dashboard);
  }, [userRole]);

  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<Dashboard dashboardType={defaultDashboard} />} />
      <Route path={`/${LOGIN}`} element={<LoginFullscreen />} />
      <Route path={`/${INVITE_CODE}`} element={<FullScreenInviteCodeInput />} />
      <Route path={`/${EMAIL_LINK_SIGNIN}`} element={<FinishEmailLogin />} />
      <Route path={`/${MOBILE}`} element={<MobileRedirectFullscreen />} />
      <Route path={`/${MOBILE_ERROR}`} element={<MobileRedirectErrorFullscreen />} />
      <Route path={`/${ONBOARDING}`} element={<Onboarding />} />
      <Route path={`/${ONBOARDING}`} element={<Onboarding />} />
      <Route path={`/${COLLAB_DEBUG}`} element={<CollabDebug />} />
      <Route path={`/${NEW_CHECKOUT_SESSION}`} element={<NewCheckoutSession />} />
      <Route path={`/${ADMIN_SET_PRODUCT_QUOTA}`} element={<SetProductQuota />} />
      <Route path="*" element={<NotFound />} />

      {/* Protected routes */}
      <Route element={<ProtectedRoute roles={[AppRoleType.Writer, AppRoleType.Owner]} />}>
        <Route path={`/${CUSTOM_MODELS}`} element={<Dashboard dashboardType="models" />} />
        <Route path={`/${CUSTOM_MODELS}/:modelId`} element={<CustomModelEditor />} />
        <Route path={`/${NEW_CUSTOM_MODEL}`} element={<CreateCustomModel />} />
        <Route path={`/${NEW_CUSTOM_MODEL}/:workflow`} element={<CreateCustomModel />} />
      </Route>

      <Route element={<ProtectedRoute roles={[AppRoleType.Owner]} />}>
        <Route path={`/${TEMPLATES}`} element={<Dashboard dashboardType="templates" />} />
        <Route path={`/${PROJECTS}`} element={<Dashboard dashboardType="projects" />} />
        <Route path={`/${PROJECTS}/:projectId`} element={<EditorRouter />} />
        <Route path={`/${NEW_PROJECT}`} element={<CreateNewProject />} />
        <Route path={`/${NEW_PROJECT}/:projectType`} element={<CreateNewProject />} />
        <Route path={`/${VIDEOS}`} element={<Dashboard dashboardType="videos" />} />
        <Route path={`/${GENERATE_VIDEO_DEBUG}`} element={<GenerateVideoEditorDebug />} />
        <Route path={`/${API}`} element={<Dashboard dashboardType="manage-api" />} />
        <Route path={`/${API}/generate-image`} element={<Dashboard dashboardType="manage-api" />} />
        <Route path={`/${API}/:apiId`} element={<ApiPlaygroundRouter />} />
        <Route path={`/${EDITOR_DEBUG}`} element={<EditorInternal />} />
      </Route>

      <Route element={<ProtectedRoute roles={[AppRoleType.Reader]} />}>
        <Route path={`/${ASSETS}`} element={<Dashboard dashboardType="assets" />} />
        <Route path={`/${ASSETS}:modelId`} element={<Dashboard dashboardType="assets" />} />
      </Route>
    </Routes>
  );
}
