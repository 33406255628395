import { ASSETS, CUSTOM_MODELS, TEMPLATES } from "@/components/constants/routes";
import { editorContextStore } from "@/contexts/editor-context";
import { EditorState } from "@/core/common/interfaces";
import {
  AppRoleType,
  canRoleRead,
  canRoleWrite,
  isRoleReader,
  isRoleWriter,
} from "@/core/common/types/user-roles";
import { getCurrentTeamId } from "@/core/utils/team-utils";

export function getTeamUserRole(editorContextState: EditorState) {
  const { publicUserId, userTeams } = editorContextState;
  const currentTeamId = getCurrentTeamId();
  if (!currentTeamId || !publicUserId) {
    return undefined;
  }

  const role = userTeams[currentTeamId]?.roles?.[publicUserId];

  return role || undefined;
}

export function useTeamUserRole() {
  return editorContextStore(getTeamUserRole);
}

export function doesUserHaveTeamFullPermission(editorContextState: EditorState) {
  return getTeamUserRole(editorContextState) === AppRoleType.Owner;
}

export function doesUserHaveTeamReadPermission(editorContextState: EditorState) {
  return canRoleRead(getTeamUserRole(editorContextState));
}

export function doesUserHaveTeamWritePermission(editorContextState: EditorState) {
  return canRoleWrite(getTeamUserRole(editorContextState));
}

export function isRoleTeamMember(editorContextState: EditorState) {
  return (
    isRoleReader(getTeamUserRole(editorContextState)) ||
    isRoleWriter(getTeamUserRole(editorContextState))
  );
}

export function getDefaultNavigateForRole(role: AppRoleType | undefined): string {
  switch (role) {
    case AppRoleType.Owner:
      return `/${TEMPLATES}`;
    case AppRoleType.Writer:
      return `/${CUSTOM_MODELS}`;
    case AppRoleType.Reader:
      return `/${ASSETS}`;
    default:
      return `/`;
  }
}
