import { CURRENT_TEAM_ID_KEY } from "@/components/constants/backend";
import { editorContextStore } from "@/contexts/editor-context";
import { PublicTeamId } from "@/core/common/types/team";

export function getCurrentTeamId(): PublicTeamId | null {
  const savedTeamId = localStorage.getItem(CURRENT_TEAM_ID_KEY);
  if (savedTeamId) {
    return savedTeamId as PublicTeamId;
  }
  return editorContextStore.getState().currentTeamId;
}
