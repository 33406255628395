import * as Dialog from "@radix-ui/react-dialog";
import React from "react";
import {
  CustomModelImageEditorFixProductDetailsProvider,
  CustomModelImageEditorMode,
  customModelImageEditorModeToNames,
  CustomModelImageEditorProps,
  CustomModelImageEditorProvider,
  loadImageFromCustomModelPredictionOutput,
  useCustomModelImageEditorActiveMode,
  useCustomModelImageEditorContext,
  useSetCustomModelImageEditorActiveModeCallback,
} from "./custom-model-image-editor-context";

import {
  getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput,
  getImageSizeFromCustomModelPredictionInput,
} from "@/core/common/types";
import { classNames } from "@/core/utils/classname-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { getPromptStateFromText } from "@/core/utils/text-utils";
import { preprocessImageUrl } from "@/core/utils/url-utils";
import { Cross1Icon } from "@radix-ui/react-icons";
import {
  DropdownClassName,
  PrimaryButtonClassName,
  SecondaryButtonClassNameDisabled,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { FloatTagZIndex } from "components/constants/zIndex";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { downloadUrl } from "components/utils/data";
import { ImageComponent } from "components/utils/image";
import { openAnimateVideoWindow } from "components/video/open-animate-image-window";
import { editorContextStore } from "contexts/editor-context";
import { Download, Expand, RotateCcw, ScanFace, SquarePen, Stamp, User } from "lucide-react";
import { ImageToVideoIcon } from "../video/image-to-video-icon";
import { mainPanelClassName, toolbarPanelClassName } from "./classnames";
import { CustomModelImageEditorFixTextEditor } from "./custom-model-fix-text";
import {
  CustomModelFixTextContextProvider,
  useCustomModelFixTextContext,
} from "./custom-model-fix-text-context";
import styles from "./custom-model-image-editor.module.css";
import { CustomModelCompareOutput } from "./custom-model-image-output";
import { CustomModelCompareOutputContextProvider } from "./custom-model-image-output-context";
import { CustomModelImageVariations } from "./custom-model-image-variations";
import {
  CustomModelImageVariationsContextProvider,
  useCustomModelImageVariationsContext,
} from "./custom-model-image-variations-context";
import { getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState } from "./custom-model-mention-plugin";
import { CustomModelRegenerateHuman } from "./custom-model-regenerate-human";
import {
  CustomModelRegenerateHumanContextProvider,
  useCustomModelRegenerateHumanContext,
} from "./custom-model-regenerate-human-context";
import { CustomModelImageEditorRegenerateProduct } from "./custom-model-regenerate-product";
import {
  CustomModelImageEditorRegenerateProductInitStatus,
  CustomModelImageEditorRegenerateProductProvider,
  useCustomModelImageEditorRegenerateProductContext,
} from "./custom-model-regenerate-product-context";
import { CustomModelUpscaleCreative } from "./custom-model-upscale-creative";
import {
  CustomModelUpscaleContextProvider,
  useCustomModelUpscaleContext,
} from "./custom-model-upscale-creative-context";
import { CustomModelUpscaleFace } from "./custom-model-upscale-face";
import {
  CustomModelUpscaleFaceContextProvider,
  useCustomModelUpscaleFaceContext,
} from "./custom-model-upscale-face-context";
import { CustomModelVirtualTryOn } from "./custom-model-virtual-tryon";
import { CustomModelVirtualTryOnContextProvider } from "./custom-model-virtual-tryon-context";

const leftPanelButtonIconSize = 16;

/* @tw */
const leftPanelButtonContainerClassName = classNames(
  SecondaryButtonClassNameInactive,
  "px-3 py-2.5 group flex flex-col items-stretch gap-2",
);

/* @tw */
const leftPanelButtonIconClassName = classNames(
  "text-zinc-500 group-hover:text-lime-500 transition-colors",
);

/* @tw */
const leftPanelButtonTitleClassName = classNames(
  "flex flex-row items-center gap-2 text-base font-semibold",
);

/* @tw */
const leftPanelButtonSubtitleClassName =
  "pl-6 text-sm text-zinc-500 group-hover:text-lime-500 transition-colors";

function FixTextButton() {
  const backend = editorContextStore((state) => state.backend);

  const { imageUrl, prediction } = useCustomModelImageEditorContext();

  const { setTargetImageUrl, setTargetMaskUrl } = useCustomModelFixTextContext();

  const setMode = useSetCustomModelImageEditorActiveModeCallback();

  return (
    <button
      className={classNames(
        !backend || !imageUrl || !prediction
          ? SecondaryButtonClassNameDisabled
          : SecondaryButtonClassNameInactive,
        leftPanelButtonContainerClassName,
      )}
      onClick={() => {
        if (!backend || !imageUrl || !prediction) {
          return;
        }

        setTargetImageUrl(imageUrl);
        setTargetMaskUrl(undefined);

        setMode(CustomModelImageEditorMode.FixText);
      }}
    >
      <div className={leftPanelButtonTitleClassName}>
        <Stamp size={leftPanelButtonIconSize} className={leftPanelButtonIconClassName} />
        {customModelImageEditorModeToNames[CustomModelImageEditorMode.FixText]}
      </div>
      <div className={leftPanelButtonSubtitleClassName}>
        Fix the small artifacts (e.g. Logos and texts) in this image by copying details from a
        reference image.
      </div>
    </button>
  );
}

function RegenerateProductButton() {
  const backend = editorContextStore((state) => state.backend);
  const { imageUrl, prediction } = useCustomModelImageEditorContext();

  const { setInitStatus, setMaskImageUrl, setPromptEditorState } =
    useCustomModelImageEditorRegenerateProductContext();

  const setMode = useSetCustomModelImageEditorActiveModeCallback();

  const predictionInput = React.useMemo(
    () =>
      prediction?.input &&
      getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(prediction?.input),
    [prediction?.input],
  );

  return (
    <button
      className={classNames(leftPanelButtonContainerClassName)}
      onClick={() => {
        if (!backend || !imageUrl || !prediction) {
          return;
        }

        const promptJson = predictionInput?.promptJson;

        if (promptJson) {
          try {
            const promptEditorState =
              getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
                promptEditorState: JSON.parse(
                  promptJson || getPromptStateFromText(predictionInput.prompt),
                ),
              });

            setPromptEditorState(promptEditorState);
          } catch (error) {
            debugError(
              "Error setting prompt editor state with prediction input ",
              predictionInput,
              "\n",
              error,
            );
          }
        }

        setInitStatus(CustomModelImageEditorRegenerateProductInitStatus.None);

        setMaskImageUrl(undefined);

        setMode(CustomModelImageEditorMode.RegenerateProduct);
      }}
    >
      <div className={leftPanelButtonTitleClassName}>
        <RotateCcw size={leftPanelButtonIconSize} className={leftPanelButtonIconClassName} />
        {customModelImageEditorModeToNames[CustomModelImageEditorMode.RegenerateProduct]}
      </div>
      <div className={leftPanelButtonSubtitleClassName}>
        Re-generate or replace the product in this image.
      </div>
    </button>
  );
}

function AnimateImageButton() {
  const { imageUrl, prediction } = useCustomModelImageEditorContext();

  const imageSize = React.useMemo(() => {
    try {
      const input = prediction?.input;

      if (!input) {
        return undefined;
      }

      return getImageSizeFromCustomModelPredictionInput(input);
    } catch (error) {
      debugError("Error retrieving image size from prediction ", prediction, "\n", error);

      return undefined;
    }
  }, [prediction]);

  return (
    <button
      className={classNames(leftPanelButtonContainerClassName)}
      onClick={() => {
        if (!imageSize) {
          return;
        }

        const { width, height } = imageSize;

        openAnimateVideoWindow({
          width,
          height,
          imageUrl,
        });
      }}
    >
      <div className={leftPanelButtonTitleClassName}>
        <ImageToVideoIcon size={leftPanelButtonIconSize} className={leftPanelButtonIconClassName} />
        <span>Animate image</span>
      </div>
      <div className={leftPanelButtonSubtitleClassName}>Generate a video from this image.</div>
    </button>
  );
}

function RegenerateHumanButton() {
  const { prediction } = useCustomModelImageEditorContext();

  const { setPromptEditorState } = useCustomModelRegenerateHumanContext();

  const predictionInput = React.useMemo(
    () =>
      prediction?.input &&
      getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(prediction?.input),
    [prediction?.input],
  );

  const setMode = useSetCustomModelImageEditorActiveModeCallback();

  return (
    <button
      className={classNames(leftPanelButtonContainerClassName)}
      onClick={() => {
        if (!prediction) {
          return;
        }

        const promptJson = predictionInput?.promptJson;

        if (promptJson) {
          try {
            const promptEditorState =
              getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
                promptEditorState: JSON.parse(
                  promptJson || getPromptStateFromText(predictionInput.prompt),
                ),
              });

            console.log("Prompt editor state: ", promptEditorState);

            setPromptEditorState(promptEditorState);
          } catch (error) {
            debugError(
              "Error setting prompt editor state with prediction input ",
              predictionInput,
              "\n",
              error,
            );
          }
        }

        setMode(CustomModelImageEditorMode.RegenerateHuman);
      }}
    >
      <div className={leftPanelButtonTitleClassName}>
        <User size={leftPanelButtonIconSize} className={leftPanelButtonIconClassName} />
        {customModelImageEditorModeToNames[CustomModelImageEditorMode.RegenerateHuman]}
      </div>
      <div className={leftPanelButtonSubtitleClassName}>
        Replace the human in this image. Upload a reference human face to generate consistent
        characters.
      </div>
    </button>
  );
}

function MagicErase() {
  return (
    <button className={classNames(SecondaryButtonClassNameInactive)}>
      {customModelImageEditorModeToNames[CustomModelImageEditorMode.MagicErase]}
    </button>
  );
}

function VirtualTryOn() {
  const setMode = useSetCustomModelImageEditorActiveModeCallback();

  return (
    <button
      className={classNames(SecondaryButtonClassNameInactive)}
      onClick={() => {
        setMode(CustomModelImageEditorMode.VirtualTryOn);
      }}
    >
      {customModelImageEditorModeToNames[CustomModelImageEditorMode.VirtualTryOn]}
    </button>
  );
}

function Upscale() {
  return (
    <button className={classNames(SecondaryButtonClassNameInactive)}>
      {customModelImageEditorModeToNames[CustomModelImageEditorMode.Upscale]}
    </button>
  );
}

function UpscaleCreativeButton() {
  const { prediction, imageUrl } = useCustomModelImageEditorContext();

  const { setImageUrl, setPromptEditorState } = useCustomModelUpscaleContext();

  const predictionInput = React.useMemo(
    () =>
      prediction?.input &&
      getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(prediction?.input),
    [prediction?.input],
  );

  const setMode = useSetCustomModelImageEditorActiveModeCallback();

  return (
    <button
      className={classNames(leftPanelButtonContainerClassName)}
      onClick={() => {
        if (!prediction) {
          return;
        }

        const promptJson = predictionInput?.promptJson;

        if (promptJson) {
          try {
            const promptEditorState =
              getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
                promptEditorState: JSON.parse(
                  promptJson || getPromptStateFromText(predictionInput.prompt),
                ),
              });

            setImageUrl(imageUrl);

            setPromptEditorState(promptEditorState);
          } catch (error) {
            debugError(
              "Error setting prompt editor state with prediction input ",
              predictionInput,
              "\n",
              error,
            );
          }
        }

        setMode(CustomModelImageEditorMode.UpscaleCreative);
      }}
    >
      <div className={leftPanelButtonTitleClassName}>
        <Expand size={leftPanelButtonIconSize} className={leftPanelButtonIconClassName} />
        <span>{customModelImageEditorModeToNames[CustomModelImageEditorMode.UpscaleCreative]}</span>
      </div>
      <div className={leftPanelButtonSubtitleClassName}>
        Upscale image to 2k resolution. Add details and preserve existing details.
      </div>
    </button>
  );
}

function UpscaleFaceButton() {
  const { prediction, imageUrl } = useCustomModelImageEditorContext();

  const { setImageUrl } = useCustomModelUpscaleFaceContext();

  const predictionInput = React.useMemo(
    () =>
      prediction?.input &&
      getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(prediction?.input),
    [prediction?.input],
  );

  const setMode = useSetCustomModelImageEditorActiveModeCallback();

  return (
    <button
      className={classNames(leftPanelButtonContainerClassName)}
      onClick={() => {
        if (!prediction) {
          return;
        }

        // For UpscaleFace, we don't need to handle prompt states.
        // We simply set the image URL in our context.
        try {
          setImageUrl(imageUrl);
        } catch (error) {
          debugError(
            "Error setting image URL for face upscale with prediction input ",
            predictionInput,
            "\n",
            error,
          );
        }

        // Switch the mode to UpscaleFace
        setMode(CustomModelImageEditorMode.UpscaleFace);
      }}
    >
      <div className={leftPanelButtonTitleClassName}>
        <ScanFace size={16} className={leftPanelButtonIconClassName} />
        <span>{customModelImageEditorModeToNames[CustomModelImageEditorMode.UpscaleFace]}</span>
      </div>
      <div className={leftPanelButtonSubtitleClassName}>
        Upscale the face in the image and improve its details.
      </div>
    </button>
  );
}

function Inpainting() {
  return (
    <button className={classNames(SecondaryButtonClassNameInactive)}>
      {customModelImageEditorModeToNames[CustomModelImageEditorMode.Inpainting]}
    </button>
  );
}

function ImageVariationsButton() {
  const setMode = useSetCustomModelImageEditorActiveModeCallback();

  const { prediction } = useCustomModelImageEditorContext();

  const { setPromptEditorState } = useCustomModelImageVariationsContext();

  const predictionInput = React.useMemo(
    () =>
      prediction?.input &&
      getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(prediction?.input),
    [prediction?.input],
  );

  return (
    <button
      className={classNames(SecondaryButtonClassNameInactive)}
      onClick={() => {
        try {
          setMode(CustomModelImageEditorMode.GenerateVarations);

          const promptJson = predictionInput?.promptJson;

          const promptEditorState =
            getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
              promptEditorState: JSON.parse(
                promptJson || getPromptStateFromText(predictionInput?.prompt ?? ""),
              ),
            });

          setPromptEditorState(promptEditorState);

          debugLog(
            "Clicking on generate variations. Custom model prediction input prompt editor state: ",
            promptEditorState,
          );
        } catch (error) {
          debugError("Error clicking on the generate variations button: ", error);
        }
      }}
    >
      {customModelImageEditorModeToNames[CustomModelImageEditorMode.GenerateVarations]}
    </button>
  );
}

function CustomModelImageEditorDefault() {
  const storageManager = editorContextStore((state) => state.storageManager);
  const { imageUrl, prediction, setImageUrl } = useCustomModelImageEditorContext();

  const predictionInput = React.useMemo(
    () =>
      prediction?.input &&
      getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(prediction.input),
    [prediction],
  );

  const prompt = React.useMemo(() => {
    if (!predictionInput) {
      return;
    }

    const promptJson = predictionInput.promptJson;

    try {
      if (!promptJson) {
        return predictionInput.prompt;
      }

      const { text } = getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
        promptEditorState: JSON.parse(promptJson),
        replaceWithText: true,
      });

      return text;
    } catch (error) {
      debugError("Cannot load prediction prompt json: ", promptJson, "\n", error);
    }

    return predictionInput.prompt;
  }, [predictionInput]);

  return (
    <div className="relative py-2.5 w-full h-full flex flex-col items-stretch md:flex-row gap-4 md:gap-2">
      <div
        className={classNames(
          mainPanelClassName,
          "w-full h-fit max-h-[50%] md:w-auto md:h-full md:max-h-none flex-1",
        )}
      >
        <div className="rounded-md w-full h-full overflow-hidden bg-zinc-800/50">
          <ImageComponent
            src={imageUrl}
            className="object-contain w-full h-full"
            onError={(e) => {
              debugError(`Error loading image from url ${imageUrl}: `, e);

              loadImageFromCustomModelPredictionOutput({
                storageManager,
                prediction,
              }).then((url) => {
                setImageUrl(url ?? "");
              });
            }}
          />
        </div>
      </div>
      <ScrollAreaContainer className={classNames(toolbarPanelClassName)}>
        <div className="h-fit flex flex-col items-stretch gap-4">
          {/* <div
                        className={classNames(
                            "text-sm px-2 py-1 bg-zinc-800/50 text-zinc-500 rounded-md border border-zinc-800 transition-colors"
                        )}
                    >
                        {prompt}
                    </div> */}
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-zinc-300/50">Tools</div>
            <div className="flex flex-col gap-2">
              <UpscaleCreativeButton />
              <UpscaleFaceButton />
              <RegenerateProductButton />
              <RegenerateHumanButton />
              {/* <ImageVariationsButton /> */}
              <FixTextButton />
              <AnimateImageButton />
            </div>
          </div>
          <div className="py-4 flex flex-col items-stretch">
            <button
              className={classNames(
                PrimaryButtonClassName,
                "flex flex-row items-center justify-center gap-2 cursor-pointer",
              )}
              onClick={() => {
                debugLog("Download image from data url: ", imageUrl);

                const processedImageUrl = preprocessImageUrl(imageUrl);

                if (!processedImageUrl) {
                  return;
                }

                downloadUrl(processedImageUrl, "output");
              }}
            >
              <Download size={leftPanelButtonIconSize} />
              <span>Download image</span>
            </button>
          </div>
        </div>
      </ScrollAreaContainer>
    </div>
  );
}

function CustomModelImageEditorInner() {
  const { prediction, imageIndex, setImageUrl } = useCustomModelImageEditorContext();

  const mode = useCustomModelImageEditorActiveMode();

  const storageManager = editorContextStore((state) => state.storageManager);

  React.useEffect(() => {
    loadImageFromCustomModelPredictionOutput({
      storageManager,
      prediction,
      imageIndex,
    }).then((imageUrl) => {
      setImageUrl(imageUrl ?? "");
    });
  }, [storageManager, prediction, imageIndex, setImageUrl]);

  if (mode === CustomModelImageEditorMode.RegenerateProduct) {
    return <CustomModelImageEditorRegenerateProduct />;
  } else if (mode === CustomModelImageEditorMode.FixText) {
    return <CustomModelImageEditorFixTextEditor />;
  } else if (mode === CustomModelImageEditorMode.CompareOutputs) {
    return <CustomModelCompareOutput />;
  } else if (mode === CustomModelImageEditorMode.VirtualTryOn) {
    return <CustomModelVirtualTryOn />;
  } else if (mode === CustomModelImageEditorMode.RegenerateHuman) {
    return <CustomModelRegenerateHuman />;
  } else if (mode === CustomModelImageEditorMode.UpscaleCreative) {
    return <CustomModelUpscaleCreative />;
  } else if (mode === CustomModelImageEditorMode.GenerateVarations) {
    return <CustomModelImageVariations />;
  } else if (mode === CustomModelImageEditorMode.UpscaleFace) {
    return <CustomModelUpscaleFace />;
  }

  return <CustomModelImageEditorDefault />;
}

export function CustomModelImageEditor({ ...props }: CustomModelImageEditorProps) {
  return (
    <CustomModelImageEditorProvider {...props}>
      <CustomModelImageEditorInner />
    </CustomModelImageEditorProvider>
  );
}

export const CustomModelImageEditorDialogTrigger = React.forwardRef(
  function CustomModelImageEditorDialogTrigger(
    { children, ...props }: React.PropsWithChildren<Dialog.DialogTriggerProps>,
    forwardedRef: React.ForwardedRef<HTMLButtonElement>,
  ) {
    return (
      <Dialog.Trigger ref={forwardedRef} {...props}>
        {children}
      </Dialog.Trigger>
    );
  },
);

function CustomModelImageEditorDialogTitle() {
  const activeMode = useCustomModelImageEditorActiveMode();

  return (
    <Dialog.Title className="flex flex-row items-center gap-2 text-zinc-300">
      <SquarePen size={16} className="text-zinc-500" />
      <span className="font-semibold">Edit image</span>
      <span className="text-zinc-500 ml-2">{customModelImageEditorModeToNames[activeMode]}</span>
    </Dialog.Title>
  );
}

export interface CustomModelImageEditorDialogProps extends CustomModelImageEditorProps {
  triggerProps?: Dialog.DialogTriggerProps;
  triggerChildren?: React.ReactNode;
  contentProps?: Dialog.DialogContentProps;
  contentChildren?: React.ReactNode;
  ignoreDialogTrigger?: boolean;
}

export function CustomModelImageEditorDialog({
  triggerProps,
  triggerChildren,
  contentProps,
  contentChildren,
  ignoreDialogTrigger = false,
  ...props
}: CustomModelImageEditorDialogProps) {
  return (
    <CustomModelImageEditorProvider {...props}>
      <CustomModelImageEditorFixProductDetailsProvider>
        <CustomModelImageEditorRegenerateProductProvider>
          <CustomModelFixTextContextProvider>
            <CustomModelCompareOutputContextProvider>
              <CustomModelVirtualTryOnContextProvider>
                <CustomModelRegenerateHumanContextProvider>
                  <CustomModelUpscaleContextProvider>
                    <CustomModelImageVariationsContextProvider>
                      <CustomModelUpscaleFaceContextProvider>
                        <Dialog.Root>
                          {ignoreDialogTrigger ? (
                            triggerChildren
                          ) : (
                            <Dialog.Trigger {...triggerProps}>{triggerChildren}</Dialog.Trigger>
                          )}
                          <Dialog.Portal>
                            <Dialog.Overlay
                              className={styles.DialogOverlay}
                              style={{
                                zIndex: FloatTagZIndex,
                              }}
                            />
                            <Dialog.Content
                              {...contentProps}
                              className={classNames(
                                styles.DialogContent,
                                DropdownClassName,
                                "rounded-xl w-[90vw] 2xl:w-[1280px] h-[90vh] px-0 py-0 flex flex-col items-stretch",
                              )}
                              style={{
                                ...(contentProps?.style ?? {}),
                                zIndex: FloatTagZIndex,
                              }}
                            >
                              <div className="relative w-full p-2.5 flex flex-row items-center border-b border-zinc-800">
                                <CustomModelImageEditorDialogTitle />
                                <Dialog.Close className="absolute right-3 text-zinc-500 hover:text-zinc-300 cursor-pointer transition-colors">
                                  <Cross1Icon width={16} height={16} />
                                </Dialog.Close>
                              </div>
                              <div className="flex-1 w-full overflow-hidden">
                                <CustomModelImageEditorInner />
                              </div>
                              {contentChildren}
                            </Dialog.Content>
                          </Dialog.Portal>
                        </Dialog.Root>
                      </CustomModelUpscaleFaceContextProvider>
                    </CustomModelImageVariationsContextProvider>
                  </CustomModelUpscaleContextProvider>
                </CustomModelRegenerateHumanContextProvider>
              </CustomModelVirtualTryOnContextProvider>
            </CustomModelCompareOutputContextProvider>
          </CustomModelFixTextContextProvider>
        </CustomModelImageEditorRegenerateProductProvider>
      </CustomModelImageEditorFixProductDetailsProvider>
    </CustomModelImageEditorProvider>
  );
}
