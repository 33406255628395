import { Canvas } from "@/components/editor/canvas";
import { EditorNavBar } from "@/components/editor/editor-navbar";
import React from "react";

import { AnalyticsConfig } from "@/analytics/config";
import { RequireAuth } from "@/components/auth/require-auth";
import { RequireDesktop } from "@/components/auth/require-desktop";
import { Chatbot } from "@/components/chatbot";
import { BACKGROUND_DARK } from "@/components/constants/colors";
import { samplePlatformProjectRealTimeReferenceImageUrl } from "@/components/constants/sample-projects";
import { EditorRightClickMenu } from "@/components/editor/right-click-context-menu/right-click-menu";
import { LeftPanels } from "@/components/panels/panels";
import { ManageSubscriptionDialogProvider } from "@/components/popup/message-dialog/manage-subscription-dialog";
import { editorContextStore } from "@/contexts/editor-context";
import { SampleProjectScene } from "@/core/common/scene";
import { EditorInitEventHandler } from "@/core/common/types";
import {
  RealTimeRenderMode,
  resetRealtimeRenderEditorState,
} from "@/core/common/types/realtime-render";
import { initFabricFilterBackend } from "@/core/utils/object-filter-utils";
import { isTemporaryProjectId } from "@/core/utils/project-utils";
import { useRealTimeQuotaUpdate } from "@/hooks/use-realtime-update";
import { useParams } from "react-router-dom";
import { ManageTeamDialogProvider } from "../popup/message-dialog/manage-team-dialog";
import {
  OnboardingDialog,
  OnboardingDialogProvider,
} from "../popup/message-dialog/onboarding-dialog";
import { RealTimeRenderController } from "./realtime-render-controller";

export function EditorInternal({ initScene }: { initScene?: SampleProjectScene }) {
  const params = useParams();
  const projectId = params?.projectId;
  const setProjectId = editorContextStore((state) => state.setProjectId);

  React.useEffect(() => {
    editorContextStore.getState().analytics.track(AnalyticsConfig.PageOpen, {
      name: `Project ${projectId}`,
      url: window.location.href,
    });
  }, [projectId]);

  React.useEffect(() => {
    initFabricFilterBackend();
  }, []);

  React.useEffect(() => {
    if (projectId && !isTemporaryProjectId(projectId)) {
      setProjectId(projectId);
      // Load project state from remote server
      const { backend, setProjectDisplayName } = editorContextStore.getState();
      backend?.getProjectDisplayName(projectId).then((projectDisplayName) => {
        setProjectDisplayName(projectDisplayName);
      });
    } else {
      setProjectId(undefined);
    }
    return () => {
      setProjectId(undefined);
    };
  }, [projectId, setProjectId]);

  React.useEffect(() => {
    const { editor, setRealtimeRenderMode, setRealtimeRenderController } =
      editorContextStore.getState();

    setRealtimeRenderMode(RealTimeRenderMode.Disabled);

    const realTimeRenderController = new RealTimeRenderController();

    setRealtimeRenderController(realTimeRenderController);

    if (editor) {
      editor.once<EditorInitEventHandler>("editor:init", () => {
        RealTimeRenderController.setRealtimeRenderMode((mode) => {
          if (mode === RealTimeRenderMode.Active) {
            return mode;
          }

          const { setGenerateToolReferenceImage } = editorContextStore.getState();
          setGenerateToolReferenceImage(
            (prevReferenceImage) =>
              prevReferenceImage || {
                path: samplePlatformProjectRealTimeReferenceImageUrl,
                type: "image-url",
              },
          );

          return RealTimeRenderMode.Active;
        });
      });
    }

    return () => {
      resetRealtimeRenderEditorState();
      realTimeRenderController.destroy();
    };
  }, []);

  useRealTimeQuotaUpdate();

  return (
    <div className="w-screen h-screen flex flex-col text-sm text-zinc-100 overflow-hidden">
      <EditorNavBar />
      <div
        className="flex-1 flex flex-row overflow-hidden"
        style={{
          backgroundColor: BACKGROUND_DARK,
        }}
      >
        <LeftPanels />
        <EditorRightClickMenu>
          <Canvas
            config={{
              // background: BACKGROUND_DARK,
              background: "transparent",
              controlsPosition: {
                rotation: "BOTTOM",
              },
              shadow: null,
            }}
            initScene={initScene}
          />
        </EditorRightClickMenu>
      </div>
    </div>
  );
}

export function Editor({ initScene }: { initScene?: SampleProjectScene }) {
  return (
    <RequireAuth>
      <RequireDesktop>
        <ManageSubscriptionDialogProvider>
          <ManageTeamDialogProvider>
            <OnboardingDialogProvider>
              <div className="h-screen bg-zinc-900 text-zinc-100 flex flex-col">
                <EditorInternal initScene={initScene} />
                <Chatbot />
                <OnboardingDialog />
              </div>
            </OnboardingDialogProvider>
          </ManageTeamDialogProvider>
        </ManageSubscriptionDialogProvider>
      </RequireDesktop>
    </RequireAuth>
  );
}
