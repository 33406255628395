import {
  customModelCaptionTrigger,
  CustomModelPlaygroundPromptEditorState,
  CustomModelPredictionInput,
  CustomModelType,
  getModelTrainingMentionName,
  isCustomModelPredictionInputFal,
  isCustomModelPredictionInputSelfHost,
} from "@/core/common/types";
import { ApiInputType } from "@/core/common/types/api";
import { removeUndefinedFromObject } from "@/core/utils/object-utils";
import { SliderInputProps } from "components/dashboard/api/slider-input";
import { SerializedEditorState } from "lexical";
import { noop } from "lodash";
import React, { createContext, useContext, useMemo, useState } from "react";
import { getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState } from "./custom-model-mention-plugin";

// export function getCustomModelPlaygroundPromptEditorStateFromPrediction({
//     modelId,
//     modelDisplayName,
//     predictionItem,
// }: {
//     modelId: string,
//     modelDisplayName: string,
//     predictionItem: CustomModelPredictionItem,
// }): CustomModelPlaygroundPromptEditorState {
//     const promptEditorState = {

//     }
// }

export function getCustomModelPlaygroundEditorStateFromPredictionInput(
  input: CustomModelPredictionInput,
): Partial<CustomModelPlaygroundEditorState> {
  if (isCustomModelPredictionInputFal(input)) {
    return removeUndefinedFromObject({
      width: input.image_size?.width,
      height: input.image_size?.height,
      guidanceScale: input.guidance_scale,
      numImages: input.num_images,
      numInferenceSteps: input.num_inference_steps,
    });
  } else if (isCustomModelPredictionInputSelfHost(input)) {
    return removeUndefinedFromObject({
      width: input.width,
      height: input.height,
      guidanceScale: input.guidance_scale,
      loraScale: input.lora_scale,
      numImages: input.num_outputs,
      numInferenceSteps: input.num_inference_steps,
    });
  } else {
    return removeUndefinedFromObject({
      width: input.image_size?.width,
      height: input.image_size?.height,
      guidanceScale: input.extraPredictionArgs?.guidance_scale ?? 3.5,
      numImages: input.extraPredictionArgs?.num_images ?? 1,
      numInferenceSteps: input.extraPredictionArgs?.num_inference_steps,
    });
  }
}

export function buildWorkflowPromptEditorState({
  customModelType,
  captionLeft,
  captionRight,
  trainingId,
  trainingDisplayName,
  modelId,
  modelDisplayName,
}: {
  customModelType: CustomModelType;
  captionLeft: string;
  captionRight: string;
  trainingId: string;
  trainingDisplayName: string;
  caption: string;
  modelId: string;
  modelDisplayName: string;
}) {
  const mention = {
    detail: 1,
    format: 0,
    mode: "segmented",
    style: "",
    text: getModelTrainingMentionName({
      modelDisplayName,
      training: {
        id: trainingId,
        displayName: trainingDisplayName,
      },
    }),
    type: "mention",
    version: 1,
    trainingId,
    trainingDisplayName,
    modelId,
    modelDisplayName,
  };

  type TextNode = { text: string; mode: string; type: "text" } | typeof mention;

  const createTextNode = (text: string, mode: string = "normal"): TextNode => ({
    text,
    mode,
    type: "text",
  });

  let textNodes: TextNode[] = [];

  switch (customModelType) {
    case CustomModelType.Fashion:
      textNodes = [
        createTextNode(`photo of a woman wearing ${captionLeft}`),
        mention,
        createTextNode(
          `${captionRight} posing on beige fabric against a beige vogue photoshoot in overexposed light and shadows, motion photography, old money 35mm lens, professional fashion photography`,
        ),
      ];
      break;
    case CustomModelType.Product:
      textNodes = [
        createTextNode(`photo of ${captionLeft}`),
        mention,
        createTextNode(
          `${captionRight} on a stone. A couple of black flowers in front of soft dark background and blurry palm leaves are poking in the frame from the side, 50mm lens, professional studio photography`,
        ),
      ];
      break;
    case CustomModelType.Style:
      textNodes = [
        createTextNode("photo in the style of "),
        mention,
        createTextNode(
          " a running shoe sitting on rocks surrounded by dirt, in front of a dark brown background and contrast between shadows and highlights. 35mm lens, professional studio photography",
        ),
      ];
      break;
    case CustomModelType.Furniture:
      textNodes = [
        createTextNode(`A photo of A ${captionLeft}`),
        mention,
        createTextNode(
          `${captionRight} sitting in a large spacious modern room with natural lighting, 60 mm lens, professional product photography with contrasting highlight and shadows on the background`,
        ),
      ];
      break;
    case CustomModelType.Tech:
      textNodes = [
        createTextNode(`A photo of ${captionLeft}`),
        mention,
        createTextNode(
          `${captionRight} the product is up in the air surrounded in air with different colored big and small shiny and matte balls flying around in front of a soft dark background, 60 mm lens, professional product photography with contrasting highlight and shadows on the background`,
        ),
      ];
      break;
    case CustomModelType.Food:
      textNodes = [
        createTextNode(`A photo of ${captionLeft}`),
        mention,
        createTextNode(
          `${captionRight} 60 mm lens, professional food photography with contrasting highlight and shadows on the background`,
        ),
      ];
      break;
    case CustomModelType.Vase:
      textNodes = [
        createTextNode(`A photo of ${captionLeft}`),
        mention,
        createTextNode(
          `${captionRight} posing on beige fabric against a beige vogue photoshoot in overexposed light and shadows, motion photography, old money 35mm lens, professional fashion photography`,
        ),
      ];
      break;
    case CustomModelType.VirtualModel:
      textNodes = [
        createTextNode(`A photo of a woman ${captionLeft}`),
        mention,
        createTextNode(
          `${captionRight} posing on beige fabric against a beige vogue photoshoot in overexposed light and shadows, motion photography, old money 35mm lens, professional fashion photography`,
        ),
      ];
      break;
    case CustomModelType.Footwear:
      textNodes = [
        createTextNode(`A pair ${captionLeft}`),
        mention,
        createTextNode(
          `${captionRight} shoes floating on a cloud front of a light blue gradient  background with shadows on it. 60mm lens, professional studio photography`,
        ),
      ];
      break;
    case CustomModelType.Jewelry:
      textNodes = [
        createTextNode(`A photo of a ${captionLeft}`),
        mention,
        createTextNode(
          `${captionRight} sitting on a piece of white concrete slab surrounded by dry white branches and flowers in front of a soft white background, 60 mm lens, professional studio photography with contrasting highlight and shadows on the background`,
        ),
      ];
      break;
    default:
      textNodes = [
        createTextNode("A photo of "),
        mention,
        createTextNode(
          " in front of soft background, 35mm lens, professional editorial photography",
        ),
      ];
  }

  return {
    root: {
      children: [
        {
          children: textNodes.map((node) => {
            if ("type" in node && node.type === "mention") {
              return node;
            } else {
              const { text, mode } = node;
              return {
                detail: 0,
                format: 0,
                mode,
                style: "",
                text,
                type: "text",
                version: 1,
              };
            }
          }),
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
          textFormat: 0,
          textStyle: "",
        },
      ],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1,
    },
  };
}

export function getEmptyCustomModelPlaygroundPromptEditorState(): CustomModelPlaygroundPromptEditorState {
  const promptEditorState = {
    root: {
      children: [
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: "",
              type: "text",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
          textFormat: 0,
          textStyle: "",
        },
      ],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1,
    },
  };

  return getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
    promptEditorState: promptEditorState as SerializedEditorState,
  });
}

export function getCustomModelPlaygroundPromptEditorStateFromTrainingId({
  customModelType,
  trainingId,
  trainingDisplayName,
  modelId,
  modelDisplayName,
  caption,
}: {
  customModelType: CustomModelType;
  trainingId: string;
  trainingDisplayName: string;
  caption: string;
  modelId: string;
  modelDisplayName: string;
}): CustomModelPlaygroundPromptEditorState {
  const captionSplitted = caption.split(customModelCaptionTrigger);

  const captionLeft = captionSplitted?.[0] ?? "";
  const captionRight = captionSplitted?.[1] ?? "";

  const promptEditorState = buildWorkflowPromptEditorState({
    customModelType,
    captionLeft,
    captionRight,
    trainingId,
    trainingDisplayName,
    modelId,
    modelDisplayName,
    caption,
  });

  return getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
    promptEditorState: promptEditorState as SerializedEditorState,
  });
}

interface CustomModelPlaygroundEditorState {
  promptEditorState: CustomModelPlaygroundPromptEditorState;
  width: number;
  height: number;
  numInferenceSteps: number;
  guidanceScale: number;
  numImages: number;
  loraScale: number;
}

export enum CustomModelPlaygroundStatus {
  Idle = "Idle",
  Rendering = "Rendering",
}

export enum CustomModelPlaygroundResultTab {
  Output = "Outputs",
  PastGenerations = "PastGenerations",
}

export function isCustomModelPlaygroundResultTab(tab: any): tab is CustomModelPlaygroundResultTab {
  return (
    typeof tab === "string" &&
    Object.values(CustomModelPlaygroundResultTab).includes(tab as CustomModelPlaygroundResultTab)
  );
}

export enum CustomModelPlaygroundGenerationMode {
  Default = "Default",
  Multstep = "Multstep",
}

export const customModelPlaygroundGenerationModeNames: Record<
  CustomModelPlaygroundGenerationMode,
  string
> = {
  [CustomModelPlaygroundGenerationMode.Default]: "Default - General-purpose image generation.",
  [CustomModelPlaygroundGenerationMode.Multstep]:
    "Creative - Improved composition and prompt following, but less detail preservation.",
};

interface CustomModelPlaygroundContextProps {
  apiConfig: typeof customModelPlaygroundGenerateImageConfig;
  apiState: CustomModelPlaygroundEditorState;
  setApiState: React.Dispatch<React.SetStateAction<CustomModelPlaygroundEditorState>>;
  status: CustomModelPlaygroundStatus;
  setStatus: React.Dispatch<React.SetStateAction<CustomModelPlaygroundStatus>>;
  outputImages: string[];
  setOutputImages: React.Dispatch<React.SetStateAction<string[]>>;
  predictionId: string;
  setPredictionId: React.Dispatch<React.SetStateAction<string>>;
  resultTab: CustomModelPlaygroundResultTab;
  setResultTab: React.Dispatch<React.SetStateAction<CustomModelPlaygroundResultTab>>;
  generationMode: CustomModelPlaygroundGenerationMode;
  setGenerationMode: React.Dispatch<React.SetStateAction<CustomModelPlaygroundGenerationMode>>;
}

const CustomModelPlaygroundContext = createContext<CustomModelPlaygroundContextProps>({
  apiConfig: {} as typeof customModelPlaygroundGenerateImageConfig,
  apiState: {} as CustomModelPlaygroundEditorState,
  setApiState: noop as React.Dispatch<React.SetStateAction<CustomModelPlaygroundEditorState>>,
  status: CustomModelPlaygroundStatus.Idle,
  setStatus: noop,
  outputImages: [],
  setOutputImages: noop,
  predictionId: "",
  setPredictionId: noop,
  resultTab: CustomModelPlaygroundResultTab.Output,
  setResultTab: noop,
  generationMode: CustomModelPlaygroundGenerationMode.Default,
  setGenerationMode: noop,
});

export const useCustomModelPlayground = () => useContext(CustomModelPlaygroundContext);

export const customModelPlaygroundGenerateImageConfig: {
  width: SliderInputProps;
  height: SliderInputProps;
  numInferenceSteps: SliderInputProps;
  guidanceScale: SliderInputProps;
  numImages: SliderInputProps;
  loraScale: SliderInputProps;
} = {
  width: {
    type: ApiInputType.Slider,
    id: "width",
    name: "Width",
    description: "The width of the generated image.",
    value: 1024,
    defaultValue: 1024,
    min: 512,
    max: 1536,
    step: 128,
    onValueChange: noop,
  },
  height: {
    type: ApiInputType.Slider,
    id: "height",
    name: "Height",
    description: "The height of the generated image.",
    value: 1024,
    defaultValue: 1024,
    min: 512,
    max: 1536,
    step: 128,
    onValueChange: noop,
  },
  loraScale: {
    type: ApiInputType.Slider,
    id: "loraScale",
    name: "Custom Model Strength",
    description: "How close the generated images are to the custom model",
    value: 1,
    defaultValue: 1,
    min: 0,
    max: 1.5,
    step: 0.1,
    onValueChange: noop,
  },
  numInferenceSteps: {
    type: ApiInputType.Slider,
    id: "numInferenceSteps",
    name: "Image Sharpness",
    description: "The sharpness of the generated image",
    value: 28,
    defaultValue: 28,
    min: 1,
    max: 50,
    step: 1,
    onValueChange: noop,
  },
  guidanceScale: {
    type: ApiInputType.Slider,
    id: "guidanceScale",
    name: "Prompt Strength",
    description: "How closely the generated images match the prompt",
    value: 3.5,
    defaultValue: 3.5,
    min: 0,
    max: 10,
    step: 0.5,
    onValueChange: noop,
  },
  numImages: {
    type: ApiInputType.Slider,
    id: "numImages",
    name: "Number of outputs",
    description: "The number of generated images.",
    value: 2,
    defaultValue: 2,
    min: 1,
    max: 4,
    step: 1,
    onValueChange: noop,
  },
};

export const CustomModelPlaygroundProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [apiState, setApiState] = useState<CustomModelPlaygroundEditorState>({
    promptEditorState: getEmptyCustomModelPlaygroundPromptEditorState(),
    width: customModelPlaygroundGenerateImageConfig.width.defaultValue ?? 1024,
    height: customModelPlaygroundGenerateImageConfig.height.defaultValue ?? 1024,
    numInferenceSteps:
      customModelPlaygroundGenerateImageConfig.numInferenceSteps.defaultValue ?? 25,
    guidanceScale: customModelPlaygroundGenerateImageConfig.guidanceScale.defaultValue ?? 3.5,
    numImages: customModelPlaygroundGenerateImageConfig.numImages.defaultValue ?? 2,
    loraScale: customModelPlaygroundGenerateImageConfig.loraScale.defaultValue ?? 1,
  });

  const [status, setStatus] = React.useState(CustomModelPlaygroundStatus.Idle);

  const [outputImages, setOutputImages] = React.useState<string[]>([]);
  const [predictionId, setPredictionId] = React.useState<string>("");

  const [resultTab, setResultTab] = React.useState<CustomModelPlaygroundResultTab>(
    CustomModelPlaygroundResultTab.Output,
  );
  const [generationMode, setGenerationMode] = React.useState(
    CustomModelPlaygroundGenerationMode.Default,
  );

  const apiConfig = useMemo(() => customModelPlaygroundGenerateImageConfig, []);

  return (
    <CustomModelPlaygroundContext.Provider
      value={{
        apiConfig,
        apiState,
        setApiState,
        status,
        setStatus,
        outputImages,
        setOutputImages,
        predictionId,
        setPredictionId,
        resultTab,
        setResultTab,
        generationMode,
        setGenerationMode,
      }}
    >
      {children}
    </CustomModelPlaygroundContext.Provider>
  );
};
