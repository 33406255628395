import { getEncryptedIdFromPublicId, isPublicId, PublicId } from "./public-id";

export const publicTeamIdSuffix = "_tpuid";

export type PublicTeamId = PublicId<typeof publicTeamIdSuffix>;

export function getEncryptedTeamIdFromPublicUserId(publicUserId: PublicTeamId) {
  return getEncryptedIdFromPublicId(publicUserId, publicTeamIdSuffix);
}

export function isPublicTeamId(id: string | undefined | null): id is PublicTeamId {
  return isPublicId(id, publicTeamIdSuffix);
}
