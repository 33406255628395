import { Backend } from "@/backend/base";
import { AppRoleType, sortAppRoleType } from "@/core/common/types/user-roles";
import {
  PublicUserId,
  PublicUserRoles,
  PublicUserMetadata,
} from "@/core/common/types/public-user-id";
import { getObjectEntries } from "@/core/utils/type-utils";

export type PublicUserMetadataDictItem = PublicUserMetadata & {
  role: AppRoleType;
};

export type PublicUserMetadataDict = Record<PublicUserId, PublicUserMetadataDictItem>;

export async function getPublicUserMetadata({
  roles,
  backend,
}: {
  roles: PublicUserRoles;
  backend: Backend;
}) {
  const userMetadataDict: PublicUserMetadataDict = {};
  await Promise.all(
    getObjectEntries(roles)
      .sort(([idA, roleA], [idB, roleB]) => sortAppRoleType(roleA, roleB))
      .map(async ([publicUserId, role]) => {
        const userMetadata = await backend.getPublicUserMetadata(publicUserId);

        if (!userMetadata) {
          console.error(`Cannot get public user ${publicUserId} metadata`);

          return;
        }

        userMetadataDict[publicUserId] = {
          ...userMetadata,
          role,
        };
      }),
  );
  return userMetadataDict;
}
