import { classNames } from "@/core/utils/classname-utils";
import { getTeamOrUserQuotas } from "@/hooks/use-user-quotas";
import { PrimaryButtonClassName } from "components/constants/class-names";
import { useManageSubscriptionDialogContext } from "components/popup/message-dialog/manage-subscription-dialog";
import { Zap } from "lucide-react";
import React from "react";

function useUpgradeVisible() {
  const userQuotas = getTeamOrUserQuotas();
  return React.useMemo(() => {
    if (!userQuotas) {
      return true;
    }

    return true;
  }, [userQuotas]);
}

export function NavBarUpgrade() {
  const { setOpen } = useManageSubscriptionDialogContext();

  const visible = useUpgradeVisible();

  if (!visible) {
    return null;
  }

  return (
    <button
      className={classNames(
        PrimaryButtonClassName,
        "group py-1 px-3 flex flex-row items-center justify-center gap-2 cursor-pointer",
        "bg-lime-500/10 hover:bg-lime-500/30 active:bg-lime-500 active:text-lime-100 text-sm text-lime-300 hover:text-lime-200 actve:text-lime-50 shadow-lg shadow-lime-900/5 hover:shadow-lime-600/5 active:shadow-lime-900/5",
      )}
      onClick={() => {
        setOpen(true);
      }}
    >
      <span>Upgrade</span>
      <Zap size={16} />
    </button>
  );
}
