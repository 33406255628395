import {
  AppUserSubscriptionTier,
  UiDisplayMessageDialogEventHandler,
  UserAssetInfo,
  UserAssetType,
} from "@/core/common/types";
import { StaticImageElementType } from "@/core/common/types/elements";
import { classNames } from "@/core/utils/classname-utils";
import { triggerContextMenuEvent } from "@/core/utils/event-utils";
import { getTeamOrUserQuotas } from "@/hooks/use-user-quotas";
import * as ContextMenu from "@radix-ui/react-context-menu";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { SecondaryButtonClassNameInactive } from "components/constants/class-names";
import rightClickMenuStyles from "components/editor/right-click-context-menu/right-click-menu.module.css";
import { mergeRefs } from "components/utils/merge-refs";
import { getObjectTargetScale, uploadAndAddFiles } from "components/utils/upload";
import { editorContextStore } from "contexts/editor-context";
import { UploadCloud } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Tooltip, TooltipProps } from "../../utils/tooltip";
import { AssetLibrary } from "./components/assets-library";
import { LeftPanelTitle } from "./components/left-panel-title";
import { PresetImageGridItem } from "./components/preset-image-grid";

import {
  AssetMetadata,
  AssetMetadataCollection,
  AssetMetadataGenerator,
} from "@/core/common/types/assetV2";
import { debugLog } from "@/core/utils/print-utilts";
import { getFilteredPresetImages } from "./assetsList";

function AssetButtonQuotaLimit({
  className = "",
  ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) {
  return (
    <button
      {...props}
      className={classNames(
        SecondaryButtonClassNameInactive,
        "box-border flex items-center justify-center w-full",
        className,
      )}
      onClick={() => {
        editorContextStore
          .getState()
          .editor?.emit<UiDisplayMessageDialogEventHandler>(
            "ui:display-message-dialog",
            "quota-subscribe",
            {
              title: "No asset storage space left.",
              header:
                "You have used all cloud storage space. Please subscribe to upload more images.",
            },
          );
      }}
    >
      <UploadCloud className="select-none" size={18} />
      <div className="ml-2 select-none">Upload Product Photo</div>
    </button>
  );
}

function UploadProductButton({ className = "" }: { className?: string }) {
  const editor = editorContextStore((state) => state.editor);
  const userQuotas = getTeamOrUserQuotas();
  const backend = editorContextStore((state) => state.backend);
  const [isUploadAvailable, setIsUploadAvailable] = useState(true);

  const tier = userQuotas?.tier || AppUserSubscriptionTier.Free;
  useEffect(() => {
    if (!backend) {
      return;
    }
    const checkUploadAvailability = async () => {
      setIsUploadAvailable(true);
    };

    checkUploadAvailability();
  }, [backend, tier]);

  const handleUploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = e.target.files;
    const editorObjects = editor?.objects;
    if (files && files.length > 0 && editorObjects) {
      uploadAndAddFiles({
        files,
        editorObjects,
        assets: editor.assets,
        userAssetInfoType: "images",
        removeBackgroundPopup: true,
        assetType: UserAssetType.UploadedProduct,
      });
    }
  };

  return (
    <>
      <input
        type="file"
        id="imgupload_graphics_default"
        style={{
          display: "none",
        }}
        onChange={(e) => {
          handleUploadFiles(e);
        }}
      />
      <label htmlFor="imgupload_graphics_default">
        {isUploadAvailable ? (
          <div
            id="left-panel-assets-upload-image-button"
            className={`${SecondaryButtonClassNameInactive} flex items-center justify-center ${className}`}
          >
            <UploadCloud className="select-none" size={18} />
            <div className="ml-2 select-none">Upload Product Photo</div>
          </div>
        ) : (
          <AssetButtonQuotaLimit className={className} />
        )}
      </label>
    </>
  );
}

const UserAssetLibraryItem = React.memo(
  React.forwardRef(
    (
      {
        imageItem,
        isIdle = true,
        ...props
      }: TooltipProps & {
        imageItem: UserAssetInfo | AssetMetadata;
        isIdle?: boolean;
      },
      forwardedRef: React.ForwardedRef<HTMLDivElement>,
    ) => {
      const editor = editorContextStore((state) => state.editor);
      const storageManager = editorContextStore((state) => state.storageManager);
      const [elementRef, inView] = useInView();
      const storagePath =
        imageItem?.storagePath ?? storageManager.getAssetStoragePathFromId(imageItem.id);
      const [url, setUrl] = React.useState("");
      const contextMenuTriggerRef = React.useRef<HTMLDivElement | null>(null);

      React.useEffect(() => {
        debugLog("UserAssetLibraryItem useEffect", storagePath);
        if (!editor || !inView || !storagePath) {
          return;
        }
        const isMounted = true;
        // Pass the storage path directly to PresetImageGridItem
        setUrl(storagePath);
        // editor.assets.loadAsset({
        //     type: 'image-storage',
        //     path: storagePath,
        //     saveToLocal:true
        // }).then((dataUrl) => {
        //     if (!dataUrl || !isMounted) {
        //         return;
        //     }
        //     debugLog("UserAssetLibraryItem loaded", storagePath, dataUrl);
        //     setUrl(dataUrl);
        // }).catch((e) => {
        //     debugError("UserAssetLibraryItem error", e);
        // });
        // return () => {
        //     isMounted = false;
        // };
      }, [editor, storagePath, inView]);

      return (
        <ContextMenu.Root>
          <ContextMenu.Trigger ref={contextMenuTriggerRef}>
            <Tooltip
              {...props}
              triggerChildren={
                <PresetImageGridItem
                  // eslint-disable-next-line
                  ref={mergeRefs([forwardedRef, elementRef])}
                  url={url}
                  className={classNames(
                    SecondaryButtonClassNameInactive,
                    "group",
                    url ? "cursor-pointer" : "cursor-wait",
                  )}
                  onAddItem={() => {
                    if (!editor || !url) {
                      return;
                    }

                    const caption = imageItem.caption;
                    editor.objects
                      .addImageFromUrl({
                        url,
                        asset: {
                          type: "image-storage",
                          path: storagePath,
                        },
                        uploadStorage: false,
                        metadata: {
                          imageType: StaticImageElementType.Subject,
                          subject: caption,
                        },
                      })
                      .then((object) => {
                        if (!object) {
                          return;
                        }
                        const scale = getObjectTargetScale(
                          // @ts-ignore
                          object,
                        );
                        object.scale(scale);
                      });
                  }}
                >
                  <button
                    className="hidden group-hover:flex items-center justify-center absolute right-0 bottom-0 m-2 p-2 rounded-full bg-zinc-800 text-zinc-300 hover:text-lime-500 transition-colors"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      const triggerElement = contextMenuTriggerRef.current;

                      if (!triggerElement) {
                        return;
                      }

                      triggerContextMenuEvent({
                        element: triggerElement,
                        clientX: e.clientX,
                        clientY: e.clientY,
                      });
                    }}
                  >
                    <DotsVerticalIcon width={12} height={12} />
                  </button>
                </PresetImageGridItem>
              }
              contentClassName="pointer-events-none"
              contentChildren={
                <div>{url ? "Click to add this asset to the canvas." : "Loading asset ..."}</div>
              }
            />
          </ContextMenu.Trigger>
          <ContextMenu.Portal>
            <ContextMenu.Content className={rightClickMenuStyles.ContextMenuContent}>
              <ContextMenu.Item
                className={rightClickMenuStyles.ContextMenuItem}
                onClick={() => {
                  const { editor } = editorContextStore.getState();
                  if (!editor) {
                    return;
                  }

                  editor.assets.deleteUserImageAsset({
                    assetId: imageItem.id,
                    storagePath: imageItem.storagePath,
                  });
                }}
              >
                Delete Asset
              </ContextMenu.Item>
            </ContextMenu.Content>
          </ContextMenu.Portal>
        </ContextMenu.Root>
      );
    },
  ),
);

export const UserAssetLibrary = React.memo(function UserAssetLibrary() {
  const backend = editorContextStore((state) => state.backend);
  const storageManager = editorContextStore((state) => state.storageManager);
  const assetMetadataGeneratorRef = editorContextStore((state) => state.assetMetadataGeneratorRef);
  const assetMetadataCollection = editorContextStore((state) => state.assetMetadataCollection);
  debugLog("UserAssetLibrary", assetMetadataCollection);
  React.useEffect(() => {
    if (!backend || !storageManager) {
      return;
    }

    if (!assetMetadataGeneratorRef.current) {
      assetMetadataGeneratorRef.current = backend.getAssetMetadataGenerator({
        assetType: UserAssetType.UploadedProduct,
        batchSize: 12,
        publicUserId: editorContextStore.getState().publicUserId,
      }) as unknown as AssetMetadataGenerator;
      assetMetadataGeneratorRef.current?.getNextBatch().then((assetInfo) => {
        editorContextStore.getState().setAssetMetadataCollection(
          assetInfo.reduce<AssetMetadataCollection>((result, i) => {
            result[i.id] = i;
            return result;
          }, {}),
        );
      });
    }
  }, [backend, storageManager, assetMetadataGeneratorRef]);

  const [lastRowRef, lastRowInView] = useInView();

  React.useEffect(() => {
    if (!lastRowInView || !assetMetadataGeneratorRef.current) {
      return;
    }

    assetMetadataGeneratorRef.current.getNextBatch().then((assetInfo) => {
      editorContextStore.getState().setAssetMetadataCollection((prevCollection) => ({
        ...prevCollection,
        ...assetInfo.reduce<AssetMetadataCollection>((result, i) => {
          result[i.id] = i;
          return result;
        }, {}),
      }));
    });
  }, [lastRowInView, assetMetadataGeneratorRef]);

  const userImageAssets = React.useMemo(
    () => Object.values(assetMetadataCollection),
    [assetMetadataCollection],
  );

  if (!userImageAssets?.length) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="font-semibold mb-2">Uploaded Products</div>
      <div className="grid grid-cols-3 gap-2">
        {userImageAssets.map(
          (imageItem, index) =>
            imageItem.id && (
              <UserAssetLibraryItem
                ref={index === userImageAssets.length - 1 ? lastRowRef : undefined}
                key={imageItem.id}
                imageItem={imageItem}
              />
            ),
        )}
      </div>
    </div>
  );
});

export function Assets() {
  const editor = editorContextStore((state) => state.editor);
  const projectType = editorContextStore((state) => state.projectType);

  return (
    <div id="left-panel-assets-container" className="flex flex-col">
      <LeftPanelTitle>
        <span id="add-assets-left-panel-title">Add Products</span>
      </LeftPanelTitle>
      <AssetLibrary label="Products" editor={editor} assets={getFilteredPresetImages(projectType)}>
        <UploadProductButton className="mb-2" />
      </AssetLibrary>
      <div className="h-4" />
      <UserAssetLibrary />
    </div>
  );
}
