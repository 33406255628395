import { UserRoleRowItem } from "@/components/collaborate/user-role-row-item";
import {
  DropdownClassName,
  DropdownMenuItemClassName,
  InputBoxClassName,
  InputBoxClassNameError,
  PrimaryButtonClassName,
  PrimaryButtonClassNameLoading,
} from "@/components/constants/class-names";
import { MessageDialogZIndex } from "@/components/constants/zIndex";
import {
  MessageDialog,
  MessageDialogClose,
  MessageDialogProps,
  MessageDialogTitle,
} from "@/components/popup/message-dialog/message-dialog";
import { useToast } from "@/components/popup/message-toast";
import { AppRoleType, appRoleTypeToDisplayName } from "@/core/common/types";
import { AppSubscriptionPlanType } from "@/core/common/types/subscription";
import { classNames } from "@/core/utils/classname-utils";
import { extractEmailsFromString } from "@/core/utils/email-utils";
import { debugLog } from "@/core/utils/print-utilts";
import { getCurrentTeamId } from "@/core/utils/team-utils";
import { getObjectEntries } from "@/core/utils/type-utils";
import { doesUserHaveTeamFullPermission } from "@/core/utils/user-role-utils";
import { useUserHighestStripeSubscription } from "@/hooks/use-user-stripe-subscription-update";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Cross1Icon } from "@radix-ui/react-icons";
import {
  getPublicUserMetadata,
  PublicUserMetadataDict,
} from "components/collaborate/public-user-roles";
import { SimpleSpinner } from "components/icons/simple-spinner";
import { editorContextStore } from "contexts/editor-context";
import { ChevronDown } from "lucide-react";
import React, { createContext, PropsWithChildren } from "react";
import { useManageSubscriptionDialogContext } from "./manage-subscription-dialog";

enum InviteTeamMemberInputStatus {
  Idle = "Idle",
  Loading = "Loading",
  Error = "Error",
}

function InviteTeamMemberInputRoleSelector({
  editRoleType,
  setEditRoleType,
}: {
  editRoleType: AppRoleType;
  setEditRoleType: (value: AppRoleType) => void;
}) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="right-2 flex flex-row items-center justify-start gap-1 cursor-pointer px-2">
        <span className="text-sm text-zinc-400 hover:text-zinc-300 transition-colors">
          {appRoleTypeToDisplayName[editRoleType]}
        </span>
        <ChevronDown size={16} className="text-zinc-500 hover:text-zinc-300 transition-colors" />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={classNames(DropdownClassName, "min-w-[100px] text-zinc-300 text-sm")}
          style={{
            zIndex: MessageDialogZIndex,
          }}
          sideOffset={5}
        >
          <DropdownMenu.Item
            key={AppRoleType.Writer}
            className={classNames(DropdownMenuItemClassName, " truncate")}
            onClick={() => {
              setEditRoleType(AppRoleType.Writer);
            }}
          >
            {appRoleTypeToDisplayName[AppRoleType.Writer]}
          </DropdownMenu.Item>
          {/* <DropdownMenu.Item
            key={AppRoleType.Reader}
            className={classNames(DropdownMenuItemClassName, " truncate")}
            onClick={() => {
              setEditRoleType(AppRoleType.Reader);
            }}
          >
            {appRoleTypeToDisplayName[AppRoleType.Reader]}
          </DropdownMenu.Item> */}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

function TeamRoleDescriptions() {
  return (
    <div className="flex flex-col gap-4">
      <div className="text-sm 2xl:text-base text-zinc-300 font-medium">Team roles</div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="p-4 rounded-lg border border-zinc-800 bg-zinc-900/50">
          <div className="text-sm font-medium text-zinc-200 mb-2">Owner</div>
          <p className="text-sm text-zinc-400">
            Full access to manage team members, billing, and manages the subscription for the team.
            Can create and modify all custom models, generative videos, and drag and drop canvas
            projects.
          </p>
        </div>
        <div className="p-4 rounded-lg border border-zinc-800 bg-zinc-900/50">
          <div className="text-sm font-medium text-zinc-200 mb-2">Editor</div>
          <p className="text-sm text-zinc-400">
            Editing access to train and edit custom models. Able to use custom models to generate
            new images. All model training and generations count towards the owner's subscription
            plan.
          </p>
        </div>
        {/* <div className="p-4 rounded-lg border border-zinc-800 bg-zinc-900/50">
          <div className="text-sm font-medium text-zinc-200 mb-2">Reader</div>
          <p className="text-sm text-zinc-400">
            View only access to custom model image generations. Can view and download existing image
            generations but cannot create new ones.
          </p>
        </div> */}
      </div>
    </div>
  );
}

function InviteTeamMemberInput() {
  const [status, setStatus] = React.useState<InviteTeamMemberInputStatus>(
    InviteTeamMemberInputStatus.Idle,
  );
  const [shareRoleType, setShareRoleType] = React.useState<AppRoleType>(AppRoleType.Writer);
  const [emails, setEmails] = React.useState<string[]>([]);
  const currentSubscription = useUserHighestStripeSubscription();
  const { showToast } = useToast();
  const userHasTeamSubscription =
    currentSubscription === AppSubscriptionPlanType.Scale ||
    currentSubscription === AppSubscriptionPlanType.Enterprise;
  const { setOpen: setOpenManageSubscriptionDialog, setLimitedPlans } =
    useManageSubscriptionDialogContext();
  const { backend, currentTeamId: publicTeamId } = editorContextStore.getState();

  return (
    <div className="w-full flex flex-col sm:flex-row items-start sm:items-center gap-2">
      <div className="relative flex-1 flex flex-row items-center w-full">
        <input
          className={classNames(
            status === InviteTeamMemberInputStatus.Error
              ? InputBoxClassNameError
              : InputBoxClassName,
            "pr-28 text-sm",
          )}
          placeholder="Invite others by email, e.g. name@company.com"
          onBlur={(e) => {
            const value = e.currentTarget.value;
            const emails = extractEmailsFromString(value);

            setEmails(emails);

            if (
              emails.length <= 0 ||
              !emails.some((email) => email.includes("@") && email.endsWith(".com"))
            ) {
              setStatus(InviteTeamMemberInputStatus.Error);
            }
            setStatus(InviteTeamMemberInputStatus.Idle);
          }}
        />
        <div className="absolute right-2">
          <InviteTeamMemberInputRoleSelector
            editRoleType={shareRoleType}
            setEditRoleType={(role) => {
              setShareRoleType(role);
            }}
          />
        </div>
      </div>
      <button
        className={classNames(
          status === InviteTeamMemberInputStatus.Loading
            ? PrimaryButtonClassNameLoading
            : PrimaryButtonClassName,
          "w-full sm:w-auto sm:min-w-[15%] flex flex-row items-center justify-center gap-2 transition-colors",
        )}
        onClick={async () => {
          if (!userHasTeamSubscription) {
            showToast({
              type: "error",
              message: "You need a Scale or Enterprise subscription to invite other users.",
            });
            setLimitedPlans([AppSubscriptionPlanType.Scale, AppSubscriptionPlanType.Enterprise]);
            setOpenManageSubscriptionDialog(true);
            return;
          }

          if (
            emails.length <= 0 ||
            !emails.some((email) => email.includes("@") && email.endsWith(".com"))
          ) {
            showToast({
              type: "error",
              message: "Please enter a valid email.",
            });
            setStatus(InviteTeamMemberInputStatus.Error);
            return;
          }

          if (!backend || !publicTeamId) {
            return;
          }

          setStatus(InviteTeamMemberInputStatus.Loading);

          const clientHostUrl = window.location.origin;

          const response = await backend.inviteUsersToTeam({
            emails,
            role: shareRoleType,
            teamId: publicTeamId,
            clientHostUrl,
          });

          const firstResponse = response[0];

          if (firstResponse.ok) {
            setStatus(InviteTeamMemberInputStatus.Idle);
            showToast({
              type: "info",
              message: "Successfully invited users to team.",
            });
          } else {
            setStatus(InviteTeamMemberInputStatus.Idle);
            showToast({
              type: "error",
              message: "Failed to invite users to team.",
            });
          }
        }}
      >
        {status === InviteTeamMemberInputStatus.Loading ? (
          <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
        ) : null}
        <span className="text-sm md:text-base">Invite member</span>
      </button>
    </div>
  );
}

function ManageTeamDialogContentTitle() {
  return <MessageDialogTitle className="w-full text-zinc-500 mb-2">Manage Team</MessageDialogTitle>;
}

function InviteTeamMemberContent() {
  const currentTeamId = getCurrentTeamId();
  const userTeams = editorContextStore((state) => state.userTeams);
  const backend = editorContextStore((state) => state.backend);
  const publicUserId = editorContextStore((state) => state.publicUserId);
  const [userMetadataDict, setUserMetadataDict] = React.useState<PublicUserMetadataDict>({});
  const userIsOwner = editorContextStore(doesUserHaveTeamFullPermission);
  const team = userTeams[currentTeamId];
  const { showToast } = useToast();

  React.useEffect(() => {
    if (!backend || !userTeams) {
      return;
    }

    getPublicUserMetadata({
      roles: team.roles,
      backend,
    }).then(setUserMetadataDict);
  }, [backend, team, userTeams]);

  const sortedUserMetadataDict = React.useMemo(() => {
    const entries = Object.entries(userMetadataDict);
    entries.sort(([id1, a], [id2, b]) => {
      // Always put current user first
      if (id1 === publicUserId) {
        return -1;
      }
      if (id2 === publicUserId) {
        return 1;
      }

      // Sort alphabetically by name
      return a.name.localeCompare(b.name);
    });
    return Object.fromEntries(entries);
  }, [userMetadataDict, publicUserId]);

  if (!team) {
    return null;
  }
  return (
    <div className="flex flex-col py-3 gap-8 w-full overflow-y-auto max-h-[85vh]">
      {userIsOwner && (
        <div className="flex flex-col gap-4">
          <div className="text-sm 2xl:text-base text-zinc-300 font-medium">Add members</div>
          <InviteTeamMemberInput />
        </div>
      )}
      <div className="flex flex-col gap-4">
        <TeamRoleDescriptions />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center justify-between gap-4">
          <div className="text-sm 2xl:text-base text-zinc-300 font-medium">Team members</div>
          <div className="text-sm 2xl:text-base text-zinc-300 w-[120px] text-left font-medium">
            Permissions
          </div>
        </div>
        <div className="flex flex-col gap-4">
          {getObjectEntries(sortedUserMetadataDict).map(([publicUserId, userMetadata]) => (
            <UserRoleRowItem
              key={publicUserId}
              className="flex flex-row items-center gap-4"
              userMetadata={userMetadata}
              userCanEdit={userIsOwner}
              setUserRole={async (role) => {
                const { backend } = editorContextStore.getState();

                if (!backend) {
                  return;
                }

                debugLog(`Set user ${publicUserId} to role ${role} for team ${currentTeamId}`);

                const response = await backend.updateUserTeamRole({
                  teamId: currentTeamId,
                  userRoles: {
                    [publicUserId]: role,
                  },
                });

                if (response.ok) {
                  showToast({
                    type: "info",
                    message: "User role updated.",
                  });
                } else {
                  showToast({
                    type: "error",
                    message: `Cannot update user role: ${response.message}`,
                  });
                }
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export type ManageTeamDialogContentProps = PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement> & {}
>;

export const ManageTeamDialogContent = React.forwardRef(function ManageTeamDialogContent(
  { className = "", children, ...props }: ManageTeamDialogContentProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const [shareRoleType, setShareRoleType] = React.useState(AppRoleType.Reader);
  return (
    <div
      {...props}
      ref={forwardedRef}
      className={classNames(
        DropdownClassName,
        "rounded-2xl min-w-[90vw] md:min-w-0 w-[95vw] 2xl:w-[1350px] max-h-[180vh] md:h-[min(800px,95vh)] px-6 py-4 text-base flex flex-col items-center overflow-hidden",
        className,
      )}
    >
      <MessageDialogClose className="absolute right-4">
        <Cross1Icon className="text-zinc-500 hover:text-zinc-200 cursor-pointer transition-colors" />
      </MessageDialogClose>
      <ManageTeamDialogContentTitle />
      <InviteTeamMemberContent />
    </div>
  );
});
interface ManageTeamDialogContextType {
  open: boolean;
  setOpen: (value: boolean) => void;
}

// Create a context with an undefined default value. We will never actually use this value directly;
// instead, we will always use the provider below to wrap our components, which provides an actual value.
const ManageTeamDialogContext = createContext<ManageTeamDialogContextType>({
  open: false,
  setOpen: () => {},
});

export function useManageTeamDialogContext() {
  return React.useContext(ManageTeamDialogContext);
}

// Define the props for the provider, including the children it will wrap
interface ManageTeamDialogProviderProps {
  children: React.ReactNode;
}

// Create a provider component
export const ManageTeamDialogProvider: React.FC<ManageTeamDialogProviderProps> = ({ children }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  // The value that will be provided to the descendants of this provider
  const value = { open, setOpen };

  return (
    <ManageTeamDialogContext.Provider value={value}>{children}</ManageTeamDialogContext.Provider>
  );
};

export function ManageTeamDialog(props: MessageDialogProps) {
  const { open, setOpen } = useManageTeamDialogContext();

  return (
    <MessageDialog
      {...props}
      open={open}
      onOpenChange={setOpen}
      contentChildren={<ManageTeamDialogContent />}
    />
  );
}
