import { FlairStorageManager } from "@/backend/firebase/storage/storage-manager";
import {
  DropdownClassName,
  frostedGlassClassName,
  SecondaryButtonClassNameInactive,
} from "@/components/constants/class-names";
import { FloatTagZIndex } from "@/components/constants/zIndex";
import { ImageComponent } from "@/components/utils/image";
import { MasonryGrid } from "@/components/utils/masonry-grid";
import { ProgressHandler } from "@/components/utils/progress-handler";
import { editorContextStore } from "@/contexts/editor-context";
import {
  defaultVideoImageUrl,
  isVideoGenerationStatusActive,
  LumaGenerationKeyframe,
  VideoGenerationDoc,
  VideoGenerationStatus,
} from "@/core/common/types/video";
import { Assets } from "@/core/controllers/assets";
import { classNames } from "@/core/utils/classname-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { capitalizeFirstLetter, isDataURL, isValidHttpsUrl } from "@/core/utils/string-utils";
import { getTimeDifferenceInSeconds, sortByTimeModified } from "@/core/utils/time-utils";
import { getObjectEntries } from "@/core/utils/type-utils";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross1Icon } from "@radix-ui/react-icons";
import { clamp, noop } from "lodash";
import { Play } from "lucide-react";
import React, { PropsWithChildren } from "react";
import styles from "./dashboard-video.module.css";
import { VideoComponent } from "./video-component";

const expectedVideoLoadingTimeSeconds = Math.round(2.5 * 60);

export async function getVideoThumbnailUrlFromGeneration({
  storageManager,
  generation,
}: {
  storageManager: FlairStorageManager;
  generation: VideoGenerationDoc;
}) {
  try {
    const thumbnailStoragePath = generation?.thumbnailStoragePath;

    if (!storageManager || !thumbnailStoragePath) {
      return defaultVideoImageUrl;
    }

    return await Assets.loadAssetFromPath({
      path: thumbnailStoragePath,
      storageManager,
    });
  } catch (error) {
    debugError(`Error loading thumbnail from generation ${generation.id}: `, error);
    return defaultVideoImageUrl;
  }
}

export function useVideoGenerationThumbnail({
  storageManager,
  generation,
}: {
  storageManager: FlairStorageManager;
  generation: VideoGenerationDoc;
}) {
  const [thumbnailUrl, setThumbnailUrl] = React.useState(defaultVideoImageUrl);

  React.useEffect(() => {
    getVideoThumbnailUrlFromGeneration({
      storageManager,
      generation,
    }).then((url) => {
      setThumbnailUrl(url || defaultVideoImageUrl);
    });
  }, [storageManager, generation, generation?.thumbnailStoragePath]);

  return thumbnailUrl;
}

export function useVideoGenerationProgress({
  generation,
  onGenerationUpdate,
}: {
  generation: VideoGenerationDoc;
  onGenerationUpdate: (value: VideoGenerationDoc) => void;
}) {
  const [progressInternal, setProgressInternal] = React.useState(0);

  const progressHandlerRef = React.useRef<ProgressHandler>(
    new ProgressHandler({
      speed: 5e-3,
      setProgress: setProgressInternal,
    }),
  );

  const backend = editorContextStore((state) => state.backend);

  const setProgressFromGeneration = React.useCallback((generation: VideoGenerationDoc) => {
    const timestamp = generation.timeModified || generation.timeCreated;

    const elapsedSeconds = getTimeDifferenceInSeconds({
      startTime: timestamp.toDate(),
    });

    const progress = clamp(elapsedSeconds / expectedVideoLoadingTimeSeconds, 0.01, 0.99);

    progressHandlerRef.current.setProgress(progress);
  }, []);

  React.useEffect(() => {
    setProgressFromGeneration(generation);

    if (!isVideoGenerationStatusActive(generation.status)) {
      return;
    }

    return backend?.onVideoGenerationDocUpdate({
      generationId: generation.id,
      callback: (newGeneration) => {
        if (!newGeneration) {
          return;
        }

        // getVideoThumbnailUrlFromGeneration({
        //     backend,
        //     generation: newGeneration,
        // }).then((url) => {
        //     setThumbnailUrl((prevUrl) => url || prevUrl);
        // });

        onGenerationUpdate(newGeneration);

        setProgressFromGeneration(newGeneration);
      },
    });
  }, [backend, generation, setProgressFromGeneration, onGenerationUpdate]);

  return progressInternal;
}

function VideoGenerationLoading({
  generation,
  thumbnailUrl: initThumbnailUrl,
}: {
  generation: VideoGenerationDoc;
  thumbnailUrl: string;
}) {
  // const [progressInternal, setProgressInternal] = React.useState(0);

  const storageManager = editorContextStore((state) => state.storageManager);

  const [thumbnailUrl, setThumbnailUrl] = React.useState(initThumbnailUrl);

  const progressInternal = useVideoGenerationProgress({
    generation,
    onGenerationUpdate: (newGeneration) => {
      getVideoThumbnailUrlFromGeneration({
        storageManager,
        generation: newGeneration,
      }).then((url) => {
        setThumbnailUrl((prevUrl) => url || prevUrl);
      });
    },
  });

  return (
    <div
      className={classNames(
        SecondaryButtonClassNameInactive,
        "group relative p-0 overflow-hidden cursor-wait",
      )}
    >
      <ImageComponent src={thumbnailUrl} className="object-cover" />
      <div className={classNames("absolute m-2 left-0 bottom-0", frostedGlassClassName)}>
        Progress {Math.round(progressInternal * 100)} %
      </div>
      <div className={classNames("absolute left-0 bottom-0 w-full h-px bg-zinc-800/10")}>
        <div
          className={`${styles.TransitionWidth} rounded-full h-full bg-lime-500`}
          style={{
            width: `${progressInternal * 100}%`,
          }}
        />
      </div>
    </div>
  );
}

async function getGenerationKeyFrameImageUrl({
  storageManager,
  keyframe,
}: {
  keyframe?: LumaGenerationKeyframe;
  storageManager: FlairStorageManager;
}) {
  try {
    if (!keyframe) {
      return null;
    }

    const path = keyframe.storagePath;

    if (!storageManager || !path) {
      return null;
    }

    if (isValidHttpsUrl(path) || isDataURL(path)) {
      return path;
    }

    debugLog("Load image from path ", path);

    return await Assets.loadAssetFromPath({
      storageManager,
      path,
    });
  } catch (error) {
    debugError(`Error loading keyframe from url `, error);
  }
  return null;
}

export function VideoGenerationEditor({
  generation,
  thumbnailUrl,
}: {
  thumbnailUrl: string;
  generation: VideoGenerationDoc;
}) {
  const storageManager = editorContextStore((state) => state.storageManager);
  const [videoSrc, setVideoSrc] = React.useState("");
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);

  const src = React.useMemo(
    () => generation.outputVideoStoragePath || "",
    [generation.outputVideoStoragePath],
  );

  const isFirstLoadRef = React.useRef(true);

  React.useEffect(() => {
    if (!src) {
      return;
    }

    if (!isFirstLoadRef.current) {
      return;
    }

    isFirstLoadRef.current = true;

    if (isValidHttpsUrl(src) || isDataURL(src)) {
      setVideoSrc(src);
      return;
    }

    if (!storageManager) {
      return;
    }

    Assets.loadAssetFromPath({
      storageManager,
      path: src,
    }).then((videoSrc) => {
      setVideoSrc(videoSrc ?? "");
    });
  }, [src, storageManager]);

  const handleCanPlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }

    setIsVideoLoaded(true);
  };

  React.useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("canplay", handleCanPlay);
      return () => {
        videoElement.removeEventListener("canplay", handleCanPlay);
      };
    }
  }, [videoSrc]);

  const [keyframeImageUrls, setKeyframeImageUrls] = React.useState<(string | null)[]>([null, null]);

  React.useEffect(() => {
    Promise.all(
      Object.values(generation.request?.keyframes ?? {}).map((keyframe) => {
        return getGenerationKeyFrameImageUrl({
          keyframe,
          storageManager,
        });
      }),
    ).then(setKeyframeImageUrls);
  }, [storageManager, generation]);

  return (
    <div className="overflow-x-hidden overflow-y-auto">
      <div className={classNames("relative flex-1 flex flex-col xl:flex-row items-stretch gap-2")}>
        <div className={classNames("xl:min-w-[60%] relative rounded overflow-hidden")}>
          <ImageComponent
            src={thumbnailUrl}
            alt=""
            className={classNames(
              "w-full h-full object-cover transition-opacity duration-500",
              isVideoLoaded ? "opacity-0" : "opacity-100",
            )}
          />
          {videoSrc && (
            <video
              muted
              loop
              preload="auto"
              ref={videoRef}
              controls
              className={classNames(
                "w-full h-full object-cover absolute top-0 left-0 transition-opacity duration-500",
                isVideoLoaded ? "opacity-100" : "opacity-0",
              )}
            >
              <source src={videoSrc} type="video/mp4" />
            </video>
          )}
        </div>
        <div className="flex-1 flex flex-col items-stretch text-sm gap-4">
          <div className="group flex flex-col items-stretch gap-1 text-zinc-300 hover:text-zinc-200 transition-colors">
            <div>Prompt</div>
            <div className="px-2.5 py-1.5 bg-zinc-800/50 border border-zinc-800 rounded">
              {generation.request?.prompt ?? "No prompt found."}
            </div>
          </div>
          <div className="group flex flex-col items-stretch gap-1 text-zinc-300 hover:text-zinc-200 transition-colors">
            <div>Keyframes</div>
            <div className="grid grid-cols-2 gap-1">
              {keyframeImageUrls.map((imageUrl, index) => (
                <div key={index} className="rounded overflow-hidden w-full border border-zinc-800">
                  <AspectRatio.Root ratio={1} className="animation-shine">
                    {imageUrl && (
                      <ImageComponent
                        key={index}
                        className="rounded animation-shine"
                        src={imageUrl || ""}
                      />
                    )}
                  </AspectRatio.Root>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function VideoGenerationFinished({
  generation,
  thumbnailUrl,
}: {
  generation: VideoGenerationDoc;
  thumbnailUrl: string;
}) {
  const backend = editorContextStore((state) => state.backend);

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <div
          className={classNames(
            SecondaryButtonClassNameInactive,
            "group relative p-0 overflow-hidden",
          )}
        >
          <VideoComponent
            backend={backend}
            src={generation?.outputVideoStoragePath ?? ""}
            thumbnailUrl={thumbnailUrl}
            className="w-full h-full"
          />
          <div className="absolute m-2 left-0 bottom-0 flex flex-row items-stretch justify-stretch gap-2">
            {generation.status === VideoGenerationStatus.Succeeded && (
              <button
                className={classNames(
                  "group-hover:text-zinc-100 group-hover:backdrop-blur-md",
                  frostedGlassClassName,
                )}
              >
                <Play size={14} />
              </button>
            )}
            <div
              className={classNames(
                "group-hover:text-white group-hover:backdrop-blur-md",
                frostedGlassClassName,
              )}
            >
              {capitalizeFirstLetter(generation.status)}
            </div>
          </div>
        </div>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          className={styles.DialogOverlay}
          style={{
            zIndex: FloatTagZIndex,
          }}
        />
        <Dialog.Content
          className={classNames(
            styles.DialogContent,
            DropdownClassName,
            "rounded-xl w-[90vw] md:w-[80vw] xl:w-[1024px] h-[80vh] xl:h-fit xl:max-h-[80vh] p-2.5 flex flex-col gap-2 items-stretch",
          )}
          style={{
            zIndex: FloatTagZIndex,
          }}
        >
          <Dialog.Title className="text-zinc-500">Generated video</Dialog.Title>
          <Dialog.Close className="absolute right-3 text-zinc-500 hover:text-zinc-300 cursor-pointer transition-colors">
            <Cross1Icon width={16} height={16} />
          </Dialog.Close>
          <VideoGenerationEditor thumbnailUrl={thumbnailUrl} generation={generation} />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export function VideoGeneration({ generation }: { generation: VideoGenerationDoc }) {
  const storageManager = editorContextStore((state) => state.storageManager);

  const thumbnailUrl = useVideoGenerationThumbnail({
    storageManager,
    generation,
  });

  if (isVideoGenerationStatusActive(generation.status)) {
    return <VideoGenerationLoading generation={generation} thumbnailUrl={thumbnailUrl} />;
  }

  return <VideoGenerationFinished generation={generation} thumbnailUrl={thumbnailUrl} />;
}

export type VideoPastGenerations = Record<string, VideoGenerationDoc>;

interface VideoPastGenerationContextType {
  generateVideoPastGenerations: VideoPastGenerations;
  setGenerateVideoPastGenerations: React.Dispatch<React.SetStateAction<VideoPastGenerations>>;
}

// Create the context with default undefined
const VideoPastGenerationContext = React.createContext<VideoPastGenerationContextType>({
  generateVideoPastGenerations: {},
  setGenerateVideoPastGenerations: noop,
});

// Custom hook for consuming the context
export const useVideoPastGenerationContext = (): VideoPastGenerationContextType => {
  const context = React.useContext(VideoPastGenerationContext);
  return context;
};

export const VideoPastGenerationProvider = ({ children }: PropsWithChildren) => {
  const [generateVideoPastGenerations, setGenerateVideoPastGenerations] =
    React.useState<VideoPastGenerations>({});
  return (
    <VideoPastGenerationContext.Provider
      value={{
        generateVideoPastGenerations,
        setGenerateVideoPastGenerations,
      }}
    >
      {children}
    </VideoPastGenerationContext.Provider>
  );
};

export function useVideoPastGenerationsUpdateEffect() {
  const { setGenerateVideoPastGenerations } = useVideoPastGenerationContext();

  const backend = editorContextStore((state) => state.backend);
  const publicUserId = editorContextStore((state) => state.publicUserId);

  React.useEffect(() => {
    if (!publicUserId) {
      return;
    }

    return backend?.onUserVideoGenerationsUpdate({
      publicUserId,
      callback: (generations) => {
        setGenerateVideoPastGenerations(generations);
      },
    });
  }, [backend, publicUserId, setGenerateVideoPastGenerations]);
}

export function useVideoPastGenerationsItems() {
  const { generateVideoPastGenerations } = useVideoPastGenerationContext();

  return React.useMemo(() => {
    const items = getObjectEntries(generateVideoPastGenerations).sort(([, a], [, b]) =>
      sortByTimeModified(a, b),
    );
    return items;
  }, [generateVideoPastGenerations]);
}

export function VideoPastGenerationsMasonryGrid({
  className = "",
  columnClassName = "",
  breakpoints = {
    4096: 9,
    2560: 8,
    2048: 7,
    1792: 6,
    1280: 5,
    1024: 4,
    512: 3,
    256: 2,
    0: 1,
  },
}: {
  className?: string;
  columnClassName?: string;
  breakpoints?: Record<number, number>;
}) {
  const backend = editorContextStore((state) => state.backend);

  useVideoPastGenerationsUpdateEffect();

  const generateVideoPastGenerations = useVideoPastGenerationsItems();

  return (
    <MasonryGrid
      className={classNames("gap-2", className)}
      columnClassName={classNames("gap-2", columnClassName)}
      items={generateVideoPastGenerations}
      breakpoints={breakpoints}
      renderItem={([generationId, generation]) => (
        <VideoGeneration key={generationId} generation={generation} />
      )}
    />
  );
}
