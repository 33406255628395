import { DEFAULT_CANVAS_LENGTH, MAX_OBJECT_LENGTH } from "@/core/common/constants";
import type { IStaticImage, IStaticVideo } from "@/core/common/layers";
import {
  EditorImageAsset,
  EditorVideoAsset,
  UiCloseMessageDialogEventHandler,
  UiDisplayMessageDialogEventHandler,
  UiDisplayMessageDialogEventHandlerProps,
  UserAssetInfoType,
  UserAssetType,
} from "@/core/common/types";
import { Assets } from "@/core/controllers/assets";
import Objects from "@/core/controllers/objects";
import { isEditorAssetContentTypeValid, isHeicImageType } from "@/core/utils/asset-utils";
import { loadImageElementFromURL } from "@/core/utils/image-loader";
import { resizeImageCanvasElement } from "@/core/utils/image-utils";
import { editorContextStore } from "contexts/editor-context";
import { fabric } from "fabric";
import { clamp } from "lodash";
import { nanoid } from "nanoid";
import { toBase64 } from "./data";
import { captureFrame, loadVideoResource } from "./video";

import heic2any from "heic2any";

async function stringfyImage(file: File | Blob) {
  const src = URL.createObjectURL(file);
  const imageElement: HTMLImageElement | HTMLCanvasElement = await loadImageElementFromURL(src);
  URL.revokeObjectURL(src);
  const maxLength = Math.max(imageElement.width, imageElement.height);
  if (maxLength > MAX_OBJECT_LENGTH) {
    const scale = MAX_OBJECT_LENGTH / maxLength;
    const width = Math.round(imageElement.width * scale);
    const height = Math.round(imageElement.height * scale);
    const resizedImageElement = await resizeImageCanvasElement({
      from: imageElement,
      width,
      height,
    });
    if (!resizedImageElement) {
      return "";
    }
    return resizedImageElement.toDataURL();
  }
  return (await toBase64(file)) as string;
}

async function stringfyVideo(file: File | Blob) {
  const base64 = (await toBase64(file)) as string;
  let preview = base64;
  const video = await loadVideoResource(base64);
  const frame = await captureFrame(video);
  preview = frame;
  return {
    base64,
    preview,
  };
}

async function stringfyFile(file: File | Blob, isVideo: boolean) {
  if (isVideo) {
    return await stringfyVideo(file);
  }
  const base64 = await stringfyImage(file);
  return {
    base64,
    preview: base64,
  };
}

export async function handleUploadFile(
  file: File | Blob,
  assets: Assets,
  userAssetInfoType?: UserAssetInfoType,
  assetType?: UserAssetType,
): Promise<{
  object: IStaticImage | IStaticVideo | null;
  uploadPromise: Promise<string | undefined> | null;
}> {
  if (!file) {
    return {
      object: null,
      uploadPromise: null,
    };
  }

  try {
    const contentType = file.type;

    if (isHeicImageType(contentType)) {
      const convertedFile = await heic2any({
        blob: file,
        toType: "image/png",
      });

      if (Array.isArray(convertedFile)) {
        file = convertedFile[0];
      } else {
        file = convertedFile;
      }
    }

    if (isEditorAssetContentTypeValid(contentType)) {
      const isVideo = contentType.includes("video");

      const { base64, preview } = await stringfyFile(file, isVideo);

      const type = isVideo ? "StaticVideo" : "StaticImage";

      const id = nanoid();

      const uploadPromise = assets
        .addAsset({
          data: base64,
          contentType,
          assetType,
        })
        .then((path) => {
          const newAsset = isVideo
            ? ({
                type: "video-storage",
                path,
                contentType,
              } as EditorVideoAsset)
            : ({
                type: "image-storage",
                path,
                contentType,
              } as EditorImageAsset);
          assets.setObjectAsset(id, newAsset);
          return path;
        })
        .then(async (storagePath) => {
          if (userAssetInfoType && storagePath) {
            await assets.setUserAssetInfo({
              assetType: userAssetInfoType,
              storagePath,
            });
          }
          return storagePath;
        });

      const upload: IStaticImage | IStaticVideo = {
        id,
        src: base64,
        preview,
        type,
      };

      return {
        object: upload,
        uploadPromise,
      };
    }
  } catch (error) {
    console.error(error);
  }

  return {
    object: null,
    uploadPromise: null,
  };
}

function removeImageObjectBackgroundPopup(props: UiDisplayMessageDialogEventHandlerProps) {
  const { editor, backend } = editorContextStore.getState();

  if (!editor || !backend) {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    editor.emit<UiDisplayMessageDialogEventHandler>(
      "ui:display-message-dialog",
      "remove-background",
      props,
    );

    editor.once<UiCloseMessageDialogEventHandler>("ui:close-message-dialog", resolve);
  });
}

function getTargetScaleFromWidthHeight(
  width: number,
  height: number,
  minScaleLimit: number = 0.01,
) {
  const maxLength = Math.max(width, height);
  const targetLength = clamp(maxLength, DEFAULT_CANVAS_LENGTH * 0.25, DEFAULT_CANVAS_LENGTH * 0.75);
  return Math.max(targetLength / maxLength, minScaleLimit);
}

export function getObjectTargetScale(object: fabric.Object) {
  const width = object.width;
  const height = object.height;
  if (!width || !height) {
    return 1;
  }
  return getTargetScaleFromWidthHeight(width, height, object.minScaleLimit);
}

export async function uploadAndAddFiles({
  files,
  assets,
  location,
  editorObjects,
  removeBackgroundPopup = false,
  userAssetInfoType,
  removeBackgroundPopupProps,
  assetType,
}: {
  files: FileList;
  assets: Assets;
  editorObjects: Objects;
  location?: { x: number; y: number };
  removeBackgroundPopup?: boolean;
  userAssetInfoType?: UserAssetInfoType;
  removeBackgroundPopupProps?: Partial<UiDisplayMessageDialogEventHandlerProps>;
  assetType?: UserAssetType;
}) {
  const addObjectPromises: Promise<fabric.Object | undefined>[] = [];
  const uploadObjectPromises: Promise<unknown>[] = [];
  const initLocation = editorObjects.getDefaultObjectLocation(location);

  for (let i = 0; i < files.length; ++i) {
    addObjectPromises.push(
      handleUploadFile(files[i], assets, userAssetInfoType, assetType).then(
        ({ object, uploadPromise }) => {
          if (!object) {
            return;
          }

          if (uploadPromise) {
            uploadObjectPromises.push(uploadPromise);
          }

          return editorObjects
            .add(object, {
              x: initLocation.x + (i - 0.5) * initLocation.width,
              y: initLocation.y,
            })
            .then((object) => {
              if (object) {
                object.visible = false;
              }
              return object;
            });
        },
      ),
    );
  }

  return await Promise.all(addObjectPromises)
    .then((objects) => {
      let currX = initLocation.x - 0.5 * initLocation.width;
      let isFirst = true;
      objects.forEach((object, index) => {
        if (object) {
          const width = object.width || initLocation.width;
          const height = object.height || initLocation.width;
          const scale = getTargetScaleFromWidthHeight(width, height, object.minScaleLimit);

          if (isFirst) {
            isFirst = false;
            currX = initLocation.x - 0.5 * width * scale;
          }

          object.left = currX;
          object.top = initLocation.y - 0.5 * height * scale;
          object.scale(scale);
          object.setCoords();
          object.visible = true;
          currX += width * scale;
        }
      });
      return objects;
    })
    .then(async (objects) => {
      if (removeBackgroundPopup) {
        objects = objects.filter(Boolean);
        console.log("Remove background");

        await removeImageObjectBackgroundPopup({
          ...removeBackgroundPopupProps,
          objects,
        });
      }
      await Promise.all(uploadObjectPromises);
      return objects;
    });
}
