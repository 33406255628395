import { Backend } from "@/backend/base";
import { FlairStorageManager } from "@/backend/firebase/storage/storage-manager";
import { EditorEventEmitter } from "@/core/common/editor-event-emitter";
import {
  EditorImageAsset,
  StateUpdater,
  UiDisplayMessageDialogEventHandler,
  UiDisplayMessageEventHandler,
} from "@/core/common/types";
import {
  StatcImageVideoGenerationMetadata,
  StaticImageElementColorDisplayType,
  StaticImageElementType,
} from "@/core/common/types/elements";
import {
  defaultVideoImageUrl,
  GenerateVideoEditorSection,
  GenerateVideoEditorState,
  GenerateVideoKeyFrame,
  GenerateVideoKeyFrames,
  GenerateVideoKeyFrameType,
  GenerateVideoKeyFrameUploadButton,
  GenerateVideoKeyFrameWithImage,
  GenerateVideoPromptGenerationStatus,
  getGenerateVideoKeyFramesWithUploadButtons,
  getKeyFramesFromGenerateVideoKeyFrames,
  isGenerateVideoKeyFrameWithImage,
  videoCameraMotionConfigs,
  VideoGenerationAspectRatio,
  videoGenerationAspectRatioToNumber,
  VideoGenerationBackendType,
  VideoGenerationResponse,
  VideoGenerationStatus,
} from "@/core/common/types/video";
import { Editor } from "@/core/editor";
import { getDataUrlFromBlob } from "@/core/utils/asset-utils";
import { classNames } from "@/core/utils/classname-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import {
  isStaticImageObjectGenerated,
  isStaticImageObjectUploaded,
} from "@/core/utils/type-guards";
import { getObjectEntries } from "@/core/utils/type-utils";
import { generateUUID } from "@/core/utils/uuid-utils";
import { canUserStartVideoGeneration } from "@/core/utils/video-utils";
import { getTeamOrUserQuotas } from "@/hooks/use-user-quotas";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Cross1Icon } from "@radix-ui/react-icons";
import {
  DropdownClassName,
  InputBoxClassName,
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  PrimaryButtonClassNameLoading,
  SecondaryButtonClassNameInactive,
  TextButtonClassName,
  TextButtonClassNameDisabled,
} from "components/constants/class-names";
import { LeftPanelDropdownZIndex } from "components/constants/zIndex";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { SortableItem, SortableList } from "components/sortable/sortable-list";
import DropdownMenuStyles from "components/utils/dropdown-options.module.css";
import { ImageComponent } from "components/utils/image";
import { editorContextStore } from "contexts/editor-context";
import { fabric } from "fabric";
import { Image, ImageUp, LogOut, RotateCcw, Sparkles, Upload } from "lucide-react";
import React from "react";
import { VideoComponent, VideoComponentProps } from "./video-component";

function getGenerateVideoImageUrl({
  generateVideoKeyFrames,
}: {
  generateVideoKeyFrames: GenerateVideoKeyFrames;
}) {
  const keyframe = generateVideoKeyFrames.find(
    (keyframe) => keyframe.type !== GenerateVideoKeyFrameType.UploadButton,
  ) as GenerateVideoKeyFrameWithImage;

  if (!keyframe) {
    return defaultVideoImageUrl;
  }

  return keyframe.src || defaultVideoImageUrl;
}

export async function handleStartVideoGenerationBackend({
  backend,
  storageManager,
  eventEmitter,
  generateVideoKeyFrames,
  generateVideoPrompt,
  generateVideoAspectRatio,
}: GenerateVideoEditorState & {
  backend?: Backend;
  storageManager: FlairStorageManager;
  eventEmitter: EditorEventEmitter;
}): Promise<VideoGenerationResponse> {
  try {
    if (!backend || !storageManager) {
      debugError("Cannot generate video because the backend is not initialized yet.");
      return {
        ok: false,
        message: "Cannot generate video because the backend is not initialized yet.",
      };
    }

    const response = await backend.generateVideo({
      type: VideoGenerationBackendType.Luma,
      prompt: generateVideoPrompt,
      aspect_ratio: generateVideoAspectRatio,
      keyframes: getKeyFramesFromGenerateVideoKeyFrames({
        storageManager,
        keyframes: generateVideoKeyFrames,
      }),
    });

    if (!response.ok) {
      eventEmitter.emit<UiDisplayMessageEventHandler>(
        "ui:display-message",
        "error",
        // @ts-expect-error
        response.message,
      );
      return response;
    }

    return response;
  } catch (error) {
    debugError("Cannot generate video because error ", error);

    return {
      ok: false,
      message: "Unknown server error.",
    };
  }
}

export async function handleStartVideoGenerationOnCanvas(
  args: GenerateVideoEditorState & {
    backend?: Backend;
    storageManager: FlairStorageManager;
    editor?: Editor | null;
    eventEmitter: EditorEventEmitter;
  },
) {
  try {
    const { editor, backend, storageManager, generateVideoKeyFrames } = args;

    if (!backend || !storageManager) {
      debugError("Cannot generate video because the backend is not initialized yet.");
      return;
    }

    const response = await handleStartVideoGenerationBackend(args);

    if (!response.ok) {
      return;
    }

    const generationId = response.generationId;

    const imageUrl = getGenerateVideoImageUrl({
      generateVideoKeyFrames,
    });

    let location:
      | {
          x: number;
          y: number;
        }
      | undefined = undefined;

    const sourceObjectId = generateVideoKeyFrames
      .map(
        (keyframe) => keyframe?.type === GenerateVideoKeyFrameType.ImageObject && keyframe.objectId,
      )
      .find(Boolean);

    if (sourceObjectId) {
      const sourceImageObject = editor?.objects.findOneById(sourceObjectId) as fabric.Object;

      if (sourceImageObject) {
        location = sourceImageObject.getCenterPoint();
        location.x += sourceImageObject.getScaledWidth();
      }
    }

    const videoMetadata: StatcImageVideoGenerationMetadata = {
      videoGenerationId: generationId,
      videoGenerationStatus: VideoGenerationStatus.Starting,
    };

    const outputImageObject = await editor?.objects?.addImageFromUrl({
      url: imageUrl,
      location,
      metadata: {
        ...videoMetadata,
        imageType: StaticImageElementType.Color,
        colorDisplayType: StaticImageElementColorDisplayType.RGB,
      },
    });

    if (!outputImageObject) {
      debugError(`Cannot add output image from url ${imageUrl}`);
      return null;
    }

    // if (location) {
    //     outputImageObject.set('left', location.x);
    //     outputImageObject.set('top', location.y);
    //     outputImageObject.setCoords();
    // }

    return outputImageObject;
  } catch (error) {
    debugError("Error handling start generating video: ", error);
  }
  return null;
}

/* @tw */
const sortableItemWidthClassName = "w-[30vw] md:w-[20vw] lg:w-[15vw] 2xl:w-[256px]";

/* @tw */
const sortableItemClassName = classNames(
  sortableItemWidthClassName,
  "bg-[#1D1D20] text-zinc-300 rounded-md border border-zinc-500/10 hover:border-lime-500/50 focus:outline-none focus-visible:outline-none focus-visible:border-lime-500 transition-colors overflow-hidden cursor-move",
);

function SortableItemGenerateVideoKeyFrame({
  item,
  itemIndexRatio,
  isOverlay,
  generateVideoAspectRatio,
}: {
  item: GenerateVideoKeyFrameWithImage;
  itemIndexRatio: number;
  isOverlay: boolean;
  generateVideoAspectRatio: VideoGenerationAspectRatio;
}) {
  const setGenerateVideoKeyFrames = editorContextStore((state) => state.setGenerateVideoKeyFrames);

  return (
    <SortableItem id={item.id} className={classNames(sortableItemClassName)}>
      <AspectRatio.Root
        ratio={videoGenerationAspectRatioToNumber[generateVideoAspectRatio]}
        className="group/item relative flex flex-col items-center justify-center"
      >
        <ImageComponent src={item.src} className="object-cover" />
        <div className={classNames("absolute top-0 right-0 m-1 md:m-2 text-xs")}>
          <button
            className="w-6 h-6 flex items-center justify-center rounded-full text-zinc-100/50 group-hover:text-zinc-100 hover:text-zinc-100 group-hover/item:text-zinc-100 backdrop-blur-sm bg-zinc-800/10 hover:bg-zinc-800/30 hover:backdrop-blur-lg border border-zinc-100/10 cursor-pointer truncate transition-all"
            onPointerDown={() => {
              setGenerateVideoKeyFrames((prevKeyFrames) =>
                getGenerateVideoKeyFramesWithUploadButtons({
                  keyFrames: prevKeyFrames.map((keyframe) => {
                    if (keyframe.id !== item.id) {
                      return keyframe;
                    }
                    return {
                      type: GenerateVideoKeyFrameType.UploadButton,
                      id: keyframe.id,
                    };
                  }),
                }),
              );
            }}
          >
            <Cross1Icon width={14} height={14} />
          </button>
        </div>
        <div
          className={classNames(
            isOverlay ? "hidden" : "block",
            "absolute bottom-0 left-0 m-1 md:m-2 text-xs",
          )}
        >
          <button className="px-2.5 py-1 w-fit flex items-center justify-center rounded-full text-zinc-100/50 group-hover:text-zinc-100 hover:text-zinc-100 group-hover/item:text-zinc-100 backdrop-blur-sm bg-zinc-800/10 hover:backdrop-blur-lg border border-zinc-100/10 truncate transition-all">
            {itemIndexRatio > 0.5 ? "End frame" : "Start frame"}
          </button>
        </div>
      </AspectRatio.Root>
    </SortableItem>
  );
}

/* @tw */
const uploadButtonClassNameBase =
  "px-2 py-1 flex flex-row items-center justify-start gap-2 rounded-md bg-transparent shadow-none text-xs lg:text-sm cursor-pointer transition-colors";

/* @tw */
const uploadButtonClassName = classNames(
  uploadButtonClassNameBase,
  "group-hover:text-zinc-300 hover:bg-zinc-700 text-zinc-500 hover:shadow",
);

/* @tw */
const uploadButtonClassNameLoading = classNames(uploadButtonClassNameBase, "text-zinc-700");

export function getGenerateVideoKeyFrameFromImageObject(
  imageObject: fabric.StaticImage,
): GenerateVideoKeyFrame {
  return {
    id: generateUUID(),
    type: GenerateVideoKeyFrameType.ImageObject,
    objectId: imageObject.id,
    src: imageObject.getSrc(),
    width: imageObject.width ?? 1,
    height: imageObject.height ?? 1,
    generationId: imageObject?.generationId,
    asset: imageObject.asset as EditorImageAsset,
  };
}

enum SortableItemUploadButtonStatus {
  Ready = "Ready",
  Uploading = "Uploading",
}

function getFileFromFileList(fileList: FileList): File | null {
  for (let i = 0; i < fileList.length; ++i) {
    const file = fileList[i];

    // Check if the file is a valid image by verifying the MIME type
    if (file.type.startsWith("image/")) {
      return file; // Return the first valid image file found
    }
  }

  // If no valid image file was found, return null
  return null;
}

async function uploadVideoKeyFrame({ data }: { data: File | Blob }) {
  try {
    const { backend } = editorContextStore.getState();
    if (!backend) {
      return "";
    }

    return await backend.uploadVideoKeyFrameToStorage({
      data,
    });
  } catch (error) {
    console.error("Error uploading key frame to backend: ", error);
  }

  return "";
}

function SortableItemUploadButton({
  item,
  itemIndexRatio,
  imageObjects,
  isOverlay,
  generateVideoAspectRatio,
}: {
  isOverlay: boolean;
  imageObjects?: fabric.StaticImage[];
  item: GenerateVideoKeyFrameUploadButton;
  itemIndexRatio: number;
  generateVideoAspectRatio: VideoGenerationAspectRatio;
  onPointerDown?: React.PointerEventHandler<HTMLDivElement>;
}) {
  const [status, setStatus] = React.useState(SortableItemUploadButtonStatus.Ready);

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const setGenerateVideoKeyFrames = editorContextStore((state) => state.setGenerateVideoKeyFrames);

  const replaceKeyFrame = React.useCallback(
    (keyFrame: StateUpdater<GenerateVideoKeyFrame>) =>
      setGenerateVideoKeyFrames((prevKeyFrames) => {
        return prevKeyFrames.map((frame) =>
          frame.id === item.id
            ? typeof keyFrame === "function"
              ? keyFrame(frame)
              : keyFrame
            : frame,
        );
      }),
    [item.id, setGenerateVideoKeyFrames],
  );

  const addFiles = React.useCallback(
    async (newFiles: FileList) => {
      if (newFiles.length <= 0) {
        return;
      }

      setStatus(SortableItemUploadButtonStatus.Uploading);

      try {
        const file = getFileFromFileList(newFiles);

        const { eventEmitter } = editorContextStore.getState();

        if (!file) {
          eventEmitter.emit<UiDisplayMessageEventHandler>(
            "ui:display-message",
            "error",
            "Please upload a valid image file.",
          );
          return;
        }

        const [imageStoragePath, imageDataUrl] = await Promise.all([
          uploadVideoKeyFrame({
            data: file,
          }),
          getDataUrlFromBlob(file),
        ]);

        if (imageStoragePath && imageDataUrl) {
          replaceKeyFrame(({ id }) => ({
            type: GenerateVideoKeyFrameType.UploadedImage,
            id,
            src: imageDataUrl,
            width: 1024,
            height: 1024,
            asset: {
              type: "image-storage",
              path: imageStoragePath,
            },
          }));
        } else {
          eventEmitter.emit<UiDisplayMessageEventHandler>(
            "ui:display-message",
            "error",
            "Cannot upload image to storage.",
          );

          return;
        }
      } catch (error) {
        console.error("Error uploading key frame to backend: ", error);
      } finally {
        setStatus(SortableItemUploadButtonStatus.Ready);
      }
    },
    [replaceKeyFrame],
  );

  return (
    <SortableItem id={item.id} className={classNames(sortableItemClassName)}>
      <AspectRatio.Root
        ratio={videoGenerationAspectRatioToNumber[generateVideoAspectRatio]}
        className={classNames(
          "flex flex-col items-center justify-center",
          isOverlay ? "pointer-events-none" : "",
        )}
      >
        <div className="h-full py-6 flex flex-col gap-2 items-stretch justify-center">
          <div
            className={classNames(
              "hidden flex-1 w-[133px] lg:flex flex-row items-center justify-center gap-2 text-zinc-700  pointer-events-none mb-2",
              isOverlay ? "opacity-0" : "opacity-100",
            )}
          >
            <ImageUp size={20} />
            <span className="hidden md:block md:text-lg lg:text-xl font-semibold truncate">
              {itemIndexRatio > 0.5 ? "End frame" : "Start frame"}
            </span>
          </div>
          <DropdownMenu.Root open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
            <DropdownMenu.Trigger
              className={classNames(
                status === SortableItemUploadButtonStatus.Ready
                  ? uploadButtonClassName
                  : uploadButtonClassNameLoading,
                imageObjects && imageObjects.length > 0 ? "flex" : "hidden",
              )}
            >
              <Image size={14} />
              <span className="truncate">Select from canvas</span>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
              <DropdownMenu.Content
                className={classNames(
                  DropdownMenuStyles.DropdownMenuContent,
                  DropdownClassName,
                  sortableItemWidthClassName,
                  "rounded-xl shadow-xl max-h-[50vh] text-sm px-2 overflow-x-hidden overflow-y-auto",
                )}
                style={{
                  zIndex: LeftPanelDropdownZIndex,
                }}
              >
                <div className="grid grid-cols-2 gap-2">
                  {imageObjects?.map((object) => (
                    <DropdownMenu.Item
                      key={object.id}
                      className={classNames(
                        SecondaryButtonClassNameInactive,
                        "p-0 overflow-hidden pointer-events-auto",
                      )}
                      onPointerDown={() => {
                        debugLog(`Pointer down on object ${object.id}`);

                        const keyFrame = getGenerateVideoKeyFrameFromImageObject(object);

                        setGenerateVideoKeyFrames((prevKeyFrames) => {
                          return prevKeyFrames.map((frame) =>
                            frame.id === item.id ? keyFrame : frame,
                          );
                        });

                        setIsDropdownOpen(false);
                      }}
                    >
                      <ImageComponent src={object.getSrc()} />
                    </DropdownMenu.Item>
                  ))}
                </div>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
          <label
            htmlFor="upload-keyframe-from-local"
            className={classNames(
              status === SortableItemUploadButtonStatus.Ready
                ? uploadButtonClassName
                : uploadButtonClassNameLoading,
            )}
            onPointerDown={() => {
              if (status !== SortableItemUploadButtonStatus.Ready) {
                return;
              }

              fileInputRef.current?.click();
            }}
          >
            <Upload size={14} />
            <span className="truncate">
              {status === SortableItemUploadButtonStatus.Ready ? "Upload file" : "Uploading"}
            </span>
          </label>
          <input
            ref={fileInputRef}
            type="file"
            multiple
            id="upload-keyframe-from-local"
            style={{
              display: "none",
            }}
            onChange={(e) => {
              const files = e.target.files;

              if (!files) {
                return;
              }
              debugLog(`Upload ${files.length} files`);

              addFiles(files);
            }}
          />
        </div>
      </AspectRatio.Root>
    </SortableItem>
  );
}

function SortableItemVideoEditorKeyFrame({
  item,
  itemIndexRatio,
  isOverlay,
  imageObjects,
  generateVideoAspectRatio,
}: {
  isOverlay: boolean;
  item: GenerateVideoKeyFrame;
  itemIndexRatio: number;
  imageObjects?: fabric.StaticImage[];
  generateVideoAspectRatio: VideoGenerationAspectRatio;
}) {
  if (item.type === "UploadButton") {
    return (
      <SortableItemUploadButton
        item={item}
        itemIndexRatio={itemIndexRatio}
        isOverlay={isOverlay}
        imageObjects={imageObjects}
        generateVideoAspectRatio={generateVideoAspectRatio}
      />
    );
  }

  return (
    <SortableItemGenerateVideoKeyFrame
      item={item}
      itemIndexRatio={itemIndexRatio}
      isOverlay={isOverlay}
      generateVideoAspectRatio={generateVideoAspectRatio}
    />
  );
}

function GenerateVideoKeyFramesSelectorInner({
  imageObjects,
}: {
  imageObjects: fabric.StaticImage[];
}) {
  const generateVideoKeyFrames = editorContextStore((state) => state.generateVideoKeyFrames);
  const generateVideoAspectRatio = editorContextStore((state) => state.generateVideoAspectRatio);
  const setGenerateVideoKeyFrames = editorContextStore((state) => state.setGenerateVideoKeyFrames);

  React.useEffect(() => {
    // if (process.env.NODE_ENV !== 'development') {
    //     return;
    // }
    // setGenerateVideoKeyFrames(getGenerateVideoKeyFramesWithUploadButtons({
    //     keyFrames: [
    //         {
    //             id: generateUUID(),
    //             type: GenerateVideoKeyFrameType.UploadedImage,
    //             width: 1024,
    //             height: 1024,
    //             src: defaultVideoImageUrl,
    //             asset: {
    //                 type: 'image-storage',
    //                 path: defaultVideoImageUrl,
    //             },
    //         }
    //     ]
    // }))
  }, [setGenerateVideoKeyFrames]);

  return (
    <ScrollAreaContainer orientation="horizontal">
      <SortableList
        className="mb-4 flex flex-row items-center gap-2"
        items={generateVideoKeyFrames}
        onChange={setGenerateVideoKeyFrames}
        renderItem={({ item, itemIndex, totalNumItems, isOverlay }) => (
          <SortableItemVideoEditorKeyFrame
            item={item}
            itemIndexRatio={itemIndex / (totalNumItems - 1 || 1)}
            isOverlay={isOverlay}
            imageObjects={imageObjects}
            generateVideoAspectRatio={generateVideoAspectRatio}
          />
        )}
      />
    </ScrollAreaContainer>
  );
}

function useEditorImageObjects({ editor }: { editor: Editor | null }) {
  return React.useMemo(() => {
    return editor?.objects.find(
      (object) => isStaticImageObjectGenerated(object) || isStaticImageObjectUploaded(object),
    ) as any as fabric.StaticImage[];
  }, [editor]);
}

function GenerateVideoKeyFramesSelector() {
  const editor = editorContextStore((state) => state.editor);
  const setGenerateVideoEditorSection = editorContextStore(
    (state) => state.setGenerateVideoEditorSection,
  );

  const imageObjects = useEditorImageObjects({
    editor,
  });

  const generateVideoEditorSection = editorContextStore(
    (state) => state.generateVideoEditorSection,
  );
  const section = GenerateVideoEditorSection.Keyframe;

  return (
    <div
      className="group flex flex-col items-stretch gap-2"
      onPointerOver={() => {
        setGenerateVideoEditorSection(section);
      }}
    >
      <div
        className={classNames(
          "group-hover:text-zinc-300 transition-colors flex flex-row items-center gap-2 truncate",
          generateVideoEditorSection === section ? "text-zinc-300" : "text-zinc-500",
        )}
      >
        1. Upload start and end keyframes.{" "}
        <span className="text-zinc-700 truncate">Upload at least one</span>
      </div>
      <GenerateVideoKeyFramesSelectorInner imageObjects={imageObjects} />
    </div>
  );
}

// Prompt Section Component
function GenerateVideoPromptSection() {
  const generateVideoPrompt = editorContextStore((state) => state.generateVideoPrompt);
  const setGenerateVideoPrompt = editorContextStore((state) => state.setGenerateVideoPrompt);
  const setGenerateVideoEditorSection = editorContextStore(
    (state) => state.setGenerateVideoEditorSection,
  );
  const generateVideoPromptGenerationStatus = editorContextStore(
    (state) => state.generateVideoPromptGenerationStatus,
  );
  const generateVideoKeyFrames = editorContextStore((state) => state.generateVideoKeyFrames);

  const [prompt, setPrompt] = React.useState(generateVideoPrompt);

  const generateVideoEditorSection = editorContextStore(
    (state) => state.generateVideoEditorSection,
  );
  const section = GenerateVideoEditorSection.Prompt;

  React.useEffect(() => {
    setPrompt(generateVideoPrompt);
  }, [generateVideoPrompt]);

  const handleAutoCaption = React.useCallback(async () => {
    const {
      backend,
      generateVideoKeyFrames,
      generateVideoCameraMotion,
      setGenerateVideoPrompt,
      setGenerateVideoCameraMotion,
      generateVideoPromptGenerationStatus,
      setGenerateVideoPromptGenerationStatus,
    } = editorContextStore.getState();

    if (generateVideoPromptGenerationStatus !== GenerateVideoPromptGenerationStatus.Idle) {
      return;
    }

    setGenerateVideoPromptGenerationStatus(GenerateVideoPromptGenerationStatus.Processing);

    if (!backend) {
      return;
    }

    try {
      const result = await backend.generateVideoPrompt({
        keyFrames: generateVideoKeyFrames,
        cameraMotion: generateVideoCameraMotion,
      });

      if (result) {
        setGenerateVideoCameraMotion(generateVideoCameraMotion || result.cameraMotion);

        setGenerateVideoPrompt(result.prompt);
      }
    } catch (error) {
      debugError("Cannot handle auto-caption: ", error);
    } finally {
      setGenerateVideoPromptGenerationStatus(GenerateVideoPromptGenerationStatus.Idle);
    }
  }, []);

  const hasInitCaptionRef = React.useRef(false);

  React.useEffect(() => {
    if (hasInitCaptionRef.current) {
      return;
    }

    const validKeyFrames = generateVideoKeyFrames.filter(
      ({ type }) => type !== GenerateVideoKeyFrameType.UploadButton,
    );

    if (validKeyFrames.length <= 0) {
      return;
    }

    const { generateVideoPrompt } = editorContextStore.getState();

    if (generateVideoPrompt) {
      return;
    }

    hasInitCaptionRef.current = true;

    handleAutoCaption();
  }, [generateVideoKeyFrames, handleAutoCaption]);

  return (
    <div
      className="group flex flex-col items-stretch gap-2"
      onPointerOver={() => setGenerateVideoEditorSection(section)}
    >
      <div className="flex flex-row items-center">
        <div
          className={classNames(
            "flex-1 group-hover:text-zinc-300 transition-colors flex flex-row items-center gap-2 truncate",
            generateVideoEditorSection === section ? "text-zinc-300" : "text-zinc-500",
          )}
        >
          2. Describe the video that you want to create.
        </div>
        <button
          className={classNames(
            generateVideoPromptGenerationStatus === GenerateVideoPromptGenerationStatus.Idle
              ? TextButtonClassName
              : TextButtonClassNameDisabled,
            "text-sm flex flex-row items-center gap-1 ",
          )}
          onClick={() => {
            handleAutoCaption();
          }}
        >
          <RotateCcw
            size={14}
            className={classNames(
              generateVideoPromptGenerationStatus === GenerateVideoPromptGenerationStatus.Idle
                ? ""
                : "animate-spin",
            )}
          />
          <span>Autocaption</span>
        </button>
      </div>
      <textarea
        cols={8}
        className={classNames(InputBoxClassName, "mb-4")}
        value={prompt}
        onChange={(e) => setPrompt(e.currentTarget.value)}
        onBlur={() => setGenerateVideoPrompt(prompt)}
      />
    </div>
  );
}

function CameraControlsVideoComponent({ className = "", ...props }: VideoComponentProps) {
  return (
    <VideoComponent
      {...props}
      className={classNames(
        "filter hover:grayscale-0 transition-all duration-500",
        props.selected ? "grayscale-0" : "grayscale",
        className,
      )}
    />
  );
}

// Camera Motion Selector Component
function GenerateVideoCameraMotionSelector() {
  const generateVideoCameraMotion = editorContextStore((state) => state.generateVideoCameraMotion);
  const setGenerateVideoCameraMotion = editorContextStore(
    (state) => state.setGenerateVideoCameraMotion,
  );
  const generateVideoEditorSection = editorContextStore(
    (state) => state.generateVideoEditorSection,
  );
  const setGenerateVideoEditorSection = editorContextStore(
    (state) => state.setGenerateVideoEditorSection,
  );
  const section = GenerateVideoEditorSection.CameraMotion;

  return (
    <div
      className="group flex flex-col items-stretch gap-2"
      onPointerOver={() => setGenerateVideoEditorSection(section)}
    >
      <div
        className={classNames(
          "group-hover:text-zinc-300 transition-colors flex flex-row items-center gap-2 truncate",
          generateVideoEditorSection === section ? "text-zinc-300" : "text-zinc-500",
        )}
      >
        3. Select camera motion. <span className="text-zinc-700 truncate">Optional</span>
      </div>
      <div
        className="grid items-center gap-2"
        style={{ gridTemplateColumns: "repeat(auto-fit, minmax(128px, 1fr))" }}
      >
        {getObjectEntries(videoCameraMotionConfigs).map(([type, config]) => {
          const selected = type === generateVideoCameraMotion;
          return (
            <div
              key={type}
              className={classNames(
                "group/card w-[132px] flex flex-col items-stretch bg-zinc-800 text-zinc-300 rounded-md border hover:border-lime-500/50 focus:outline-none transition-colors overflow-hidden",
                selected ? "border-lime-500/30" : "border-transparent",
              )}
              onClick={() => setGenerateVideoCameraMotion(type)}
            >
              <AspectRatio.Root
                ratio={1}
                className="relative flex flex-col items-center justify-center opacity-80 group-hover:opacity-100 transition-opacity"
              >
                <CameraControlsVideoComponent
                  muted
                  src={config.videoUrl}
                  thumbnailUrl={config.thumbnailUrl}
                  selected={selected}
                />
              </AspectRatio.Root>
              <div
                className={classNames(
                  "px-2 py-1 text-center text-xs transition-colors",
                  selected ? "text-zinc-300" : "text-zinc-500",
                )}
              >
                {config.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export type GenerateVideoEditorProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

// Main GenerateVideoEditorInner Component
export const GenerateVideoEditorInner = React.forwardRef<HTMLDivElement, GenerateVideoEditorProps>(
  function GenerateVideoEditorInner({ className = "", ...props }, forwardedRef) {
    return (
      <div
        {...props}
        ref={forwardedRef}
        className={classNames("p-2 flex flex-col items-stretch gap-2", className)}
      >
        {/* Keyframes Selector */}
        <GenerateVideoKeyFramesSelector />

        {/* Prompt Section */}
        <GenerateVideoPromptSection />

        {/* Camera Motion Selector */}
        <GenerateVideoCameraMotionSelector />
      </div>
    );
  },
);

export enum GenerateVideoEditorStatus {
  Idle = "Idle",
  Disabled = "Disabled",
  Processing = "Processing",
}

export function useGenerateButtonDisabledEffect({
  setStatus,
  generateVideoPrompt,
  generateVideoKeyFrames,
}: {
  setStatus: (value: StateUpdater<GenerateVideoEditorStatus>) => void;
  generateVideoPrompt: string;
  generateVideoKeyFrames: GenerateVideoKeyFrames;
}) {
  const [generateButtonText, setGenerateButtonText] = React.useState("Generate video");

  React.useEffect(() => {
    if (!generateVideoPrompt.trim()) {
      setGenerateButtonText("Enter a valid prompt");

      setStatus(GenerateVideoEditorStatus.Disabled);

      return;
    }

    if (generateVideoKeyFrames.filter(isGenerateVideoKeyFrameWithImage).length <= 0) {
      setGenerateButtonText("Upload a keyframe");

      setStatus(GenerateVideoEditorStatus.Disabled);

      return;
    }

    setGenerateButtonText("Generate video");

    setStatus((prevStatus) =>
      prevStatus === GenerateVideoEditorStatus.Disabled
        ? GenerateVideoEditorStatus.Idle
        : prevStatus,
    );
  }, [generateVideoPrompt, generateVideoKeyFrames, setStatus, setGenerateButtonText]);

  return {
    generateButtonText,
  };
}

export const GenerateVideoEditor = React.forwardRef(function GenerateVideoEditor(
  {
    className = "",
    onExit,
    ...props
  }: GenerateVideoEditorProps & {
    onExit?: () => void;
  },
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const [status, setStatus] = React.useState(GenerateVideoEditorStatus.Idle);

  const eventEmitter = editorContextStore((state) => state.eventEmitter);

  const userQuotas = getTeamOrUserQuotas();
  const generateVideoPrompt = editorContextStore((state) => state.generateVideoPrompt);
  const generateVideoKeyFrames = editorContextStore((state) => state.generateVideoKeyFrames);

  const { generateButtonText } = useGenerateButtonDisabledEffect({
    setStatus,
    generateVideoPrompt,
    generateVideoKeyFrames,
  });

  const canUserStart = React.useMemo(
    () =>
      canUserStartVideoGeneration({
        userQuotas,
      }),
    [userQuotas],
  );

  return (
    <div
      ref={forwardedRef}
      className={classNames("flex flex-col items-stretch text-zinc-300", className)}
      {...props}
    >
      <div className="p-3 flex flex-row items-center justify-center gap-2 border-b border-zinc-800">
        <div className="text-zinc-300 font-semibold">Generate video from images</div>
        <div className="min-w-0 flex-1" />
        <button
          className="text-zinc-500 hover:text-zinc-300 transition-colors cursor-pointer"
          onClick={() => {
            onExit?.();
          }}
        >
          <Cross1Icon />
        </button>
      </div>
      <div className="overflow-x-hidden overflow-y-auto">
        <GenerateVideoEditorInner />
      </div>
      <div className="p-2 flex flex-row items-center gap-2 border-t border-zinc-800">
        <button
          className={classNames(
            SecondaryButtonClassNameInactive,
            "px-3 py-2 flex flex-row items-center justify-center gap-2",
          )}
          onClick={() => {
            onExit?.();
          }}
        >
          <LogOut size={18} className="hidden md:block" />
          <span className="truncate">Exit to canvas</span>
        </button>
        <button
          className={classNames(
            status === GenerateVideoEditorStatus.Idle
              ? classNames(PrimaryButtonClassName, "cursor-pointer")
              : status === GenerateVideoEditorStatus.Processing
                ? PrimaryButtonClassNameLoading
                : PrimaryButtonClassNameDisabled,
            "flex flex-row items-center justify-center gap-2",
          )}
          onClick={() => {
            if (status !== GenerateVideoEditorStatus.Idle) {
              return;
            }

            if (!canUserStart) {
              onExit?.();

              setTimeout(() => {
                eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
                  "ui:display-message-dialog",
                  "quota-subscribe",
                  {
                    title: "No video generation credits left.",
                    header:
                      "You have used all video generation credits. Please subscribe to generate more videos.",
                  },
                );
              }, 100);

              return;
            }

            setStatus(GenerateVideoEditorStatus.Processing);

            handleStartVideoGenerationOnCanvas({
              storageManager: editorContextStore.getState().storageManager,
              backend: editorContextStore.getState().backend,
              ...editorContextStore.getState(),
            })
              .then(onExit)
              .finally(() => {
                setStatus(GenerateVideoEditorStatus.Idle);
              });
          }}
        >
          <Sparkles size={18} className="hidden md:block" />
          <span className="truncate">{generateButtonText}</span>
        </button>
      </div>
    </div>
  );
});

export function GenerateVideoEditorDebug() {
  return (
    <div className="w-screen h-screen overflow-hidden flex items-center justify-center p-12 bg-zinc-950">
      <div
        className={classNames(
          DropdownClassName,
          "w-full p-0 lg:max-w-[1000px] 2xl:max-w-[1280px] flex flex-col bg-zinc-900 border border-zinc-800 rounded-xl shadow-lg",
        )}
      >
        <GenerateVideoEditor className="max-h-[85vh]" />
      </div>
    </div>
  );
}
