import { PublicUserId } from "./public-user-id";

export enum AppRoleType {
  Owner = "owner",
  Writer = "writer",
  Reader = "reader",
  Commenter = "commenter",
}

export const appRoleTypeToDisplayName: Record<AppRoleType, string> = {
  [AppRoleType.Owner]: "Owner",
  [AppRoleType.Writer]: "Editor",
  [AppRoleType.Reader]: "Reader",
  [AppRoleType.Commenter]: "Commenter",
};

const rolesCanWrite = new Set([AppRoleType.Owner, AppRoleType.Writer]);

const rolesCanRead = new Set([
  AppRoleType.Owner,
  AppRoleType.Writer,
  AppRoleType.Reader,
  AppRoleType.Commenter,
]);

const rolesCanOnlyRead = new Set([AppRoleType.Reader, AppRoleType.Commenter]);

export function isRoleOwner(role?: AppRoleType | null) {
  return role ? role === AppRoleType.Owner : false;
}

export function isRoleReader(role?: AppRoleType | null) {
  return role ? role === AppRoleType.Reader : false;
}

export function isRoleWriter(role?: AppRoleType | null) {
  return role ? role === AppRoleType.Writer : false;
}

export function canRoleWrite(type?: AppRoleType | null) {
  return type ? rolesCanWrite.has(type) : false;
}

export function canRoleRead(type?: AppRoleType | null) {
  return type ? rolesCanRead.has(type) : false;
}

export function getRolesCanWrite() {
  return Array.from(rolesCanWrite);
}

export function getRolesCanRead() {
  return Array.from(rolesCanRead);
}

export function getRolesCanOnlyRead() {
  return Array.from(rolesCanOnlyRead);
}

export const appRoleTypeToRank: Record<AppRoleType, number> = {
  [AppRoleType.Owner]: 5,
  [AppRoleType.Writer]: 4,
  [AppRoleType.Reader]: 3,
  [AppRoleType.Commenter]: 2,
};

export function sortAppRoleType(a: AppRoleType, b: AppRoleType) {
  return appRoleTypeToRank[a] - appRoleTypeToRank[b];
}

export type PublicUserRoles = Record<PublicUserId, AppRoleType>;
