import {
  isPublicUserId,
  isPublicUserMetadata,
  PublicUserId,
  PublicUserMetadata,
} from "@/core/common/types/public-user-id";
import {
  isPublicTeamId,
  isPublicTeamQuotas,
  isTeamMetadata,
  PublicTeamId,
  PublicTeamQuotas,
  TeamMetadata,
  TeamMetadataCollection,
} from "@/core/common/types/team";
import { AppRoleType, getRolesCanRead } from "@/core/common/types/user-roles";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { Auth } from "firebase/auth";
import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  Firestore,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  QuerySnapshot,
  where,
} from "firebase/firestore";
import { Functions, httpsCallable, HttpsCallable } from "firebase/functions";
import { noop } from "lodash";

export interface UpdateUserTeamRoleArgs {
  teamId: string;
  userRoles: Record<string, AppRoleType>;
}

export interface UpdateUserTeamRoleResponse {
  ok: boolean;
  message: string;
}

export interface OnUserTeamsUpdateArgs {
  publicUserId: PublicUserId;
  callback: (teams: TeamMetadataCollection) => void;
}
export interface OnPublicTeamQuotasUpdateArgs {
  teamId: PublicTeamId;
  callback: (publicTeamQuotas: PublicTeamQuotas | undefined) => void;
}

export interface InviteUsersToTeamArgs {
  emails: string[];
  teamId: PublicTeamId;
  role: AppRoleType;
  hostName?: string;
  clientHostUrl: string;
}

export interface InviteUsersToTeamResponse {
  ok: boolean;
  message: string;
  role?: AppRoleType;
}

export interface RemoveUserFromTeamArgs {
  publicTeamId: PublicTeamId;
  publicUserId: PublicUserId;
}

export interface RemoveUserFromTeamResponse {
  status: string;
}

export class TeamsManager {
  private firebaseAuth: Auth;
  private firestore: Firestore;
  private firebaseFunctions: Functions;

  private inviteUsersToTeamCallable: HttpsCallable<
    InviteUsersToTeamArgs,
    InviteUsersToTeamResponse[]
  > | null = null;

  private removeUserFromTeamCallable: HttpsCallable<
    RemoveUserFromTeamArgs,
    RemoveUserFromTeamResponse
  > | null = null;

  private updateUserTeamRoleCallable: HttpsCallable<
    UpdateUserTeamRoleArgs,
    UpdateUserTeamRoleResponse
  > | null = null;

  constructor({
    firebaseAuth,
    firestore,
    firebaseFunctions,
  }: {
    firebaseAuth: Auth;
    firestore: Firestore;
    firebaseFunctions: Functions;
  }) {
    this.firestore = firestore;
    this.firebaseAuth = firebaseAuth;
    this.firebaseFunctions = firebaseFunctions;

    this.inviteUsersToTeamCallable = httpsCallable(
      firebaseFunctions,
      "inviteUsersToTeamColabJuly24_v2",
    );

    this.removeUserFromTeamCallable = httpsCallable(firebaseFunctions, "removeUserFromTeam_v2");
    this.updateUserTeamRoleCallable = httpsCallable(
      firebaseFunctions,
      "updateUserTeamRoleColabJuly24_v2",
    );
  }

  private static teamsMetadataCollectionName = "teamsMetadataV1";
  private static publicUserMetadataCollectionName = "publicUserMetadataV1";
  static publicTeamQuotasCollectionName = "publicTeamQuotas";

  private static getUserTeamsQuery({
    publicUserId,
    firestore,
  }: {
    publicUserId: PublicUserId;
    firestore: Firestore;
  }) {
    return query(
      collection(firestore, TeamsManager.teamsMetadataCollectionName),
      where(`roles.${publicUserId}`, "in", getRolesCanRead()),
    );
  }

  private getTeamMetadataRef({
    firestore,
    publicTeamId,
  }: {
    firestore: Firestore;
    publicTeamId: PublicTeamId;
  }) {
    return doc(collection(firestore, TeamsManager.teamsMetadataCollectionName), publicTeamId);
  }

  private static getUserTeamsFromQuerySnapshot(
    userTeamsSnapshot: QuerySnapshot<DocumentData>,
  ): TeamMetadataCollection {
    if (userTeamsSnapshot.empty) {
      return {};
    }

    const userTeams: TeamMetadata[] = userTeamsSnapshot.docs
      .map((doc) => doc.data())
      .filter(isTeamMetadata);

    const userTeamsCollection: TeamMetadataCollection = {};

    userTeams.forEach((team) => {
      userTeamsCollection[team.id] = team;
    });

    return userTeamsCollection;
  }

  async getUserTeams(publicUserId: PublicUserId): Promise<TeamMetadataCollection> {
    try {
      if (!isPublicUserId(publicUserId)) {
        debugError("No valid public user id found.");
        return {};
      }

      const userTeamsSnapshot = await getDocs(
        TeamsManager.getUserTeamsQuery({
          firestore: this.firestore,
          publicUserId,
        }),
      );

      return TeamsManager.getUserTeamsFromQuerySnapshot(userTeamsSnapshot);
    } catch (error) {
      console.error(error);
    }

    return {};
  }

  async getTeamMetadata(teamId: PublicTeamId): Promise<TeamMetadata | undefined> {
    try {
      if (!isPublicTeamId(teamId)) {
        debugError(`The provided team id ${teamId} is invalid.`);
        return undefined;
      }

      const teamMetadataSnapshot = await getDoc(
        this.getTeamMetadataRef({
          firestore: this.firestore,
          publicTeamId: teamId,
        }),
      );

      if (!teamMetadataSnapshot.exists()) {
        debugError(`Cannot find team ${teamId} metadata.`);
        return undefined;
      }

      const teamMetadata = teamMetadataSnapshot.data();

      if (isTeamMetadata(teamMetadata)) {
        return teamMetadata;
      }

      debugError(`Team ${teamId} metadata is invalid:`);
      debugError(teamMetadata);
    } catch (error) {
      console.error(error);
    }

    return undefined;
  }

  onUserTeamsUpdate({ publicUserId, callback }: OnUserTeamsUpdateArgs) {
    try {
      if (!publicUserId) {
        debugError("No valid public user id found.");
        return noop;
      }

      const userTeamsRef = TeamsManager.getUserTeamsQuery({
        firestore: this.firestore,
        publicUserId,
      });

      return onSnapshot(userTeamsRef, (userTeamsSnapshot: QuerySnapshot<DocumentData>) => {
        try {
          return callback(TeamsManager.getUserTeamsFromQuerySnapshot(userTeamsSnapshot));
        } catch (error) {
          console.error(error);
        }
      });
    } catch (error) {
      console.error(error);
    }

    return noop;
  }

  static getPublicTeamQuotasRef({
    firestore,
    teamId,
  }: {
    firestore: Firestore;
    teamId: PublicTeamId;
  }) {
    return doc(collection(firestore, TeamsManager.publicTeamQuotasCollectionName), teamId);
  }

  static getPublicTeamQuotasFromQuerySnapshot(snapshot: DocumentSnapshot<DocumentData>) {
    if (!snapshot.exists()) {
      debugError("Team quotas snapshot does not exist");
      return undefined;
    }

    const data = snapshot.data();

    if (!isPublicTeamQuotas(data)) {
      debugError("Invalid team quotas: ", data);
      return undefined;
    }

    return data;
  }

  onPublicTeamQuotasUpdate({ teamId, callback }: OnPublicTeamQuotasUpdateArgs) {
    try {
      const { firestore } = this;

      if (!teamId) {
        debugError("The provided team id is invalid.");
        return noop;
      }

      const publicTeamQuotasRef = TeamsManager.getPublicTeamQuotasRef({
        firestore,
        teamId,
      });

      debugLog(`Subscribe to public team quotas ${publicTeamQuotasRef.path} update.`);

      return onSnapshot(publicTeamQuotasRef, (snapshot) => {
        try {
          const publicTeamQuotas = TeamsManager.getPublicTeamQuotasFromQuerySnapshot(snapshot);

          debugLog("Public team quotas:\n", publicTeamQuotas);

          callback(publicTeamQuotas);
        } catch (error) {
          console.error(error);
        }
      });
    } catch (error) {
      console.error(error);
      return noop;
    }
  }

  async inviteUsersToTeam(args: InviteUsersToTeamArgs): Promise<InviteUsersToTeamResponse[]> {
    try {
      const { firebaseAuth } = this;
      const user = firebaseAuth.currentUser;

      if (!user) {
        debugError("User is not logged in, cannot invite others to the team.");
        return [
          {
            ok: false,
            message: "User is not logged in.",
          },
        ];
      }

      const displayName = user.displayName ?? undefined;
      const response = await this.inviteUsersToTeamCallable({
        ...args,
        hostName: displayName,
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return [
        {
          ok: false,
          message: "Unknown error",
        },
      ];
    }
  }

  async removeUserFromTeam(args: RemoveUserFromTeamArgs): Promise<RemoveUserFromTeamResponse> {
    try {
      const { firebaseAuth } = this;

      const user = firebaseAuth.currentUser;

      if (!user) {
        return {
          status: "error",
        };
      }

      const response = await this.removeUserFromTeamCallable(args);

      return {
        status: response.data.status,
      };
    } catch (error) {
      return {
        status: "error",
      };
    }
  }

  private getPublicUserRef({
    publicUserId,
    firestore,
  }: {
    publicUserId: PublicUserId;
    firestore: Firestore;
  }) {
    return doc(collection(firestore, TeamsManager.publicUserMetadataCollectionName), publicUserId);
  }

  private publicUserMetadataCache: Record<PublicUserId, PublicUserMetadata> = {};

  private async getPublicUserMetadataInternal(publicUserId: PublicUserId) {
    try {
      if (!publicUserId || !isPublicUserId(publicUserId)) {
        return undefined;
      }

      const publicUserMetadataSnapshot = await getDoc(
        this.getPublicUserRef({
          firestore: this.firestore,
          publicUserId,
        }),
      );

      const publicUserMetadata = publicUserMetadataSnapshot.data();

      if (isPublicUserMetadata(publicUserMetadata)) {
        return publicUserMetadata;
      }
    } catch (error) {
      console.error(error);
    }
    return undefined;
  }

  async getPublicUserMetadata(publicUserId: PublicUserId) {
    try {
      let publicUserMetadata: PublicUserMetadata | undefined =
        this.publicUserMetadataCache[publicUserId];

      if (publicUserMetadata) {
        return publicUserMetadata;
      }

      publicUserMetadata = await this.getPublicUserMetadataInternal(publicUserId);

      if (publicUserMetadata) {
        this.publicUserMetadataCache[publicUserId] = publicUserMetadata;
      }

      return publicUserMetadata;
    } catch (error) {
      console.error(error);
    }
    return undefined;
  }

  async updateUserTeamRole(args: UpdateUserTeamRoleArgs) {
    try {
      const response = await this.updateUserTeamRoleCallable(args);

      return response.data;
    } catch (error) {
      console.error(error);
    }

    return {
      ok: false,
      message: "Unknown error.",
    };
  }
}
