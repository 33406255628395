import { OutpaintContextState, OutpaintStatus } from "@/core/common/types/outpaint";
import React from "react";

const noop = () => {};

// Create the context with default initial values
const OutpaintContext = React.createContext<OutpaintContextState>({
  status: OutpaintStatus.Idle,
  setStatus: noop,
  outpaintWidth: 0,
  setOutpaintWidth: noop,
  outpaintHeight: 0,
  setOutpaintHeight: noop,
  outputImageUrls: [],
  setOutputImageUrls: noop,
});
// Provider component
export const OutpaintContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children = null }) => {
  const [status, setStatus] = React.useState<OutpaintStatus>(OutpaintStatus.Idle);
  const [outpaintWidth, setOutpaintWidth] = React.useState<number>(0);
  const [outpaintHeight, setOutpaintHeight] = React.useState<number>(0);
  const [outputImageUrls, setOutputImageUrls] = React.useState<string[]>([]);

  const value = {
    status,
    setStatus,
    outpaintWidth,
    setOutpaintWidth,
    outpaintHeight,
    setOutpaintHeight,
    outputImageUrls,
    setOutputImageUrls,
  };

  React.useEffect(() => {
    return () => {
      setOutputImageUrls([]);
      setStatus(OutpaintStatus.Idle);
    };
  }, []);

  return <OutpaintContext.Provider value={value}>{children}</OutpaintContext.Provider>;
};

// Custom hook to use the context
export const useOutpaintContext = (): OutpaintContextState => {
  return React.useContext(OutpaintContext);
};
