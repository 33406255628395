import { ASSETS, CUSTOM_MODELS, PROJECTS, TEMPLATES, VIDEOS } from "@/components/constants/routes";
import { getApiDashboardModelUrl } from "@/core/common/types/api";
import { editorContextStore } from "contexts/editor-context";
import React from "react";
import { useNavigate } from "react-router-dom";

export function useDashboardModelNavigateEffect() {
  const dashboardType = editorContextStore((state) => state.dashboardType);
  const apiDashboardModelType = editorContextStore((state) => state.apiDashboardModelType);
  const navigate = useNavigate();

  React.useEffect(() => {
    let targetUrl: string = window.location.pathname;
    switch (dashboardType) {
      case "manage-api":
        targetUrl = getApiDashboardModelUrl(apiDashboardModelType);
        break;
      case "projects":
        targetUrl = `/${PROJECTS}`;
        break;
      case "models":
        targetUrl = `/${CUSTOM_MODELS}`;
        break;
      case "videos":
        targetUrl = `/${VIDEOS}`;
        break;
      case "templates":
        targetUrl = `/${TEMPLATES}`;
        break;
      case "assets":
        targetUrl = `/${ASSETS}`;
        break;
      default:
        // Optionally handle unknown dashboard types
        console.warn(`Unknown dashboard type: ${dashboardType}`);
        break;
    }

    navigate(targetUrl);
  }, [dashboardType, apiDashboardModelType, navigate]);
}
