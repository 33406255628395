import * as Toast from "@radix-ui/react-toast";
import React from "react";
// import * as Toast from '@radix-ui/react-toast';
import { DisplayMessageType, ToastType } from "@/core/common/types";
import { classNames } from "@/core/utils/classname-utils";
import {
  DropdownClassName,
  PrimaryButtonClassName,
  PrimaryButtonClassNameError,
  SecondaryButtonClassName,
} from "components/constants/class-names";
import { ToastZIndex } from "../constants/zIndex";

function getTitleFromMessageType(type: DisplayMessageType) {
  if (type === "error") {
    return "Error";
  }
  if (type === "info") {
    return "Note";
  }
  return "Action";
}

function getActionMessageFromMessageType(type: ToastType) {
  if (type === "error") {
    return "Got it";
  }
  if (type === "info") {
    return "Got it";
  }
  return "Yes";
}

type CustomMessageToastProps = {
  type: ToastType;
  title?: string;
  message?: string;
  actionMessage?: string;
  onClick?: () => void | Promise<void>;
};

function ActionButton({
  onClick,
  actionMessage,
}: {
  actionMessage?: string;
  onClick?: () => void;
}) {
  return (
    <Toast.Action asChild altText={actionMessage || ""}>
      <button onClick={onClick} className={PrimaryButtonClassName}>
        {actionMessage}
      </button>
    </Toast.Action>
  );
}

function DoneButton() {
  return (
    <Toast.Action asChild altText="Ok">
      <button className={SecondaryButtonClassName}>Ok</button>
    </Toast.Action>
  );
}

function ErrorButton() {
  return (
    <Toast.Action asChild altText="Error">
      <button className={PrimaryButtonClassNameError}>Error</button>
    </Toast.Action>
  );
}

function MessageToastContainer({
  type,
  title,
  message = "",
  children,
}: CustomMessageToastProps & {
  children?: React.ReactNode;
}) {
  title = title || getTitleFromMessageType(type);
  return (
    <Toast.Root
      className={classNames(
        DropdownClassName,
        "flex flex-row min-w-[200px] max-w-[50vw] bg-black shadow-lg-center data-[state=open]:animate-enter data-[state=closed]:animate-leave",
      )}
    >
      <Toast.Description asChild>
        <div className="mr-4 flex-1 flex flex-col items-start">
          <p className="text-sm font-medium text-zinc-100">{title ?? "Note"}</p>
          <p className="mt-1 text-sm text-zinc-300">{message}</p>
        </div>
      </Toast.Description>
      <div className="flex items-center justify-center">{children}</div>
    </Toast.Root>
  );
}

type ToastContextType = {
  showToast: (props: CustomMessageToastProps) => void;
};

export const ToastContext = React.createContext<ToastContextType | null>(null);

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toastState, setToastState] = React.useState<{
    open: boolean;
    current: {
      props: CustomMessageToastProps;
    } | null;
  }>({
    open: false,
    current: null,
  });

  const showToast = React.useCallback((props: CustomMessageToastProps) => {
    setToastState({
      open: true,
      current: {
        props: { ...props },
      },
    });
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toast.Provider swipeDirection="right" duration={5000}>
        <Toast.Root
          open={toastState.open && toastState.current !== null}
          onOpenChange={(open) => setToastState((prev) => ({ ...prev, open }))}
        >
          {toastState.current && (
            <MessageToastContainer {...toastState.current.props}>
              {toastState.current.props.type === "action" && (
                <ActionButton
                  onClick={toastState.current.props.onClick}
                  actionMessage={
                    toastState.current.props.actionMessage ||
                    getActionMessageFromMessageType(toastState.current.props.type)
                  }
                />
              )}
              {toastState.current.props.type === "error" && <ErrorButton />}
              {toastState.current.props.type === "info" && <DoneButton />}
            </MessageToastContainer>
          )}
        </Toast.Root>
        <Toast.Viewport
          className="fixed p-6 flex flex-col gap-2 w-full sm:w-auto bottom-0 left-1/2 -translate-x-1/2"
          style={{
            zIndex: ToastZIndex,
          }}
        />
      </Toast.Provider>
    </ToastContext.Provider>
  );
}

export function useToast() {
  const context = React.useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
}
