import { TextButtonClassName } from "@/components-mobile/constants/class-names";
import {
  CustomModelType,
  DashboardType,
  getFrontendDisplayTemplateTypeFromCustomModelType,
} from "@/core/common/types";
import { getHighestUserStripeSubscriptionPlan } from "@/core/common/types/user-stripe-subscriptions";
import { classNames } from "@/core/utils/classname-utils";
import {
  doesUserHaveTeamFullPermission,
  doesUserHaveTeamWritePermission,
} from "@/core/utils/user-role-utils";
import { trackTemplateAction } from "components/panels/panel-items/components/TemplateCarousel";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { editorContextStore } from "contexts/editor-context";
import { Code2, File, HelpCircle, MousePointerClick, Rocket, Sparkles } from "lucide-react";
import React from "react";
import { ImageToVideoIcon } from "../video/image-to-video-icon";
import { DashboardLeftPanelTeamsDropdown } from "./left-panels/teams-dropdown";

interface DashboardItem {
  name: string;
  icon: React.ReactNode;
  onClick?: () => void;
}

interface DashboardSection {
  name: string;
  items: Partial<Record<DashboardType, DashboardItem>>;
}

const dashboardLeftPanelReadTabs = ["assets"];

const dashboardLeftPanelEditTabs = ["models"];

const dashboardLeftPanelOwnerTabs = ["templates", "projects", "models", "videos", "manage-api"];

const dashboardLeftPanelTabs: Record<string, DashboardSection> = {
  Tools: {
    name: "Tools",
    items: {
      templates: {
        name: "Templates",
        icon: <Rocket size={18} />,
        onClick: () => {
          trackTemplateAction("SidebarNavClick");
        },
      },
      projects: {
        name: "Drag & Drop Projects",
        icon: <MousePointerClick size={18} />,
      },
      models: {
        name: "Custom Models",
        icon: <Sparkles size={18} />,
      },
      videos: {
        name: "Generative Video",
        icon: <ImageToVideoIcon size={18} />,
      },
    },
  },
  Assets: {
    name: "Assets",
    items: {
      assets: {
        name: "All Assets",
        icon: <File size={18} />,
      },
    },
  },
  More: {
    name: "More",
    items: {
      "manage-api": {
        name: "API",
        icon: <Code2 size={18} />,
      },
    },
  },
};

const getFilteredDashboardTabs = (userIsOwner: boolean, userCanEdit: boolean) => {
  const allowedTabs = userIsOwner
    ? dashboardLeftPanelOwnerTabs
    : userCanEdit
      ? dashboardLeftPanelEditTabs
      : dashboardLeftPanelReadTabs;

  return Object.entries(dashboardLeftPanelTabs).reduce(
    (acc, [sectionKey, section]) => {
      const filteredItems = Object.entries(section.items).reduce(
        (itemsAcc, [itemKey, item]) => {
          if (allowedTabs.includes(itemKey as any)) {
            itemsAcc[itemKey] = item;
          }
          return itemsAcc;
        },
        {} as typeof section.items,
      );

      if (Object.keys(filteredItems).length > 0) {
        acc[sectionKey] = {
          ...section,
          items: filteredItems,
        };
      }
      return acc;
    },
    {} as typeof dashboardLeftPanelTabs,
  );
};

export default function DashboardLeftPanel() {
  const dashboardType = editorContextStore((state) => state.dashboardType);
  const setDashboardType = editorContextStore((state) => state.setDashboardType);
  const { setCustomModelWorkflow } = editorContextStore.getState();
  const userStripeSubscriptions = editorContextStore((state) => state.userStripeSubscriptions);
  const highestSubcriptionPlan = getHighestUserStripeSubscriptionPlan({
    userStripeSubscriptions,
  });

  const userCanEdit = editorContextStore(doesUserHaveTeamWritePermission);
  const userIsOwner = editorContextStore(doesUserHaveTeamFullPermission);
  const filteredTabs = getFilteredDashboardTabs(userIsOwner, userCanEdit);

  return (
    <ScrollAreaContainer className="lg:w-[268px] hidden md:block md:flex-none border-r border-zinc-800">
      <div className="flex flex-col gap-2">
        <DashboardLeftPanelTeamsDropdown />
        <div className="flex flex-col gap-3 px-4 py-2">
          {Object.entries(filteredTabs).map(([sectionKey, section]) => (
            <div key={sectionKey}>
              <div className="text-zinc-500 font-bold text-sm mb-2 px-4">{section.name}</div>
              {Object.entries(section.items).map(([itemKey, item]) => (
                <div
                  key={itemKey}
                  className={classNames(
                    "w-full flex flex-row items-center justify-start select-none cursor-pointer px-4 py-2 mb-2 rounded-md transition-colors border active:border-zinc-700 active:text-white",
                    dashboardType === itemKey
                      ? "text-zinc-100 bg-zinc-800 shadow-lg border-zinc-800 hover:border-zinc-700/50"
                      : "text-zinc-300 hover:text-zinc-200 hover:bg-zinc-800/80 border-zinc-900",
                  )}
                  onClick={() => {
                    if (itemKey === "models") {
                      setCustomModelWorkflow(
                        getFrontendDisplayTemplateTypeFromCustomModelType(CustomModelType.Custom),
                      );
                    }
                    setDashboardType(itemKey as DashboardType);
                    item.onClick?.();
                  }}
                >
                  <span className="mr-3">{item.icon}</span>
                  <span>{item.name}</span>
                </div>
              ))}
              {sectionKey !== Object.keys(filteredTabs).pop() && (
                <div className="h-px bg-zinc-800 my-2" />
              )}
            </div>
          ))}
        </div>
        <div
          className={classNames(
            TextButtonClassName,
            "absolute flex flex-row flex-grow box-border items-center bottom-3 left-3 px-4 py-2  justify-start select-none cursor-pointer text-zinc-500 ",
          )}
        >
          <span className="mr-3">
            <HelpCircle size={18} />
          </span>
          <a
            href={`mailto:support@flair.ai?subject=${highestSubcriptionPlan}%20User%20-%20Customer%20Support`}
          >
            Need Help?
          </a>
        </div>
      </div>
    </ScrollAreaContainer>
  );
}
