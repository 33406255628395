import { Backend } from "@/backend/base";
import { PublicUserId } from "@/core/common/types";
import { classNames } from "@/core/utils/classname-utils";
import React from "react";

export const UserProfileIcon = React.forwardRef(function UserProfileIcon(
  {
    name: inputName = "Anonymous",
    backend,
    publicUserId,
    className = "",
    style = {},
    color,
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    name?: string;
    backend?: Backend;
    color?: string;
    publicUserId?: PublicUserId | null;
  },
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const [name, setName] = React.useState<string>(inputName);
  const [userColor, setUserColor] = React.useState<string | null>(color ?? null);

  React.useEffect(() => {
    if (!backend || !publicUserId) {
      return;
    }

    if (color && color.length > 5) {
      return;
    }

    backend.getPublicUserMetadata(publicUserId).then((userMetadata) => {
      setName((prevName) => (prevName?.length > 0 ? prevName : (userMetadata?.name ?? "")));
    });
  }, [color, backend, publicUserId]);

  return (
    <div
      {...props}
      ref={forwardedRef}
      className={classNames(
        "w-7 h-7 flex justify-center items-center rounded-full text-sm bg-lime-500 text-zinc-900 font-semibold select-none shadow-md focus-visible:outline-none",
        className,
      )}
      style={
        userColor
          ? {
              ...style,
              backgroundColor: userColor,
            }
          : style
      }
    >
      {name.charAt(0).toUpperCase()}
    </div>
  );
});
