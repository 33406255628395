import { debugError } from "@/core/utils/print-utilts";
import { getUpdaterFunction, SetEditorStateFunction } from "contexts/editor-context-utils";
import { noop } from "lodash";
import { StripeSubscriptionFirestoreDoc, StripeSubscriptionItem } from "./stripe";
import {
  AppSubscriptionPlanType,
  StripeSubscriptionProductId,
  subscriptionPlanRank,
  subscriptionProductToPlan,
} from "./subscription";
import { StateUpdater } from "./utils";

export interface UserStripeSubscriptionsEditorState {
  userStripeSubscriptions: StripeSubscriptionFirestoreDoc[];
  setUserStripeSubscriptions: (value: StateUpdater<StripeSubscriptionFirestoreDoc[]>) => void;
}

export function getDummyUserStripeSubscriptionsEditorState(): UserStripeSubscriptionsEditorState {
  return {
    userStripeSubscriptions: [],
    setUserStripeSubscriptions: noop,
  };
}

export function getDefaultUserStripeSubscriptionsEditorState(
  set: SetEditorStateFunction,
): UserStripeSubscriptionsEditorState {
  return {
    userStripeSubscriptions: [],
    setUserStripeSubscriptions: getUpdaterFunction(set, "userStripeSubscriptions"),
  };
}

export function resetUserStripeSubscriptionsEditorState(state: UserStripeSubscriptionsEditorState) {
  state.setUserStripeSubscriptions([]);
}

export function getHighestUserStripeSubscriptionItem({
  userStripeSubscriptions = [],
}: {
  userStripeSubscriptions: StripeSubscriptionFirestoreDoc[];
}): StripeSubscriptionItem | undefined {
  try {
    // Flatten the subscription items
    const subscriptionItems = userStripeSubscriptions.flatMap((subscription) => subscription.items);

    // Filter items that have a plan product in subscriptionProductToPlan
    const validItems = subscriptionItems.filter(
      (item) => item.plan.product in subscriptionProductToPlan,
    );

    // Sort the items based on subscriptionPlanRank
    const sortedItems = validItems.sort(
      (itemA, itemB) =>
        subscriptionPlanRank[
          subscriptionProductToPlan[itemB.plan.product as StripeSubscriptionProductId] ??
            AppSubscriptionPlanType.Free
        ] -
        subscriptionPlanRank[
          subscriptionProductToPlan[itemA.plan.product as StripeSubscriptionProductId] ??
            AppSubscriptionPlanType.Free
        ],
    );

    return sortedItems[0];
  } catch (error) {
    debugError("Error getting highest user stripe subscription item: ", error);
    return undefined;
  }
}

export function getHighestUserStripeSubscriptionProduct({
  userStripeSubscriptions = [],
}: {
  userStripeSubscriptions: StripeSubscriptionFirestoreDoc[];
}): StripeSubscriptionProductId | undefined {
  try {
    const highestItem = getHighestUserStripeSubscriptionItem({
      userStripeSubscriptions,
    });
    return highestItem?.plan.product as StripeSubscriptionProductId;
  } catch (error) {
    debugError("Error getting highest user stripe subscription product: ", error);
    return undefined;
  }
}

export function getHighestUserStripeSubscriptionPlan({
  userStripeSubscriptions = [],
}: {
  userStripeSubscriptions: StripeSubscriptionFirestoreDoc[];
}): AppSubscriptionPlanType {
  try {
    const highestItem = getHighestUserStripeSubscriptionItem({
      userStripeSubscriptions,
    });
    const product = highestItem?.plan.product as StripeSubscriptionProductId;
    const plan = subscriptionProductToPlan[product] ?? AppSubscriptionPlanType.Free;

    return plan;
  } catch (error) {
    debugError("Error getting user stripe subscriptions: ", error);
    return AppSubscriptionPlanType.Free;
  }
}
