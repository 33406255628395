// Firebase configuration and initialization
import { FirebaseOptions } from "firebase/app";

export const FLAIR_AI_FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN ?? "flair-ai-dev.firebaseapp.com",
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID ?? "flair-ai-dev",
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET ?? "flair-ai-dev.appspot.com",
  messagingSenderId: import.meta.env.VITE_FIREBASE_PROJECT_NUMBER,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

export const API_ENDPOINTS = {
  REMOVE_BACKGROUND: import.meta.env.VITE_REMOVE_BACKGROUND_API_URL,
  UPSCALE: import.meta.env.VITE_UPSCALE_API_URL,
  PARSE_CLOTH: import.meta.env.VITE_TRYON_PARSE_CLOTH_API_URL,
  WARP_CLOTH: import.meta.env.VITE_TRYON_WARP_CLOTH_API_URL,
  RENDER_CLOTH: import.meta.env.VITE_TRYON_RENDER_CLOTH_API_URL,
  CAPTION: import.meta.env.VITE_CAPTION_API_URL,
};
