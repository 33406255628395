import { getEncryptedIdFromPublicId, isPublicId, PublicId } from "./public-id";
import type { AppRoleType } from "./user-roles";

export const publicUserIdSuffix = "_puid";

export type PublicUserId = PublicId<typeof publicUserIdSuffix>;

export type PublicUserRoles = Record<PublicUserId, AppRoleType>;

export interface PublicUserMetadata {
  id: PublicUserId;
  name: string;
  profilePicturePath?: string;
}

export function isPublicUserMetadata(metadata: any): metadata is PublicUserMetadata {
  return metadata && isPublicUserId(metadata.id);
}

export function getEncryptedUserIdFromPublicUserId(publicUserId: PublicUserId) {
  return getEncryptedIdFromPublicId(publicUserId, publicUserIdSuffix);
}

export function isPublicUserId(id: string | undefined | null): id is PublicUserId {
  return isPublicId(id, publicUserIdSuffix);
}
