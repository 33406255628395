import { CURRENT_TEAM_ID_KEY } from "@/components/constants/backend";
import { AppRoleType, UserAuthChangedEventHandler } from "@/core/common/types";
import { PublicTeamId } from "@/core/common/types/team";
import { editorContextStore } from "contexts/editor-context";
import React from "react";

export function useAuthUpdateEffect() {
  const backend = editorContextStore((state) => state.backend);
  const user = editorContextStore((state) => state.user);
  const setCurrentTeamId = editorContextStore((state) => state.setCurrentTeamId);
  const userTeams = editorContextStore((state) => state.userTeams);
  const setUserTeams = editorContextStore((state) => state.setUserTeams);
  const setUser = editorContextStore((state) => state.setUser);
  const setUserQuotas = editorContextStore((state) => state.setUserQuotas);
  const setUserOnboardData = editorContextStore((state) => state.setUserOnboardData);
  const setTeamQuotas = editorContextStore((state) => state.setTeamQuotas);
  const currentTeamId = editorContextStore((state) => state.currentTeamId);
  React.useEffect(() => {
    const handleFocus = async () => {
      await user?.getIdTokenResult();
    };

    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    return backend?.onAuthStateChanged(async (user) => {
      const { setPublicUserId } = editorContextStore.getState();

      setUser(user);
      if (!user) {
        setUserQuotas(null);
        setTeamQuotas(null);
        setUserOnboardData(null);
        setPublicUserId(null);
        setUserTeams({});
        setCurrentTeamId(null);
        localStorage.removeItem(CURRENT_TEAM_ID_KEY);
        editorContextStore.getState().analytics.identify();
      } else {
        // Get token directly from the user object
        const token = await user.getIdTokenResult();

        const userTeams = token?.claims?.teams || {};
        setUserTeams(userTeams);

        const initialTeamId = Object.entries(userTeams).find(
          ([_, role]) => role === AppRoleType.Owner,
        )?.[0] as PublicTeamId | undefined;

        setCurrentTeamId(initialTeamId || null);

        backend.getPublicUserId().then(async (publicUserId) => {
          setPublicUserId(publicUserId || null);
        });

        editorContextStore.getState().analytics.identify(user.uid);

        backend.onUserLogin({
          uid: user.uid,
        });
      }
      editorContextStore
        .getState()
        .editor?.emit<UserAuthChangedEventHandler>("user:auth-changed", user);
    });
  }, [
    backend,
    setUser,
    setUserQuotas,
    setUserOnboardData,
    setUserTeams,
    setCurrentTeamId,
    setTeamQuotas,
  ]);

  React.useEffect(() => {
    return backend?.onUserQuotasUpdate({
      userId: user?.uid,
      onUpdate: setUserQuotas,
    });
  }, [backend, user, setUserQuotas]);

  React.useEffect(() => {
    if (user) {
      backend?.getUserOnboardData(user?.uid).then((userOnboardData) => {
        setUserOnboardData(userOnboardData);
      });
    }
  }, [backend, user, setUserOnboardData]);

  React.useEffect(() => {
    if (userTeams && !Object.keys(userTeams).includes(currentTeamId)) {
      localStorage.removeItem(CURRENT_TEAM_ID_KEY);
    }
  }, [userTeams, currentTeamId]);
}
