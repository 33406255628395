import { editorContextStore } from "@/contexts/editor-context";
import { TeamMetadataCollection } from "@/core/common/types";
import { useEffect, useState } from "react";

export function CollabDebug() {
  // Get all Vite env variables
  const viteEnvVars = Object.entries(import.meta.env).sort();

  // Specific CF Pages variables we want to display
  const cfVars = ["CF_PAGES_COMMIT_SHA", "CF_PAGES_URL", "CF_PAGES_BRANCH"];

  const [tokenClaims, setTokenClaims] = useState<any>(null);
  const backend = editorContextStore.getState().backend;
  const puid = editorContextStore.getState().publicUserId;
  const [teams, setTeams] = useState<TeamMetadataCollection>({});
  useEffect(() => {
    const fetchTokenClaims = async () => {
      const token = await editorContextStore.getState().user.getIdToken();
      // Parse the JWT token to get claims
      const claims = JSON.parse(atob(token.split(".")[1]));
      setTokenClaims(claims);
    };
    const teams = async () => {
      const teams = await backend.getUserTeams(puid);
      setTeams(teams);
    };
    fetchTokenClaims().catch(console.error);
    teams().catch(console.error);
  }, [backend, puid]);

  return (
    <div
      style={{
        padding: "1rem",
        backgroundColor: "#f5f5f5",
        borderRadius: "4px",
        fontSize: "0.875rem",
        maxWidth: "900px",
        maxHeight: "90vh",
        overflowY: "auto",
      }}
    >
      <h3 style={{ margin: "0 0 0.5rem" }}>Debug Info</h3>

      <div style={{ marginBottom: "1rem" }}>
        <h4 style={{ margin: "0 0 0.5rem" }}>Token Claims:</h4>
        <pre style={{ margin: 0 }}>
          {tokenClaims ? JSON.stringify(tokenClaims, null, 2) : "Loading..."}
        </pre>
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <h4 style={{ margin: "0 0 0.5rem" }}>Teams:</h4>
        <pre style={{ margin: 0 }}>{JSON.stringify(teams, null, 2)}</pre>
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <h4 style={{ margin: "0 0 0.5rem" }}>Cloudflare Pages Variables:</h4>
        <ul style={{ margin: 0, paddingLeft: "1.5rem" }}>
          {cfVars.map((varName) => (
            <li key={varName}>
              <strong>{varName}:</strong> {import.meta.env[varName] || "<not set>"}
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h4 style={{ margin: "0 0 0.5rem" }}>All Vite Environment Variables:</h4>
        <ul style={{ margin: 0, paddingLeft: "1.5rem" }}>
          {viteEnvVars.map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> {typeof value === "string" ? value : JSON.stringify(value)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
