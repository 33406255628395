import { Timestamp } from "firebase/firestore";
import { UserAssetType } from "./user-asset-type";
import { PublicUserRoles } from "./user-roles";
import { DocVisibility } from "./doc-visibility";
import { DocsBatchGenerator } from "../types";

/**
 * In order to keep logic consistent, below types mimic old userAssetInfo types
 */
export type AssetMetadataGenerator = DocsBatchGenerator<AssetMetadata>;
export type AssetMetadataGeneratorRef = { current?: AssetMetadataGenerator };
export type AssetMetadataCollection = Record<string, AssetMetadata>;

export enum UserAssetDocUploadStatus {
  Uploading = "Uploading",
  Ready = "Ready",
}

export interface AssetMetadata {
  id: string;
  uploadStatus: UserAssetDocUploadStatus;
  assetType: UserAssetType;
  storagePath: string;
  thumbnail128StoragePath?: string;
  thumbnail256StoragePath?: string;
  thumbnail512StoragePath?: string;
  clipEmbedding768StoragePath?: string;
  sizeBytes?: number;
  bucket?: string;
  contentType?: string;
  timeModified?: Timestamp;
  caption?: string;
  isDeleted: boolean;
  roles: PublicUserRoles;
  visibility: DocVisibility;
}

export function isAssetMetadata(doc: any): doc is AssetMetadata {
  return (
    typeof doc?.id === "string" &&
    (doc as AssetMetadata)?.assetType != null &&
    (doc as AssetMetadata)?.roles != null
  );
}
