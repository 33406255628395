import { editorContextStore } from "@/contexts/editor-context";
import { AppRoleType } from "@/core/common/types/user-roles";
import { getDefaultNavigateForRole, getTeamUserRole } from "@/core/utils/user-role-utils";
import { useEffect, useRef, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

export function ProtectedRoute({ roles }: { roles: string[] }) {
  const userRole = editorContextStore(getTeamUserRole);
  const [currentRole, setCurrentRole] = useState<AppRoleType | undefined>(userRole);
  const isUpdating = useRef(false);

  useEffect(() => {
    if (userRole !== currentRole) {
      isUpdating.current = true;
      setCurrentRole(userRole);
    } else {
      isUpdating.current = false;
    }
  }, [userRole, currentRole]);

  if (roles.includes(currentRole)) {
    return <Outlet />;
  }

  const defaultNavigate = getDefaultNavigateForRole(currentRole);
  return <Navigate to={defaultNavigate} replace />;
}
