import { CheckoutSessionType } from "@/core/common/checkout-session";
import { AppUserQuotas } from "@/core/common/types";
import { ApiInputType } from "@/core/common/types/api";
import { StripePrice, StripeProduct } from "@/core/common/types/stripe";
import { StripeSubscriptionProductId } from "@/core/common/types/subscription";
import { classNames } from "@/core/utils/classname-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { RequireAuth } from "components/auth/require-auth";
import {
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  PrimaryButtonClassNameLoading,
} from "components/constants/class-names";
import { ApiInput } from "components/dashboard/api/api-input";
import { OptionsInputProps } from "components/dashboard/api/options-input";
import { SimpleSpinner } from "components/icons/simple-spinner";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { CopyButton } from "components/utils/copy-button";
import { displayUiMessage } from "components/utils/display-message";
import { editorContextStore } from "contexts/editor-context";
import { noop } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

const productIdsExcluded = new Set([
  StripeSubscriptionProductId.IndividualProPlan,
  StripeSubscriptionProductId.IndividualProPlusPlan,
  StripeSubscriptionProductId.IndividualScalePlan,
  StripeSubscriptionProductId.ApiStandardPlan,
  StripeSubscriptionProductId.TeamStandardPlan,
  StripeSubscriptionProductId.OneTimePayment,
]);

type Interval = "day" | "month" | "week" | "year";

/**
 * A type guard to determine if a given value is a valid Interval.
 *
 * @param obj - The value to check.
 * @returns `true` if `obj` is a valid Interval, otherwise `false`.
 */
function isInterval(obj: any): obj is Interval {
  // Define the set of valid intervals
  const validIntervals: Interval[] = ["day", "week", "month", "year"];

  // Check if obj is a string and is one of the valid intervals
  return typeof obj === "string" && validIntervals.includes(obj as Interval);
}

/**
 * A dictionary that maps each Interval to its equivalent number of seconds.
 */
const intervalToSeconds: Record<Interval, number> = {
  day: 24 * 60 * 60, // 24 hours * 60 minutes * 60 seconds = 86,400 seconds
  week: 7 * 24 * 60 * 60, // 7 days * 86,400 seconds = 604,800 seconds
  month: 30 * 24 * 60 * 60, // 30 days * 86,400 seconds = 2,592,000 seconds
  year: 365 * 24 * 60 * 60, // 365 days * 86,400 seconds = 31,536,000 seconds
};

enum SetProductQuotaStatus {
  Idle = "Idle",
  Loading = "Loading",
}

const authorizedUserEmails = new Set(["antonio@flair.ai", "mickey@flair.ai", "leon@flair.ai"]);

function AuthorizeUser({ children }: React.PropsWithChildren) {
  const navigate = useNavigate();
  const user = editorContextStore((state) => state.user);

  const isAuthorized = React.useMemo(() => {
    if (!user) {
      return false;
    }

    const email = user.email;

    return Boolean(email && authorizedUserEmails.has(email));
  }, [user]);

  React.useEffect(() => {
    if (!isAuthorized) {
      navigate("/");
    }
  }, [isAuthorized, navigate]);

  if (!isAuthorized) {
    return null;
  }

  return <>{children}</>;
}

function SetProductQuotaInternal() {
  const backend = editorContextStore((state) => state.backend);
  const [status, setStatus] = React.useState(SetProductQuotaStatus.Idle);

  const [products, setProducts] = React.useState<StripeProduct[]>([]);
  const [prices, setPrices] = React.useState<StripePrice[]>([]);

  const [productOptionsConfig, setProductOptionsConfig] = React.useState<OptionsInputProps | null>(
    null,
  );
  const [priceOptionsConfig, setPriceOptionsConfig] = React.useState<OptionsInputProps | null>(
    null,
  );

  const [productId, setProductId] = React.useState("");
  const [priceId, setPriceId] = React.useState("");
  const [maxNumCustomModelTrainings, setMaxNumCustomModelTrainings] = React.useState<number | null>(
    0,
  );
  const [maxNumCustomModelPredictions, setMaxNumCustomModelPredictions] = React.useState<
    number | null
  >(0);
  const [maxNumVideos, setMaxNumVideos] = React.useState<number | null>(0);

  React.useEffect(() => {
    if (!backend) {
      return;
    }

    return backend.onProductsUpdate((products) => {
      setProducts(
        products.filter(
          (product) =>
            product?.id && !productIdsExcluded.has(product.id as StripeSubscriptionProductId),
        ),
      );
    });
  }, [backend]);

  React.useEffect(() => {
    setProductOptionsConfig({
      type: ApiInputType.Options,
      id: "Product",
      name: "Product",
      value: products[0]?.id ?? "",
      options: {
        Products: products.map((product) => ({
          name: `${product.name ?? product.id} (${product.id})`,
          value: product.id,
        })),
      },
      onValueChange: noop,
      required: true,
    });
  }, [products]);

  React.useEffect(() => {
    if (!backend || !productId) {
      setPriceId("");
      debugLog("Reset price id");
      setPriceOptionsConfig(null);
      return;
    }

    backend.onPricesUpdate(productId, (prices) => {
      setPrices(prices);
      setPriceId(prices[0]?.id ?? "");
    });
  }, [backend, productId]);

  React.useEffect(() => {
    setPriceId(prices[0]?.id ?? "");

    debugLog("Reset price id to ", prices[0]?.id);

    setPriceOptionsConfig({
      type: ApiInputType.Options,
      id: "Price",
      name: "Price",
      value: prices[0]?.id ?? "",
      options: {
        Prices: prices.map((price) => ({
          name: `${price.currency.toUpperCase()} ${(price.unit_amount ?? 0) * 0.01} / ${price.recurring?.interval ?? "One-time"}`,
          value: price.id,
        })),
      },
      onValueChange: noop,
      required: true,
    });
  }, [prices]);

  const recurringInterval = React.useMemo(() => {
    if (!priceId || prices.length <= 0) {
      return "";
    }

    const price = prices.find((price) => price.id === priceId);

    if (!price) {
      return "";
    }

    const interval = price.recurring?.interval;

    if (interval) {
      return `/ ${interval}`;
    }

    return "one time";
  }, [priceId, prices]);

  const checkoutSessionType = React.useMemo((): CheckoutSessionType | undefined => {
    if (!priceId || prices.length <= 0) {
      return undefined;
    }

    const price = prices.find((price) => price.id === priceId);

    if (!price) {
      return undefined;
    }

    const interval = price.recurring?.interval;

    if (interval) {
      return "subscription";
    }

    return "one-time";
  }, [priceId, prices]);

  const setProductQuotaParams = async () => {
    if (!backend || !products || !productId || !prices || !priceId) {
      return;
    }

    if (status !== SetProductQuotaStatus.Idle) {
      return;
    }

    const price = prices?.find((price) => price.id === priceId);

    if (!price) {
      return;
    }

    try {
      setStatus(SetProductQuotaStatus.Loading);

      const interval = price.recurring?.interval;

      const intervalSeconds = isInterval(interval) ? intervalToSeconds[interval] : 99999;

      const userQuotas: Partial<AppUserQuotas> = {};

      if (typeof maxNumCustomModelTrainings === "number" && maxNumCustomModelTrainings >= 0) {
        const numTrainingCreditsPerSecondReplicate = 1;
        const numTrainingCreditsPerMinute = numTrainingCreditsPerSecondReplicate * 60;
        const numMinutesPerTrainingRun = 60;
        const numTrainingCreditsPerRun = numTrainingCreditsPerMinute * numMinutesPerTrainingRun;

        userQuotas.maxNumCustomModelTrainingCreditsTotal =
          numTrainingCreditsPerRun * maxNumCustomModelTrainings;
        userQuotas.maxNumCustomModels = maxNumCustomModelTrainings * 10;
        userQuotas.customModelsRenewPeriodSec = intervalSeconds;
      }

      if (typeof maxNumCustomModelPredictions === "number" && maxNumCustomModelPredictions >= 0) {
        const numPredictionCreditsPerSecond = 1;
        const numSecondsPerPrediction = 15;
        const numCreditsPerPrediction = numPredictionCreditsPerSecond * numSecondsPerPrediction;
        userQuotas.maxNumCustomModelPredictionCreditsTotal =
          numCreditsPerPrediction * maxNumCustomModelPredictions;
        userQuotas.customModelsRenewPeriodSec = intervalSeconds;
      }

      if (typeof maxNumVideos === "number" && maxNumVideos >= 0) {
        userQuotas.maxNumVideoGenerationCredits = maxNumVideos;
        userQuotas.videoGenerationRenewPeriodSec = intervalSeconds;
      }

      const response = await backend.setSubscriptionProductIdQuota({
        productId,
        quotas: userQuotas,
      });

      if (!response.success) {
        debugError("Cannot set subscription product quota ", response);

        const message = response.message;

        if (message) {
          displayUiMessage(message, "error");
        }

        return;
      }
    } catch (error) {
      debugError("Error setting subscription product quota ", error);
    } finally {
      setStatus(SetProductQuotaStatus.Idle);
    }
  };

  const checkoutURL = React.useMemo(() => {
    if (!productId || !priceId || !checkoutSessionType) {
      return undefined;
    }

    const baseUrl = "https://app.flair.ai/newCheckout";

    if (checkoutSessionType === "subscription") {
      const params = new URLSearchParams({
        type: checkoutSessionType,
        priceId,
      });

      return `${baseUrl}?${params.toString()}`;
    } else {
      const params = new URLSearchParams({
        type: checkoutSessionType,
        priceId,
        quantity: "1",
      });

      return `${baseUrl}?${params.toString()}`;
    }
  }, [productId, priceId, checkoutSessionType]);

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center text-base bg-zinc-950">
      <form
        className="text-zinc-300 w-[90vw] xl:w-[1024px] rounded-xl flex flex-col items-stretch max-h-[90vh] border border-zinc-800 bg-zinc-900 shadow-xl"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="px-8 py-4 text-lg font-semibold border-b border-zinc-800">
          Set product quotas
        </div>
        <ScrollAreaContainer className="flex-1 max-h-[80vh]">
          <div className="px-8 py-6 flex flex-col items-stretch gap-12">
            {productOptionsConfig && (
              <ApiInput {...productOptionsConfig} value={productId} onValueChange={setProductId} />
            )}
            {priceOptionsConfig && (
              <ApiInput {...priceOptionsConfig} value={priceId} onValueChange={setPriceId} />
            )}
            <ApiInput
              type={ApiInputType.Slider}
              id="maxNumCustomModelTrainings"
              name={`Maximum number of custom model trainings ${recurringInterval}`}
              value={maxNumCustomModelTrainings ?? 0}
              onValueChange={setMaxNumCustomModelTrainings}
              min={0}
              max={1000}
              step={5}
            />
            <ApiInput
              type={ApiInputType.Slider}
              id="maxNumCustomModelPredictions"
              name={`Maximum number of custom model predictions ${recurringInterval}`}
              value={maxNumCustomModelPredictions ?? 0}
              onValueChange={setMaxNumCustomModelPredictions}
              min={0}
              max={10000}
              step={50}
            />
            <ApiInput
              type={ApiInputType.Slider}
              id="maxNumVideos"
              name={`Maximum number of video generations ${recurringInterval}`}
              value={maxNumVideos ?? 0}
              onValueChange={setMaxNumVideos}
              min={0}
              max={500}
              step={5}
            />
            <div className="h-[20vh]" />
          </div>
        </ScrollAreaContainer>
        <div className="px-8 py-4 border-t border-zinc-800 flex flex-col items-stretch gap-4">
          <div className="flex flex-row items-center gap-4">
            <div>Checkout link:</div>
            <div className="flex-1 flex flex-row items-center p-4 bg-zinc-950 rounded-md text-sm truncate min-h-[52px]">
              <span className="flex-1 truncate">{checkoutURL}</span>
              {checkoutURL && <CopyButton textToCopy={checkoutURL} />}
            </div>
          </div>
          <div className="flex flex-row">
            <button
              className={classNames(
                status === SetProductQuotaStatus.Loading
                  ? PrimaryButtonClassNameLoading
                  : productId && priceId
                    ? PrimaryButtonClassName
                    : PrimaryButtonClassNameDisabled,
                "flex-1 lg:min-w-[15vw] flex flex-row items-center justify-center gap-2",
              )}
              onClick={() => {
                if (!productId || !priceId) {
                  return;
                }
                setProductQuotaParams();
              }}
            >
              {status === SetProductQuotaStatus.Loading && (
                <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
              )}
              <span>{status === SetProductQuotaStatus.Loading ? "Loading ..." : "Submit"}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export function SetProductQuota() {
  return (
    <RequireAuth>
      <AuthorizeUser>
        <SetProductQuotaInternal />
      </AuthorizeUser>
    </RequireAuth>
  );
}
