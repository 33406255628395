import { IEditorContext } from "@/contexts/editor-context";
import { getUpdaterFunction, SetEditorStateFunction } from "@/contexts/editor-context-utils";
import { noop } from "lodash";
import { AppUserQuotas } from "../types";
import type { PublicTeamId } from "./public-team-id";
import { isPublicUserId, PublicUserId, PublicUserRoles } from "./public-user-id";
import { AppRoleType } from "./user-roles";
import { StateUpdater } from "./utils";
export { isPublicTeamId } from "./public-team-id";
export type { PublicTeamId } from "./public-team-id";

export type PublicTeamRoles = Record<PublicUserId, AppRoleType>;

export enum TeamSubscriptionTier {
  None = "None",
  Standard = "Standard",
}

export const teamSubscriptionTierRank: Record<TeamSubscriptionTier, number> = {
  [TeamSubscriptionTier.None]: 0,
  [TeamSubscriptionTier.Standard]: 1,
};

export interface PublicTeamQuotas extends Omit<AppUserQuotas, "id"> {
  id: PublicTeamId;
  numTeamUsers: number;
  maxNumTeamUsers: number;
  numCollaborationProjects: number;
  maxNumCollaborationProjects: number;
  teamSubscriptionTier?: TeamSubscriptionTier;
  roles: PublicTeamRoles;
}

export function isPublicTeamQuotas(quotas: any): quotas is PublicTeamQuotas {
  return quotas && quotas.roles && typeof quotas.numTeamUsers === "number";
}

export interface TeamMetadata {
  id: PublicTeamId;
  name: string;
  roles: PublicUserRoles;
}

export function isTeamMetadata(metadata: any): metadata is TeamMetadata {
  return typeof metadata?.id === "string" && (metadata as TeamMetadata).roles != null;
}

export type TeamMetadataCollection = Record<PublicTeamId, TeamMetadata>;

export interface TeamEditorState {
  currentTeamId: PublicTeamId | null;
  setCurrentTeamId: (value: PublicTeamId | null) => void;
  userTeams: TeamMetadataCollection;
  setUserTeams: (value: StateUpdater<TeamMetadataCollection>) => void;
  teamQuotas: PublicTeamQuotas | null;
  setTeamQuotas: (value: StateUpdater<PublicTeamQuotas | null>) => void;
}

export function getDefaultUserTeamMetadata(
  editorContextState: IEditorContext,
): TeamMetadata | null {
  const { user, publicUserId, currentTeamId } = editorContextState;

  if (!user || !publicUserId || !isPublicUserId(publicUserId)) {
    return null;
  }

  return {
    id: currentTeamId,
    name: `${user.displayName ?? "User"}'s Team`,
    roles: {
      [publicUserId]: AppRoleType.Owner,
    },
  };
}

export function getDummyTeamEditorState(): TeamEditorState {
  return {
    currentTeamId: null,
    setCurrentTeamId: noop,
    userTeams: {},
    setUserTeams: noop,
    teamQuotas: null,
    setTeamQuotas: noop,
  };
}

export function getDefaultTeamEditorState(set: SetEditorStateFunction): TeamEditorState {
  return {
    currentTeamId: null,
    setCurrentTeamId: getUpdaterFunction(set, "currentTeamId"),
    userTeams: {},
    setUserTeams: getUpdaterFunction(set, "userTeams"),
    teamQuotas: null,
    setTeamQuotas: getUpdaterFunction(set, "teamQuotas"),
  };
}
