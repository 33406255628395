import { ExternalStorageManager } from "@/backend/base";
import { editorContextStore } from "@/contexts/editor-context";
import { EditorAssetContentType, HttpsCallable, UserAssetType } from "@/core/common/types";
import { getEditorAssetExtension } from "@/core/utils/asset-utils";
import { debugError } from "@/core/utils/print-utilts";
import { isValidFirebaseStoragePath } from "@/core/utils/string-utils";
import { generateUUID } from "@/core/utils/uuid-utils";
import { Functions, httpsCallable } from "firebase/functions";
import { getDownloadURL, ref as storageRef, StringFormat, uploadBytes } from "firebase/storage";
import {
  createGenerateUserAssetUploadUrlFunction,
  GenerateUserAssetUploadUrlArgs,
  GenerateUserAssetUploadUrlFunction,
  GenerateUserAssetUploadUrlResponse,
  getStoragePathFromSignedUrl,
} from "../asset-upload-utils";
import { FLAIR_AI_FIREBASE_CONFIG } from "../config";
import { getFirebaseApp } from "../firebase-backend";

export class FlairStorageManager implements ExternalStorageManager {
  static readonly ASSET_V1_METADATA_COLLECTION_PATH = "assetsMetadataV1";
  static readonly ASSET_STORAGE_PREFIX = "assetsV2/";
  private softDeleteAssetMetadata: HttpsCallable<{ assetId: string }, { status: string }>;
  private generateUserAssetUploadUrlColabJuly24: GenerateUserAssetUploadUrlFunction;

  constructor(firebaseFunctions: Functions) {
    this.generateUserAssetUploadUrlColabJuly24 = createGenerateUserAssetUploadUrlFunction({
      firebaseFunctions,
    });
    this.softDeleteAssetMetadata = httpsCallable<{ assetId: string }, { status: string }>(
      firebaseFunctions,
      "deleteAsset",
    );
  }

  private static readonly STORAGE_URL_PATTERNS = {
    FIREBASE_STORAGE: {
      HTTP: (bucket: string) => `https://storage.googleapis.com/${bucket}/`,
      GS: (bucket: string) => `gs://${bucket}/`,
    },
    // Regex for extracting paths from Firebase Storage URLs
    PATH_EXTRACTOR: /\/o\/([^?]*)/,
  };

  /**
   * Creates a fully qualified storage URL from a storage path
   * @param storagePath Relative storage path (e.g. "assetsV2/xyz/asset.png")
   * @returns Full storage URL with bucket and protocol
   */
  public createStorageUrl(storagePath: string): string {
    try {
      const bucket = import.meta.env.VITE_FIREBASE_STORAGE_BUCKET;
      if (!bucket) {
        throw new Error("Storage bucket not configured");
      }

      // Remove any leading slashes
      const cleanPath = storagePath.replace(/^\/+/, "");

      // Create GS URL
      const gsUrl = `${FlairStorageManager.STORAGE_URL_PATTERNS.FIREBASE_STORAGE.GS(
        bucket,
      )}${cleanPath}`;
      return gsUrl;
    } catch (error) {
      debugError("Error creating storage URL:", error);
      return storagePath;
    }
  }

  //Securily marks asset isDeleted flag to true
  async deleteAssetMetadata(params: { assetId: string }) {
    const response = await this.softDeleteAssetMetadata(params);
    return response.data?.status;
  }
  /**
   * Gets the configured storage bucket from environment or config
   */
  private static getStorageBucket(): string {
    return FLAIR_AI_FIREBASE_CONFIG.storageBucket || "";
  }

  /**
   * Gets all valid storage URL prefixes for the current configuration
   */
  private static getStorageURLPrefixes(): string[] {
    const bucket = this.getStorageBucket();
    return [
      this.STORAGE_URL_PATTERNS.FIREBASE_STORAGE.HTTP(bucket),
      this.STORAGE_URL_PATTERNS.FIREBASE_STORAGE.GS(bucket),
    ];
  }

  getAssetStoragePathFromId(assetId: string) {
    return `${FlairStorageManager.ASSET_STORAGE_PREFIX}${assetId}/asset.png`;
  }
  /**
   * Extracts the storage path from a Firebase Storage URL
   * @param url Full Firebase Storage URL
   * @returns Cleaned storage path
   */
  private static extractPathFromStorageURL(url: string): string {
    try {
      const match = url.match(this.STORAGE_URL_PATTERNS.PATH_EXTRACTOR);
      if (!match?.[1]) {
        return url;
      }
      return decodeURIComponent(match[1]);
    } catch (error) {
      console.error("Error extracting path from storage URL:", error);
      return url;
    }
  }

  private isSignedUrl(value: string): boolean {
    //if it contains googleapis.com/
    return value.includes("googleapis.com/");
  }
  /**
   * Checks if a given string is a valid storage URL
   */
  public isStoragePathURL = (value: string): boolean => {
    try {
      if (this.isSignedUrl(value)) {
        const cleanedPath = this.cleanupStoragePathURL(value);
        if (isValidFirebaseStoragePath(cleanedPath)) {
          return true;
        }
      }

      return FlairStorageManager.getStorageURLPrefixes().some((prefix) => value.startsWith(prefix));
    } catch (error) {
      console.error("Error checking storage path URL:", error);
      return false;
    }
  };

  /**
   * Cleans up a storage URL to get the raw path
   * @param storagePath Storage URL or path
   * @returns Cleaned storage path
   */
  public cleanupStoragePathURL(storagePath: string): string {
    try {
      // Handle standard Firebase Storage URLs
      const prefixes = FlairStorageManager.getStorageURLPrefixes();
      for (const prefix of prefixes) {
        if (storagePath.startsWith(prefix)) {
          return storagePath.slice(prefix.length);
        }
      }

      // Handle download URLs
      if (storagePath.startsWith("https://")) {
        return FlairStorageManager.extractPathFromStorageURL(storagePath);
      }

      // Return original path if no cleanup needed
      return storagePath;
    } catch (error) {
      console.error("Error cleaning up storage path URL:", error);
      return storagePath;
    }
  }

  private async uploadUrlToStorage({
    data,
    contentType,
    assetId,
    assetType,
    stringFormat,
    projectId,
  }: {
    data: string;
    contentType: EditorAssetContentType;
    assetId?: string;
    assetType?: UserAssetType;
    stringFormat: StringFormat;
    projectId?: string;
  }) {
    console.log("uploadUrlToStorage", contentType, stringFormat);
    try {
      const { signedUrl, extensionHeaders } = await this.generateAssetUploadUrl({
        assetType: assetType ?? UserAssetType.Unknown,
        contentType,
        projectId,
        publicTeamId: editorContextStore.getState().currentTeamId,
      });
      //ensure base64/raw data is converted to blob properly
      const processedData = this.processUploadData(data, contentType, stringFormat);
      try {
        const response = await fetch(signedUrl, {
          method: "PUT",
          headers: {
            ...extensionHeaders,
            "Content-Type": contentType,
          },
          body: processedData,
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `Failed to upload data to storage: ${response.status} ${response.statusText} - ${errorText}`,
          );
        }

        const cleanedPath = getStoragePathFromSignedUrl(signedUrl);

        return cleanedPath;
      } catch (error) {
        debugError("Upload error:", error);
        throw error; // Re-throw to handle it at a higher level
      }
    } catch (error) {
      debugError("Storage upload error:", error);
      throw error; // Re-throw to handle it at a higher level
    }
  }

  private processUploadData(
    data: string,
    contentType: EditorAssetContentType,
    stringFormat: StringFormat,
  ) {
    // Handle data based on stringFormat
    let processedData: Blob;
    switch (stringFormat) {
      case "data_url": {
        // For data URLs, extract and convert base64 to Blob
        const base64Data = data.split(",")[1];
        if (!base64Data) {
          throw new Error("Invalid data URL format");
        }
        const binaryData = atob(base64Data);
        const array = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          array[i] = binaryData.charCodeAt(i);
        }
        processedData = new Blob([array], { type: contentType });
        break;
      }
      case "base64": {
        // Convert base64 string to Blob using proper binary conversion
        const binaryData = atob(data);
        const array = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          array[i] = binaryData.charCodeAt(i);
        }
        processedData = new Blob([array], { type: contentType });
        break;
      }
      case "raw":
        // Convert raw string to Blob to ensure proper handling
        processedData = new Blob([data], { type: contentType });
        break;
      default:
        throw new Error(`Unsupported string format: ${stringFormat}`);
    }

    // Validate blob size
    const MAX_SIZE = 10 * 1024 * 1024; // 10MB example limit
    if (processedData.size > MAX_SIZE) {
      throw new Error(`File size exceeds maximum allowed size of ${MAX_SIZE} bytes`);
    }

    return processedData;
  }

  async uploadFileToStorage({
    data,
    contentType,
    assetType,
    projectId,
  }: {
    data: File | Blob;
    contentType: EditorAssetContentType;
    assetType?: UserAssetType;
    projectId?: string;
  }) {
    try {
      const { signedUrl, assetId, extensionHeaders } = await this.generateAssetUploadUrl({
        assetType: assetType ?? UserAssetType.Unknown,
        contentType,
        projectId,
        publicTeamId: editorContextStore.getState().currentTeamId,
      });
      const response = await fetch(signedUrl, {
        method: "PUT",
        headers: { ...extensionHeaders, "Content-Type": contentType },
        body: data,
      });
      if (!response.ok) {
        throw new Error(`Failed to upload data to storage: ${response.statusText}`);
      }
      const cleanedPath = getStoragePathFromSignedUrl(signedUrl);
      return cleanedPath;
    } catch (error) {
      debugError(error);
    }
  }

  async getDownloadUrlFromStoragePath(path: string) {
    try {
      const { firebaseStorage } = getFirebaseApp();

      // Clean up the path if it's a full URL
      if (this.isStoragePathURL(path)) {
        const url = new URL(path);
        url.search = ""; // Remove query parameters
        path = this.cleanupStoragePathURL(url.toString());
      }

      // Create storage reference
      const ref = storageRef(firebaseStorage, path);

      // Make sure that this is not a root reference
      if (ref.fullPath === "") {
        debugError("getDownloadUrlFromStoragePath: root reference");
        return "";
      }

      // Get the download URL which includes the token
      const downloadUrl = await getDownloadURL(ref);

      // Add CORS headers to the URL
      const corsUrl = new URL(downloadUrl);
      corsUrl.searchParams.append("cors", "true");

      return corsUrl.toString();
    } catch (error) {
      debugError("getDownloadUrlFromStoragePath error:", {
        path,
        error,
      });
      return "";
    }
  }

  async uploadDataUrlToStorage({
    data,
    contentType,
    assetId,
    projectId,
    assetType,
  }: {
    data: string;
    contentType: EditorAssetContentType;
    assetId?: string;
    projectId?: string;
    assetType?: UserAssetType;
  }) {
    return this.uploadUrlToStorage({
      data,
      assetId,
      contentType,
      stringFormat: "data_url",
      projectId,
      assetType,
    });
  }

  async uploadJsonToStorage({
    data,
    assetId,
    projectId,
    assetType,
  }: {
    data: string;
    assetId?: string;
    projectId?: string;
    assetType?: UserAssetType;
  }) {
    return this.uploadUrlToStorage({
      data,
      assetId,
      contentType: EditorAssetContentType.json,
      stringFormat: "raw",
      projectId,
      assetType,
    });
  }

  async generateAssetUploadUrl({
    assetType,
    contentType,
    tags,
    extensionHeaders,
    projectId,
  }: GenerateUserAssetUploadUrlArgs): Promise<GenerateUserAssetUploadUrlResponse> {
    const teamId = editorContextStore.getState().currentTeamId;
    const response = await this.generateUserAssetUploadUrlColabJuly24({
      assetType,
      contentType,
      publicTeamId: teamId,
      tags,
      extensionHeaders,
      projectId,
    });

    const { assetId, signedUrl, extensionHeaders: responseExtensionHeaders } = response.data ?? {};

    return {
      assetId,
      signedUrl,
      extensionHeaders: responseExtensionHeaders,
    };
  }

  private getUserDatasetStoragePath(modelId: string, contentType: EditorAssetContentType) {
    const { firebaseAuth } = getFirebaseApp();
    const uid = firebaseAuth.currentUser?.uid;
    const assetId = generateUUID();
    return `users/${uid}/dataset/${modelId}/${assetId}${getEditorAssetExtension(contentType)}`;
  }

  async uploadFileToDatasetStorage({
    data,
    modelId,
    contentType,
  }: {
    data: File | Blob;
    modelId: string;
    contentType: EditorAssetContentType;
  }) {
    const { firebaseStorage } = getFirebaseApp();
    const storagePath = this.getUserDatasetStoragePath(modelId, contentType);
    const blobRef = storageRef(firebaseStorage, storagePath);
    await uploadBytes(blobRef, data);
    return storagePath;
  }
}
