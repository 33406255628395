import { editorContextStore } from "@/contexts/editor-context";
import {
  CustomModelPredictionItem,
  getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput,
} from "@/core/common/types";
import { CustomModelInfo } from "@/core/common/types/custom-model-types";
import { Assets } from "@/core/controllers/assets";
import { classNames } from "@/core/utils/classname-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { sortByTimeModified } from "@/core/utils/time-utils";
import { preprocessImageUrl } from "@/core/utils/url-utils";
import { useComponentSize } from "@/hooks/use-component-size";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { formatDistanceToNow } from "date-fns";
import { ChevronDown, Download } from "lucide-react";
import React, { memo } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  CardClassName,
  DashboardTitleClassName,
  DropdownClassName,
  DropdownMenuItemClassName,
  frostedGlassClassName,
  SecondaryButtonClassNameInactive,
} from "../constants/class-names";
import { ASSETS } from "../constants/routes";
import { getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState } from "../custom-model/custom-model-mention-plugin";
import { CustomModelPredictionTypeTag } from "../custom-model/custom-model-predictions";
import { useModelThumbnailUrl } from "../custom-model/dashboard-custom-models";
import { downloadUrl } from "../utils/data";
import { ImageComponent } from "../utils/image";

const ASPECT_RATIO = 4 / 3;

function CustomModelCard({ customModel }: { customModel: CustomModelInfo }) {
  const navigate = useNavigate();
  const storageManager = editorContextStore((state) => state.storageManager);
  const setCustomModelId = editorContextStore((state) => state.setCustomModelId);
  const thumbnailUrl = useModelThumbnailUrl({
    model: customModel,
  });

  return (
    <button
      onClick={() => {
        navigate(`/${ASSETS}?modelId=${customModel.id}`);
        setCustomModelId(customModel.id);
      }}
    >
      <div className={`${CardClassName}`}>
        <AspectRatio.Root
          ratio={ASPECT_RATIO}
          className="pointer-events-none select-none bg-cover bg-center"
          style={{
            backgroundImage: thumbnailUrl ? `url(${preprocessImageUrl(thumbnailUrl)})` : "",
          }}
        ></AspectRatio.Root>
        <div className="flex flex-row px-3 py-3">
          <div className="flex-1 mr-2 truncate">
            <div className="flex flex-col">
              <span>{customModel.displayName}</span>
              <span className="text-sm text-zinc-400">Custom Model </span>
            </div>
          </div>
          <div className="flex flex-row gap-2">{/* TODO: Add number of predictions */}</div>
        </div>
      </div>
    </button>
  );
}

function GeneratedImageCard({ prediction }: { prediction: CustomModelPredictionItem }) {
  const [predictionImageUrls, setPredictionImageUrls] = React.useState<string[]>([]);
  const { backend, storageManager } = editorContextStore.getState();

  const timeAgo = React.useMemo(() => {
    const time = prediction.timeModified || prediction.timeCreated;

    if (!time) {
      return;
    }

    return formatDistanceToNow(time.toDate(), {
      addSuffix: true,
    });
  }, [prediction]);

  const input = React.useMemo(
    () => getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(prediction.input),
    [prediction],
  );

  React.useEffect(() => {
    setPredictionImageUrls(Array(input?.num_outputs ?? 0).map(() => ""));

    if (!backend) {
      return;
    }

    const output = prediction?.output;

    if (!output) {
      return;
    }

    Promise.all(
      output.map((path) =>
        Assets.loadAssetFromPath({
          path,
          storageManager,
        }),
      ),
    ).then((imageUrls) => {
      const validImageUrls = imageUrls.filter(Boolean) as string[];

      setPredictionImageUrls(validImageUrls);
    });
  }, [backend, storageManager, input, prediction?.output]);

  const [width, height] = React.useMemo(() => [input.width, input.height], [input]);
  const predictionInput = React.useMemo(
    () => getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(prediction.input),
    [prediction.input],
  );

  const prompt = React.useMemo(() => {
    const promptJson = predictionInput.promptJson;

    try {
      if (!promptJson) {
        return predictionInput.prompt;
      }

      const { text } = getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
        promptEditorState: JSON.parse(promptJson),
        replaceWithText: true,
      });

      return text;
    } catch (error) {
      debugError(`Cannot load prediction prompt json `, promptJson, "\n", error);
    }

    return predictionInput.prompt;
  }, [predictionInput]);

  return (
    <div
      className={classNames(
        SecondaryButtonClassNameInactive,
        "group h-auto max-w-full p-2 flex flex-col items-stretch gap-4 rounded-xl border border-zinc-800 shadow-xl active:border-lime-700 hover:border-zinc-600 hover:shadow-lg",
      )}
    >
      {predictionImageUrls.filter(Boolean).length > 0 && (
        <div className="h-fit flex flex-col gap-2">
          {predictionImageUrls.map((imageUrl, index) => (
            <AspectRatio.Root
              ratio={width / height}
              className={classNames(
                "group/prediction relative rounded bg-contain hover:outline hover:outline-1 hover:outline-lime-500 overflow-hidden",
              )}
            >
              <ImageComponent
                className="pointer-events-none group-hover/prediction:scale-110 duration-300 transition-all"
                src={preprocessImageUrl(imageUrl)}
              />
              <div
                className={classNames(
                  "absolute top-0 left-0 p-2 w-full h-full flex flex-col items-stretch",
                  "opacity-0 group-hover/prediction:opacity-100 transition-opacity",
                  "pointer-events-none group-hover/prediction:pointer-events-auto",
                )}
              >
                <div className="min-h-0 flex-1" />
                <div className="flex flex-row items-center">
                  <div className="flex-1 min-w-0" />
                  <button
                    className={classNames(
                      frostedGlassClassName,
                      "w-7 h-7 flex items-center justify-center gap-2 rounded-full",
                    )}
                    onClick={() => {
                      debugLog("Download image from data url: ", imageUrl);

                      const processedImageUrl = preprocessImageUrl(imageUrl);

                      if (!processedImageUrl) {
                        return;
                      }

                      downloadUrl(processedImageUrl, `output-${prediction.id}-${index}`);
                    }}
                  >
                    <Download size={15} />
                  </button>
                </div>
              </div>
            </AspectRatio.Root>
          ))}
        </div>
      )}
      <div className="flex flex-row truncate text-sm text-zinc-300 group-hover:text-lime-500">
        <CustomModelPredictionTypeTag prediction={prediction} />
        <span>{timeAgo}</span>
      </div>
      <div
        className={classNames(
          "text-sm px-2 py-1 bg-zinc-800/50 text-zinc-500 group-hover:text-zinc-300 rounded border border-zinc-800 transition-colors",
        )}
      >
        {prompt}
      </div>
    </div>
  );
}

function AllAssetsHeader({ selectedModel }: { selectedModel?: CustomModelInfo }) {
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex items-center gap-2">
        <Link to={`/${ASSETS}`}>
          <span className={DashboardTitleClassName}>All Custom Models</span>
        </Link>
        {selectedModel && (
          <>
            <span className="text-zinc-500">/</span>
            <span className={DashboardTitleClassName}>{selectedModel.displayName}</span>
          </>
        )}
      </div>
    </div>
  );
}

function AssetFilteringAndSortingMenu({
  onSortChange,
}: {
  onSortChange: (order: "newest" | "oldest") => void;
}) {
  const [currentSortOrder, setCurrentSortOrder] = React.useState<"Newest first" | "Oldest first">(
    "Newest first",
  );

  return (
    <div className="flex flex-row items-center justify-between gap-4">
      <span className="text-zinc-400">Sort by:</span>
      <div className="flex flex-row items-center justify-between gap-4 bg-zinc-800 rounded-lg">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger className="w-full group flex flex-row items-center justify-start gap-1 cursor-pointer">
            <div className="border-zinc-800 border w-full md:min-w-[260px] flex flex-row rounded-md items-center justify-start px-4 py-2">
              <span className="min-w-0 flex-1 text-zinc-300 group-hover:text-zinc-100 transition-colors truncate">
                {currentSortOrder}
              </span>
              <ChevronDown
                size={18}
                className="text-zinc-500 group-hover:text-zinc-300 transition-colors"
              />
            </div>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className={classNames(
                DropdownClassName,
                "bg-zinc-900 shadow-md border border-zinc-800 rounded-md min-w-[230px] max-w-[350px] text-zinc-300 text-sm flex flex-col justify-center items-start",
              )}
            >
              <DropdownMenu.Item
                className={classNames(DropdownMenuItemClassName)}
                onClick={() => {
                  onSortChange("newest");
                  setCurrentSortOrder("Newest first");
                }}
              >
                Newest first
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className={classNames(DropdownMenuItemClassName)}
                onClick={() => {
                  onSortChange("oldest");
                  setCurrentSortOrder("Oldest first");
                }}
              >
                Oldest first
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    </div>
  );
}

function CustomModelsGrid({ models }: { models: CustomModelInfo[] }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 xl:gap-6">
      {models.map((customModel) => (
        <CustomModelCard key={customModel.id} customModel={customModel} />
      ))}
    </div>
  );
}

function PredictionGrid() {
  const customModelPredictions = editorContextStore((state) => state.customModelPredictions);
  const setCustomModelPredictions = editorContextStore((state) => state.setCustomModelPredictions);
  const backend = editorContextStore((state) => state.backend);
  const publicUserId = editorContextStore((state) => state.publicUserId);
  const currentTeamId = editorContextStore((state) => state.currentTeamId);
  const [searchParams] = useSearchParams();
  const modelId = searchParams.get("modelId");

  const predictions = React.useMemo(() => {
    return Object.values(customModelPredictions).sort(sortByTimeModified);
  }, [customModelPredictions]);

  React.useEffect(() => {
    setCustomModelPredictions({});

    if (!modelId || !backend || !publicUserId) {
      return;
    }

    const unsubscribePromise = backend
      .getPublicCustomModelPredictions({
        modelId,
      })
      .then((publicPredictions) => {
        return backend.onCustomModelPredictionsUpdate({
          modelId,
          publicUserId,
          publicTeamId: currentTeamId,
          callback: (predictions) => {
            setCustomModelPredictions({
              ...predictions,
              ...publicPredictions,
            });
          },
        });
      });

    return () => {
      setCustomModelPredictions({});

      unsubscribePromise.then((unsubscribe) => {
        unsubscribe();
      });
    };
  }, [backend, modelId, publicUserId, currentTeamId, setCustomModelPredictions]);

  const [containerSize, containerRef] = useComponentSize<HTMLDivElement>();

  const { columns, numColumns } = React.useMemo(() => {
    const width = containerSize.width;

    const numColumns = width > 1024 ? 3 : width > 640 ? 2 : 1;

    const columns = Array.from(Array(numColumns).keys()).map((columnIndex) => {
      return predictions.filter((_, index) => index % numColumns === columnIndex);
    });

    return {
      columns,
      numColumns,
    };
  }, [containerSize.width, predictions]);

  return (
    <div
      ref={containerRef}
      className="grid grid-cols-2 gap-4"
      style={{
        gridTemplateColumns: `repeat(${numColumns}, minmax(0, 1fr))`,
      }}
    >
      {columns.map((predictions, index) => (
        <div key={index}>
          <div className="grid gap-4">
            {predictions.map((prediction) => (
              <GeneratedImageCard key={prediction.id} prediction={prediction} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export const DashboardAssets = memo(function UpdatedDashboardAssets({
  visible = true,
}: {
  visible?: boolean;
}) {
  const [searchParams] = useSearchParams();
  const modelId = searchParams.get("modelId");
  const [sortOrder, setSortOrder] = React.useState<"newest" | "oldest">("newest");

  // TODO: This is a temporary solution to get all custom models, we need to change this to get only the current team's custom models
  const customModels = editorContextStore((state) => state.customModels);
  const selectedModel = modelId ? customModels[modelId] : undefined;

  const sortedAndFilteredModels = React.useMemo(() => {
    const models = Object.values(customModels);

    // Apply sorting
    models.sort((a, b) => {
      const timeA = a.timeModified?.seconds || 0;
      const timeB = b.timeModified?.seconds || 0;
      return sortOrder === "newest" ? timeB - timeA : timeA - timeB;
    });

    return models;
  }, [customModels, sortOrder]);

  return (
    <div className={classNames(visible ? "flex flex-col gap-4" : "hidden", "p-4")}>
      <div className="flex flex-row justify-between items-center w-full min-h-12">
        <AllAssetsHeader selectedModel={selectedModel} />
        {!modelId ? <AssetFilteringAndSortingMenu onSortChange={setSortOrder} /> : null}
      </div>

      {modelId ? <PredictionGrid /> : <CustomModelsGrid models={sortedAndFilteredModels} />}
    </div>
  );
});
