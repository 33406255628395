import { canUserCreateCustomModel } from "@/core/utils/custom-model-utils";
import { debugLog } from "@/core/utils/print-utilts";
import { getCurrentTeamId } from "@/core/utils/team-utils";
import { doesUserHaveTeamWritePermission } from "@/core/utils/user-role-utils";
import { editorContextStore } from "contexts/editor-context";
import React from "react";
import { getTeamOrUserQuotas } from "./use-user-quotas";

export function useCustomModelsEffect() {
  const backend = editorContextStore((state) => state.backend);
  const publicUserId = editorContextStore((state) => state.publicUserId);
  const currentTeamId = editorContextStore((state) => state.currentTeamId);
  const setCustomModels = editorContextStore((state) => state.setCustomModels);
  React.useEffect(() => {
    if (!publicUserId) {
      return;
    }
    if (!backend) {
      return;
    }
    return backend?.onUserCustomModelsUpdate({
      publicUserId,
      publicTeamId: currentTeamId,
      callback: setCustomModels,
    });
  }, [backend, publicUserId, currentTeamId, setCustomModels]);
}

export function useCanUserCreateCustomModel() {
  const userQuotas = getTeamOrUserQuotas();

  return React.useMemo(() => {
    return canUserCreateCustomModel({
      userQuotas,
    });
  }, [userQuotas]);
}

export function usePublicCustomModelsEffect() {
  const backend = editorContextStore((state) => state.backend);
  const setPublicCustomModels = editorContextStore((state) => state.setPublicCustomModels);

  React.useEffect(() => {
    if (!backend) {
      return;
    }

    backend.getPublicCustomModels().then((models) => {
      setPublicCustomModels(Object.fromEntries(models.map((model) => [model.id, model])));
    });
  }, [backend, setPublicCustomModels]);
}

export function useCanUserEditCustomModel() {
  const publicUserId = editorContextStore((state) => state.publicUserId);
  const currentTeamId = getCurrentTeamId();
  const userCanEdit = editorContextStore(doesUserHaveTeamWritePermission);

  return React.useMemo(() => {
    if (!publicUserId || !currentTeamId) {
      debugLog(`User cannot edit custom model because public user id ${publicUserId} is invalid.`);
      return false;
    }
    return userCanEdit;
  }, [publicUserId, currentTeamId, userCanEdit]);
}
