import type { IEditorContext } from "@/contexts/editor-context";
import {
  getEncryptedTeamIdFromPublicUserId,
  isPublicTeamId,
} from "@/core/common/types/public-team-id";
import { getEncryptedUserIdFromPublicUserId } from "@/core/common/types/public-user-id";
import type { AppUserQuotas } from "../common/types";
import { debugError, debugLog } from "./print-utilts";

export function getTeamQuotasFromContext(editorContextState: IEditorContext): AppUserQuotas | null {
  try {
    const { userQuotas, teamQuotas, currentTeamId, publicUserId } = editorContextState;

    // If the current team id is the same as the current user id, then we default to the user's own quota

    if (
      getEncryptedTeamIdFromPublicUserId(currentTeamId) ===
      getEncryptedUserIdFromPublicUserId(publicUserId)
    ) {
      debugLog("User is the default owner of the team, so use user quotas.");

      return userQuotas;
    }

    if (isPublicTeamId(currentTeamId) && teamQuotas?.id === currentTeamId) {
      debugLog("Using public team quotas");
      return teamQuotas;
    }

    // debugLog("Using default user quotas: ", {
    //   publicUserId,
    //   currentTeamId,
    //   teamQuotas,
    // });

    return userQuotas;
  } catch (error) {
    debugError("Error getting team quotas from context state: ", error);

    return editorContextState?.userQuotas || null;
  }
}
