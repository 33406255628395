import {
  GetAssetMetadataGeneratorArgs,
  GetThumbnailPathFromAssetStoragePathArgs,
} from "@/backend/base";
import { AppRoleType } from "@/core/common/types";
import { isAssetMetadata } from "@/core/common/types/assetV2";
import { debugError } from "@/core/utils/print-utilts";
import {
  collection,
  doc,
  DocumentData,
  Firestore,
  getDoc,
  QueryConstraint,
  where,
} from "firebase/firestore";
import { FirebaseDocQueryGenerator } from "./firebase-doc-query-generator";

const StorageURLPrefixes = [
  `https://storage.googleapis.com/${import.meta.env.VITE_FIREBASE_STORAGE_BUCKET}/`,
  `https://firebasestorage.googleapis.com/v0/b/${import.meta.env.VITE_FIREBASE_STORAGE_BUCKET}/`,
];

export const StoragePathPrefix = `${import.meta.env.VITE_FIREBASE_STORAGE_BUCKET}/`;
export const PublicApiBucketPrefix = "api-storage.flair.ai";

const isStoragePathURL = (value: string) => {
  try {
    if (typeof value !== "string") {
      console.error("Invalid input: not a string");
      return false;
    }

    value = value.trim();

    return Boolean(
      StorageURLPrefixes.find((prefix) => {
        return value.startsWith(prefix);
      }),
    );
  } catch (error) {
    console.error(error);
  }
  return false;
};

export function cleanupStoragePathURL(storagePath: string) {
  try {
    storagePath = storagePath.trim();

    if (isStoragePathURL(storagePath)) {
      const storageUrl = new URL(storagePath);

      storageUrl.search = "";

      const pathname = storageUrl.pathname;

      const path = decodeURIComponent(pathname);

      if (storageUrl.host === "storage.googleapis.com") {
        return path.split("/").slice(2).join("/");
      }

      if (storageUrl.host === "firebasestorage.googleapis.com") {
        return path.split("/").slice(5).join("/");
      }

      console.log("Splitted storage path", path);

      return path;
    } else if (storagePath.startsWith(StoragePathPrefix)) {
      return storagePath.slice(StoragePathPrefix.length);
    }

    return storagePath;
  } catch (error) {
    console.error(error);
  }
  return storagePath;
}

function regexExtractUserAssetIdFromUpscaleIntermediateResultPath(
  path: string,
): string | undefined {
  try {
    const regex = /^users\/[^\/]+\/assets\/intermediate\/([^\/]+)\/([^\/]+)\.[^\/]+$/;
    const match = path.match(regex);

    if (match) {
      const assetId = match[1];
      const fileName = match[2];
      return `${assetId}-${fileName}`;
    }
  } catch (error) {
    console.error(`Cannot extract user id from upsacle intermediate result path ${path}`);
  }

  return undefined;
}

function regexExtractUserAssetIdFromLegacyUserAssetPath(path: string): string | undefined {
  try {
    const pattern = /users\/[^\/]+\/assets\/(.*?)\.[^\.]+$/;

    const match = path.match(pattern);

    return match?.[1];
  } catch (error) {
    console.error(`Cannot extract user id from asset V1 path ${path}`);
  }

  return undefined;
}

function regexExtractUserAssetIdFromLegacyUserAssetV2(path: string): string | undefined {
  try {
    const patterns = [
      /assetsV2\/([^\/]+)\/[^\/]*$/, // Case 2
      /assetsV2\/([^\/]+)\/.+$/, // Case 3
    ];

    for (const pattern of patterns) {
      const match = path.match(pattern);
      if (match) {
        return match[1];
      }
    }
  } catch (error) {
    console.error(`Cannot extract user id from asset V2 path ${path}`);
  }

  return undefined;
}

function regexExtractUserAssetIdFromPath(path: string | undefined): string | undefined {
  // This regex pattern captures the 'id' portion in the specified path formats.
  // It assumes that 'id' can include dots but excludes any slashes.
  // The patterns cater to:
  // 1. /users/{uid}/assets/ijk.png -> captures "ijk"
  // 2. /assetsV2/{assetId}/* -> captures {assetId}
  // 3. /assetsV2/{assetId}/**/* -> captures {assetId}

  if (!path) {
    return undefined;
  }

  return (
    regexExtractUserAssetIdFromUpscaleIntermediateResultPath(path) ||
    regexExtractUserAssetIdFromLegacyUserAssetPath(path) ||
    regexExtractUserAssetIdFromLegacyUserAssetV2(path)
  );
}

import { getUserAssetIdFromPath } from "@/core/utils/storage-path-utils";

const assetsMetadataCollectionName = "assetsMetadataV1";

function getAssetV2IdFromPath(path?: string): string | undefined {
  return getUserAssetIdFromPath(path);
}

function getAssetV2MetadataCollectionRef(firestore: Firestore) {
  return collection(firestore, assetsMetadataCollectionName);
}

function getAssetV2MetadataDocRef({
  firestore,
  assetId,
}: {
  firestore: Firestore;
  assetId: string;
}) {
  return doc(getAssetV2MetadataCollectionRef(firestore), assetId);
}

export class AssetV2Manager {
  private firestore: Firestore;

  constructor({ firestore }: { firestore: Firestore }) {
    this.firestore = firestore;
  }

  private getAssetV2MetadataCollectionRef() {
    return getAssetV2MetadataCollectionRef(this.firestore);
  }

  private getAssetV2MetadataDocRef(assetId: string) {
    return getAssetV2MetadataDocRef({
      firestore: this.firestore,
      assetId,
    });
  }

  async getAssetMetadata(assetId: string) {
    try {
      const assetMetadataDocRef = this.getAssetV2MetadataDocRef(assetId);

      const assetMetadataDocSnapshot = await getDoc(assetMetadataDocRef);

      if (!assetMetadataDocSnapshot.exists()) {
        return undefined;
      }

      const assetMetadataDoc = assetMetadataDocSnapshot.data();

      if (!isAssetMetadata(assetMetadataDoc)) {
        debugError(`Asset ${assetId} metadata doc is invalid: `, assetMetadataDoc);
        return undefined;
      }

      return assetMetadataDoc;
    } catch (error) {
      debugError(`Error retrieving asset ${assetId} metadata doc: `, error);
      return undefined;
    }
  }

  async getAssetMetadataFromStoragePath(storagePath: string) {
    const assetId = getAssetV2IdFromPath(storagePath);

    if (!assetId) {
      return undefined;
    }

    return await this.getAssetMetadata(assetId);
  }

  getAssetMetadataGenerator({
    publicUserId,
    assetType,
    tag,
    batchSize = 10,
  }: GetAssetMetadataGeneratorArgs) {
    return new FirebaseDocQueryGenerator<DocumentData>({
      baseQuery: this.getAssetV2MetadataCollectionRef(),
      batchSize,
      queryConstraints: [
        where("assetType", "==", assetType),
        //todo: change to lookup on publicTeamId only
        where(`roles.${publicUserId}`, "in", Object.values(AppRoleType)),
        where("isDeleted", "in", [false, null]),
        tag ? where("tags", "array-contains", tag) : undefined,
      ].filter(Boolean) as QueryConstraint[],
    });
  }

  async getThumbnailPathFromAssetStoragePath({
    storagePath,
  }: GetThumbnailPathFromAssetStoragePathArgs) {
    try {
      const assetId = getUserAssetIdFromPath(storagePath);

      if (assetId) {
        const assetMetadata = await this.getAssetMetadataFromStoragePath(storagePath);

        if (assetMetadata) {
          const { thumbnail512StoragePath, thumbnail256StoragePath, thumbnail128StoragePath } =
            assetMetadata;

          return (
            thumbnail512StoragePath ||
            thumbnail256StoragePath ||
            thumbnail128StoragePath ||
            storagePath
          );
        }
      }

      return storagePath;
    } catch (error) {
      debugError(`Error retrieving thumbnail path from dataset path ${storagePath}: `, error);
      return storagePath;
    }
  }
}
