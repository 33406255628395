import { UserAssetType } from "@/core/common/types";
import {
  AssetMetadata,
  AssetMetadataCollection,
  AssetMetadataGenerator,
} from "@/core/common/types/assetV2";
import { classNames } from "@/core/utils/classname-utils";
import { SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { mergeRefs } from "components/utils/merge-refs";
import { Tooltip, TooltipProps } from "components/utils/tooltip";
import { editorContextStore } from "contexts/editor-context";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { PresetImageGridItem, PresetImageGridItemPropsAddItemHandler } from "./preset-image-grid";

export function useUserImageAssets() {
  const assetMetadataCollection = editorContextStore((state) => state.assetMetadataCollection);
  const assetMetadataGeneratorRef = editorContextStore((state) => state.assetMetadataGeneratorRef);
  const backend = editorContextStore((state) => state.backend);
  const publicUserId = editorContextStore((state) => state.publicUserId);

  React.useEffect(() => {
    if (!backend || !publicUserId) {
      return;
    }

    if (!assetMetadataGeneratorRef.current) {
      assetMetadataGeneratorRef.current = backend.getAssetMetadataGenerator({
        assetType: UserAssetType.UploadedProduct,
        batchSize: 12,
        publicUserId,
      }) as unknown as AssetMetadataGenerator;
      assetMetadataGeneratorRef.current?.getNextBatch().then((assetInfo) => {
        editorContextStore.getState().setAssetMetadataCollection(
          assetInfo.reduce<AssetMetadataCollection>((result, i) => {
            result[i.id] = i;
            return result;
          }, {}),
        );
      });
    }
  }, [backend, publicUserId, assetMetadataGeneratorRef]);

  const [lastRowRef, lastRowInView] = useInView();

  React.useEffect(() => {
    if (!lastRowInView || !assetMetadataGeneratorRef.current) {
      return;
    }

    assetMetadataGeneratorRef.current.getNextBatch().then((assetInfo) => {
      editorContextStore.getState().setAssetMetadataCollection((prevCollection) => ({
        ...prevCollection,
        ...assetInfo.reduce<AssetMetadataCollection>((result, i) => {
          result[i.id] = i;
          return result;
        }, {}),
      }));
    });
  }, [lastRowInView, assetMetadataGeneratorRef]);

  const userImageAssets = React.useMemo(
    () => Object.values(assetMetadataCollection),
    [assetMetadataCollection],
  );

  return {
    userImageAssets,
    lastRowRef,
  };
}

export const UserAssetLibraryItem = React.forwardRef(
  (
    {
      imageItem,
      isIdle = true,
      onAddItem,
      ...props
    }: TooltipProps & {
      imageItem: AssetMetadata;
      isIdle?: boolean;
      onAddItem: PresetImageGridItemPropsAddItemHandler;
    },
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const editor = editorContextStore((state) => state.editor);
    const [elementRef, inView] = useInView();
    const [url, setUrl] = useState("");
    const storagePath = imageItem.storagePath;

    useEffect(() => {
      if (!editor) {
        return;
      }

      editor.assets
        .loadAsset({
          type: "image-storage",
          path: storagePath,
        })
        .then((dataUrl) => {
          if (!dataUrl) {
            return;
          }
          setUrl(dataUrl);
        });
    }, [editor, storagePath, setUrl]);

    return (
      <Tooltip
        {...props}
        triggerChildren={
          <PresetImageGridItem
            // eslint-disable-next-line react-compiler/react-compiler
            ref={mergeRefs([forwardedRef, elementRef])}
            url={inView ? url : ""}
            className={classNames(
              SecondaryButtonClassNameInactive,
              url ? "cursor-pointer" : "cursor-wait",
            )}
            onAddItem={(args) =>
              onAddItem({
                ...args,
                caption: imageItem.caption,
              })
            }
          />
        }
        contentClassName="pointer-events-none"
        contentChildren={
          <div>{url ? "Click to add this asset to the canvas." : "Loading asset ..."}</div>
        }
      />
    );
  },
);
