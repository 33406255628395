import { FirebaseBackend, getFirebaseApp } from "./firebase/firebase-backend";
import { FlairStorageManager } from "./firebase/storage/storage-manager";

//todo: ensure singleton ideally
export function createBackend() {
  return new FirebaseBackend();
}

//Ensure singleton
let storageManagerInstance: FlairStorageManager | undefined;
export function createStorageManager() {
  if (!storageManagerInstance) {
    const { firebaseFunctions } = getFirebaseApp();
    storageManagerInstance = new FlairStorageManager(firebaseFunctions);
  }
  return storageManagerInstance;
}
