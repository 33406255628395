import { getFirebaseApp } from "@/backend/firebase/firebase-backend";
import { BACKEND_API_KEY_UNLIMITED } from "@/components/constants/backend";
import {
  EditImageProcessType,
  EditImageProcessTypeName,
  EditorAssetContentType,
  SetActiveHistoryHandler,
} from "@/core/common/types";
import { MagicEraseHistory } from "@/core/controllers/history/magic-erase-history";
import { Editor } from "@/core/editor";
import { classNames } from "@/core/utils/classname-utils";
import { getDataUrlFromImageElement } from "@/core/utils/image-utils";
import { isDataURL, isValidHttpsUrl } from "@/core/utils/string-utils";
import { isStaticImageObject } from "@/core/utils/type-guards";
import { useActiveObjectPastGeneration } from "@/hooks/use-past-generation";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import {
  MagicEraseFrame,
  MagicEraseFrameImperativeHandle,
} from "components/canvas-frames/magic-erase-frame";
import {
  InputBoxClassName,
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  SecondaryButtonClassName,
  SecondaryButtonClassNameDisabled,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { Navigate } from "components/panels/panel-items/components/navigate";
import { NumberSlider } from "components/panels/panel-items/components/number-slider";
import { ActiveObjectLoadingCover } from "components/utils/active-object-loading-cover";
import { Tooltip } from "components/utils/tooltip";
import { editorContextStore } from "contexts/editor-context";
import { fabric } from "fabric";
import { Eraser, Trash, Undo } from "lucide-react";
import React from "react";
import { LeftPanelSectionContainer } from "../base";
import { ProgressBar } from "../components/progress-bar";
import { PanelSwitchRow } from "../components/switch";
import { createObjectEditImageProgressController, ObjectWithProgress } from "./edit-image-process";

const magicEraseDefaultUrl = import.meta.env.VITE_MAGIC_ERASE_DEFAULT_URL;
const magicEraseDiffusionUrl = import.meta.env.VITE_MAGIC_ERASE_DIFFUSION_URL;

const iconSize = 18;

function getMaskUrlFromCanvas(canvas: HTMLCanvasElement, width?: number, height?: number) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = width || canvas.width;
  tmpCanvas.height = height || canvas.height;
  const ctx = tmpCanvas.getContext("2d");
  if (!ctx) {
    return null;
  }
  ctx.globalCompositeOperation = "source-over";
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  ctx.globalCompositeOperation = "destination-in";
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    ctx.canvas.width,
    ctx.canvas.height,
  );
  return ctx.canvas.toDataURL("image/png");
}

function OtherProcessRunningButton({ type }: { type: EditImageProcessType | undefined }) {
  const name = type ? EditImageProcessTypeName[type].toLowerCase() : "undefined";
  return (
    <div className={classNames(PrimaryButtonClassNameDisabled, "cursor-wait")}>
      Waiting for {name} tool to finish
    </div>
  );
}

function CanvasEmptyButton() {
  return <div className={classNames(PrimaryButtonClassNameDisabled)}>Erase something first</div>;
}

function ProcessingButton() {
  return <ProgressBar progress={0.01} />;
}

function RenderButton({
  className,
  ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) {
  return (
    <button {...props} className={classNames(PrimaryButtonClassName, className ?? "")}>
      Generate
    </button>
  );
}

function MagicErasePrimaryButton({
  isProcessing,
  isOtherProcessRunning,
  isCanvasEmpty,
  editImageProcessType,
  onRenderStart,
}: {
  isProcessing: boolean;
  isOtherProcessRunning: boolean;
  isCanvasEmpty: boolean;
  editImageProcessType: EditImageProcessType | undefined;
  onRenderStart: () => void;
}) {
  return isOtherProcessRunning ? (
    <OtherProcessRunningButton type={editImageProcessType} />
  ) : isProcessing ? (
    <ProcessingButton />
  ) : isCanvasEmpty ? (
    <CanvasEmptyButton />
  ) : (
    <RenderButton onClick={onRenderStart} />
  );
}

function isImageUrl(image: string) {
  return isDataURL(image) || isValidHttpsUrl(image);
}

type MagicEraseDefaultArgs = {
  imageUrl: string;
  maskUrl: string;
};

async function callMagicEraseDefault({ imageUrl, maskUrl }: MagicEraseDefaultArgs) {
  if (!magicEraseDefaultUrl) {
    console.error("Magic erase default url is not defined");
    return null;
  }

  const response = await fetch(magicEraseDefaultUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Engine-Type": "default",
      "Ocp-Apim-Subscription-Key": BACKEND_API_KEY_UNLIMITED,
    },
    body: JSON.stringify({
      image_url: imageUrl,
      mask_url: maskUrl,
    }),
  });

  if (response.ok) {
    const result = await response.json();

    const image = result?.image;

    if (typeof image === "string" && isImageUrl(image)) {
      return image;
    }
  } else {
    console.error((await response.json())?.message);
  }

  return null;
}

type MagicEraseDiffusionArgs = {
  imageUrl: string;
  maskUrl: string;
  prompt?: string;
  denoisingStart?: number;
};

async function callMagicEraseDiffusion({
  imageUrl,
  maskUrl,
  prompt = "",
  denoisingStart,
}: MagicEraseDiffusionArgs) {
  if (!magicEraseDiffusionUrl) {
    console.error("Magic erase diffusion url is not defined");
    return null;
  }
  const { firebaseAuth } = getFirebaseApp();
  const userId = firebaseAuth.currentUser?.uid;
  if (!userId) {
    console.error("User id is not defined");
    return null;
  }
  const response = await fetch(magicEraseDiffusionUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      UserId: userId,
      // 'Engine-Type': 'fill-content',
      // 'Ocp-Apim-Subscription-Key': BACKEND_API_KEY_UNLIMITED,
    },
    body: JSON.stringify({
      image: imageUrl,
      mask: maskUrl,
      prompt,
      // 'denoising_start': denoisingStart,
    }),
  });

  if (response.ok) {
    const result = await response.json();

    const image = result?.image;

    if (typeof image === "string" && isImageUrl(image)) {
      return image;
    }
  } else {
    console.error((await response.json())?.message);
  }

  return null;
}

async function magicErase({
  imageUrl,
  maskUrl,
  fillContent = false,
  ...props
}: MagicEraseDefaultArgs &
  MagicEraseDiffusionArgs & {
    fillContent?: boolean;
  }) {
  if (fillContent) {
    return await callMagicEraseDiffusion({
      imageUrl,
      maskUrl,
      ...props,
    });
  } else {
    return await callMagicEraseDefault({
      imageUrl,
      maskUrl,
    });
  }
}

function getStaticImageElement(object: fabric.StaticImage) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const imageElement = new Image();

    imageElement.crossOrigin = "anonymous";

    imageElement.onload = () => resolve(imageElement);

    imageElement.onerror = reject;

    imageElement.src = object.getSrc();
  });
}

export async function magicEraseImageObject({
  object,
  canvas,
  onError,
  isStopped,
}: {
  object: fabric.StaticImage;
  canvas: HTMLCanvasElement;
  onError: (error: Error) => void;
  isStopped?: () => boolean;
}) {
  try {
    const {
      editor,
      backend,
      magicErasePrompt = "",
      magicEraseFillContent,
    } = editorContextStore.getState();
    if (editor && backend && object.aCoords) {
      const imageElement = await getStaticImageElement(object);

      if (!(imageElement instanceof HTMLImageElement)) {
        onError(new Error("Object is not a valid image"));
        return;
      }

      const width = imageElement.width;
      const height = imageElement.height;

      imageElement.crossOrigin = "anonymous";

      const imageUrl = await getDataUrlFromImageElement({
        from: imageElement,
        width,
        height,
      });

      if (!imageUrl) {
        onError(new Error("Cannot parse image"));
        return;
      }

      const maskUrl = getMaskUrlFromCanvas(canvas, width, height);
      if (!maskUrl) {
        onError(new Error("Cannot parse mask image from canvas"));
        return;
      }

      const outputUrl = await magicErase({
        imageUrl,
        maskUrl,
        prompt: magicErasePrompt,
        fillContent: magicEraseFillContent,
      });

      if (isStopped?.()) {
        return;
      }

      if (outputUrl) {
        object.setSrc(outputUrl);
        editor.assets
          .addAsset({
            data: outputUrl,
            contentType: EditorAssetContentType.webp,
          })
          .then((path) => {
            if (path) {
              editor.assets.setObjectAsset(object.id, {
                type: "image-storage",
                path,
                contentType: EditorAssetContentType.webp,
              });
            } else {
              onError(new Error("Cannot upload image to the storage."));
            }
          });
      } else {
        onError(new Error("Output url is invalid"));
      }
    } else {
      onError(new Error("Image url is invalid"));
    }
  } catch (error) {
    onError(error as Error);
  }
}

function MagicEraseFillContentToggle({
  labelClassName = "",
  nameClassName = "",
}: {
  labelClassName?: string;
  nameClassName?: string;
}) {
  const magicEraseFillContent = editorContextStore((state) => state.magicEraseFillContent);
  const setMagicEraseFillContent = editorContextStore((state) => state.setMagicEraseFillContent);
  return (
    <PanelSwitchRow
      id="magic-erase-fill-content-toggle"
      rootProps={{
        checked: magicEraseFillContent,
        onCheckedChange: setMagicEraseFillContent,
      }}
      labelProps={{
        className: classNames("flex flex-row items-center gap-2", labelClassName),
      }}
    >
      <span className={nameClassName}>Fill Content</span>
      <Tooltip
        triggerChildren={<QuestionMarkCircledIcon className="text-zinc-500" />}
        contentChildren={
          <div className="flex flex-col gap-2">
            <div className="text-sm font-normal">Auto-fill content inside the erased mask.</div>
          </div>
        }
      />
    </PanelSwitchRow>
  );
}

function MagicErasePromptInput() {
  const [value, setValue] = React.useState("");
  const pastGeneration = useActiveObjectPastGeneration();
  const setMagicErasePrompt = editorContextStore((state) => state.setMagicErasePrompt);
  const magicEraseFillContent = editorContextStore((state) => state.magicEraseFillContent);

  React.useEffect(() => {
    const value = pastGeneration?.prompt ?? "";
    setValue(value);
    setMagicErasePrompt(value);
  }, [pastGeneration, setMagicErasePrompt]);

  if (!magicEraseFillContent) {
    return null;
  }

  return (
    <input
      value={value}
      className={InputBoxClassName}
      onChange={(e) => setValue(e.currentTarget.value)}
      placeholder="Describe the content to fill. e.g. a flower"
      onBlur={(e) => {
        const value = e.currentTarget.value;

        setValue(value);
        setMagicErasePrompt(value);
      }}
    />
  );
}

function useMagicEraseHistoryRef({
  editor,
  magicEraseFrameRef,
}: {
  editor: Editor | null;
  magicEraseFrameRef: React.MutableRefObject<MagicEraseFrameImperativeHandle | null>;
}) {
  const magicEraseHistoryRef = React.useRef<MagicEraseHistory | undefined>(undefined);

  React.useEffect(() => {
    const history = new MagicEraseHistory({
      editor,
      getCanvasSnapshot: () => magicEraseFrameRef.current?.getCanvasSnapshot(),
      restoreCanvas: (snapshot) => magicEraseFrameRef.current?.restoreCanvas(snapshot),
    });

    magicEraseHistoryRef.current?.destroy();

    magicEraseHistoryRef.current = history;

    return () => {
      history.destroy();
    };
  }, [editor, magicEraseFrameRef]);

  React.useEffect(() => {
    editor?.emit<SetActiveHistoryHandler>("history:set", magicEraseHistoryRef);
    return () => {
      editor?.emit<SetActiveHistoryHandler>("history:set", {
        current: undefined,
      });

      editor?.history.save();
    };
  }, [editor]);

  return magicEraseHistoryRef;
}

export function MagicErase() {
  const editor = editorContextStore((state) => state.editor);
  const activeObject = editorContextStore((state) => state.activeObject);
  const setActiveInpaintMode = editorContextStore((state) => state.setActiveInpaintMode);
  const activeInpaintBrush = editorContextStore((state) => state.activeInpaintBrush);
  const setActiveInpaintBrush = editorContextStore((state) => state.setActiveInpaintBrush);
  const isInpaintPointerDown = editorContextStore((state) => state.isInpaintPointerDown);
  const inpaintBrushSize = editorContextStore((state) => state.inpaintBrushSize);
  const setInpaintBrushSize = editorContextStore((state) => state.setInpaintBrushSize);

  const [progress, setProgress] = React.useState(0);
  const [isProcessing, setIsProcessing] = React.useState(false);

  React.useEffect(() => {
    setActiveInpaintMode("magic-eraser");
    return () => {
      setActiveInpaintMode(null);
    };
  }, [setActiveInpaintMode]);

  React.useEffect(() => {
    editorContextStore.getState().setActiveInpaintBrush("paint");
    return () => {
      editorContextStore.getState().setActiveInpaintBrush(null);
    };
  }, []);

  const [isCanvasEmpty, setIsCanvasEmpty] = React.useState(true);

  const magicEraseFrameRef = React.useRef<MagicEraseFrameImperativeHandle | null>(null);
  const magicEraseHistoryRef = useMagicEraseHistoryRef({
    editor,
    magicEraseFrameRef,
  });

  const [editImageProcessType, setEditImageProcessType] = React.useState<EditImageProcessType>();
  const isOtherProcessRunning = React.useMemo(
    () => editImageProcessType != null && editImageProcessType !== "magic-erase",
    [editImageProcessType],
  );

  const editImageProgressController = (activeObject as ObjectWithProgress)
    ?.editImageProgressController;

  React.useEffect(() => {
    setEditImageProcessType(editImageProgressController?.type);
    if (editImageProgressController?.isDestroyed === false) {
      setIsProcessing(true);
      return editImageProgressController.subscribeToProgress(
        setProgress,
        () => {
          setProgress(1);
        },
        () => {
          setIsProcessing(false);
        },
      );
    } else {
      setIsProcessing(false);
    }
  }, [editImageProgressController]);

  return (
    <div className="flex flex-col">
      {isProcessing ? (
        <ActiveObjectLoadingCover />
      ) : (
        <MagicEraseFrame
          ref={magicEraseFrameRef}
          historyRef={magicEraseHistoryRef}
          onCanvasSnapshotUpdate={(canvasSnapshot) => {
            setIsCanvasEmpty((canvasSnapshot.commands?.length ?? 0) <= 0);
          }}
        />
      )}
      <Navigate />
      <LeftPanelSectionContainer>
        <div className="w-full mt-2 grid grid-cols-3 gap-2 items-center">
          <div
            className={`${
              isProcessing
                ? SecondaryButtonClassNameDisabled
                : activeInpaintBrush === "paint"
                  ? SecondaryButtonClassName
                  : SecondaryButtonClassNameInactive
            }`}
            onClick={() => {
              if (isProcessing) {
                return;
              }
              if (!isInpaintPointerDown) {
                const brush = activeInpaintBrush === "paint" ? null : "paint";
                setActiveInpaintBrush(brush);
                console.log(`Set active inpaint brush to ${brush}`);
              } else {
                console.log("Inpaint pointer is down");
              }
            }}
          >
            <Eraser size={iconSize} />
            <span className="flex-1 ml-2">Erase</span>
          </div>
          <div
            className={`${
              isProcessing
                ? SecondaryButtonClassNameDisabled
                : activeInpaintBrush === "erase"
                  ? SecondaryButtonClassName
                  : SecondaryButtonClassNameInactive
            }`}
            onClick={() => {
              if (isProcessing) {
                return;
              }
              if (!isInpaintPointerDown) {
                editor?.history.undo();
              }
            }}
          >
            <Undo size={iconSize} />
            <span className="flex-1 ml-2">Undo</span>
          </div>
          <div
            className={`${
              isProcessing ? SecondaryButtonClassNameDisabled : SecondaryButtonClassNameInactive
            }`}
            onClick={() => {
              if (isProcessing) {
                return;
              }
              magicEraseFrameRef.current?.clearCanvas();
            }}
          >
            <Trash size={iconSize} />
            <span className="flex-1 ml-2">Clear</span>
          </div>
        </div>
        <div className="w-full my-4 flex flex-col gap-4">
          <NumberSlider
            name="Brush size"
            nameClassName="min-w-[95px] text-sm font-semibold"
            value={inpaintBrushSize}
            setValue={setInpaintBrushSize}
            defaultValue={50}
            min={5}
            max={100}
            step={5}
          />
          <MagicEraseFillContentToggle
            labelClassName="min-w-[95px]"
            nameClassName="text-sm font-semibold"
          />
          <MagicErasePromptInput />
        </div>
        <MagicErasePrimaryButton
          isProcessing={isProcessing}
          isOtherProcessRunning={isOtherProcessRunning}
          isCanvasEmpty={isCanvasEmpty}
          editImageProcessType={editImageProcessType}
          onRenderStart={() => {
            const canvas = magicEraseFrameRef.current?.getCanvasElement();
            if (!canvas) {
              return;
            }
            if (!isStaticImageObject(activeObject)) {
              return;
            }

            setEditImageProcessType("magic-erase");

            setIsProcessing(true);

            createObjectEditImageProgressController({
              type: "magic-erase",
              canvas,
              object: activeObject,
            });
          }}
        />
      </LeftPanelSectionContainer>
    </div>
  );
}
