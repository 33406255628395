import { CustomModelDatasetItem } from "@/core/common/types";
import { Assets } from "@/core/controllers/assets";
import { classNames } from "@/core/utils/classname-utils";
import { debugError } from "@/core/utils/print-utilts";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import * as Dialog from "@radix-ui/react-dialog";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Cross1Icon, DotsVerticalIcon, TrashIcon } from "@radix-ui/react-icons";
import {
  DropdownClassName,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { FloatTagZIndex } from "components/constants/zIndex";
import { LoadingCardImage } from "components/panels/panel-items/components/loading-card";
import { editorContextStore } from "contexts/editor-context";
import { Upload } from "lucide-react";
import React from "react";
import styles from "./custom-model.module.css";

export function DeleteDataAlert({
  onOpenChange,
  onDelete,
  ...props
}: AlertDialogPrimitive.AlertDialogProps & {
  onDelete: () => void;
}) {
  return (
    <AlertDialogPrimitive.Root {...props} onOpenChange={onOpenChange}>
      <AlertDialogPrimitive.Portal>
        <AlertDialogPrimitive.Overlay className={styles.AlertDialogOverlay} />
        <AlertDialogPrimitive.Content
          className="absolute w-full h-full top-0 flex justify-center items-center text-zinc-300"
          onClick={() => {
            onOpenChange?.(false);
          }}
        >
          <div
            className="z-[999] flex flex-col px-4 py-4 bg-zinc-900 rounded-md border border-zinc-800 shadow-lg-center shadow-zinc-300/10"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <AlertDialogPrimitive.Title className="font-medium text-zinc-300">
              Are you sure to delete this image?
            </AlertDialogPrimitive.Title>
            <div className="flex flex-row mt-4 justify-end items-center">
              <AlertDialogPrimitive.Cancel
                className={`ml-4 px-3 py-1 rounded-md font-medium ${SecondaryButtonClassNameInactive}`}
              >
                Cancel
              </AlertDialogPrimitive.Cancel>
              <AlertDialogPrimitive.Action
                className="ml-4 px-3 py-1 rounded-md font-medium bg-red-500 hover:bg-red-600 text-red-100 focus-visible:outline-red-600"
                onClick={onDelete}
              >
                Delete
              </AlertDialogPrimitive.Action>
            </div>
          </div>
        </AlertDialogPrimitive.Content>
      </AlertDialogPrimitive.Portal>
    </AlertDialogPrimitive.Root>
  );
}

function Menu({ onDelete }: { onDelete: () => void }) {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild>
        <div className="flex-none flex justify-center items-center rounded-full bg-zinc-800 hover:text-lime-500 w-7 h-7">
          <DotsVerticalIcon />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className={classNames(DropdownClassName, "min-w-[150px] px-1 py-1")}
        style={{
          zIndex: FloatTagZIndex,
        }}
      >
        <DropdownMenu.Item
          className="w-full px-2 py-1 rounded flex flex-row items-center hover:outline-none hover:text-zinc-200 hover:bg-zinc-800"
          onClick={(e) => {
            e.preventDefault();
            onDelete();
            setOpen(false);
          }}
        >
          <TrashIcon className="text-zinc-300" />
          <span className="mx-2">Delete</span>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}

const CustomModelDatasetGridImage = React.forwardRef(
  (
    {
      src,
      prompt = "",
      onDelete,
    }: {
      src?: string;
      prompt?: string;
      onDelete: () => void;
    },
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <AspectRatio.Root
        ref={forwardedRef}
        ratio={1}
        className="group relative w-full rounded-md border border-zinc-800 overflow-hidden bg-cover bg-center cursor-pointer hover:border-lime-500 transition-colors"
        style={{
          backgroundImage: src ? `url(${src})` : undefined,
        }}
      >
        <div className="flex flex-row items-center justify-center absolute left-0 bottom-0 px-2 w-full h-[3rem] bg-gradient-to-t from-transparent group-hover:from-black/80 to-transparent transition-colors">
          <span className="flex-1 truncate text-left mx-2">{prompt}</span>
          <Menu onDelete={onDelete} />
        </div>
      </AspectRatio.Root>
    );
  },
);

export function CustomModelDatasetGridItem({
  id,
  modelId,
  item,
  onDelete,
}: {
  id: string;
  modelId: string;
  item: CustomModelDatasetItem;
  onDelete: () => void;
}) {
  const storageManager = editorContextStore((state) => state.storageManager);
  const [src, setSrc] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (!storageManager) {
      debugError(
        `Cannot load src ${item.storagePath} because the storage manager is not initialized.`,
      );
      return;
    }

    const url = item.storagePath;

    setSrc("");

    Assets.loadAssetFromPath({
      storageManager,
      path: url,
    })
      .then((imageUrl) => {
        if (!imageUrl) {
          return;
        }

        setSrc(imageUrl);
      })
      .catch(console.error);
  }, [storageManager, item]);

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <CustomModelDatasetGridImage src={src} onDelete={onDelete} />
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          className={styles.DialogOverlay}
          style={{
            zIndex: FloatTagZIndex,
          }}
        />
        <Dialog.Content
          className={classNames(
            styles.DialogContent,
            DropdownClassName,
            "rounded-xl w-[90vw] 2xl:w-[1280px] h-[90vh] px-0 py-0 flex flex-col items-stretch",
          )}
          style={{
            zIndex: FloatTagZIndex,
          }}
        >
          <div className="relative w-full p-2.5 flex flex-row items-center border-b border-zinc-800">
            <Dialog.Title className="flex flex-row items-center gap-2 text-zinc-300">
              <Upload size={16} className="text-zinc-500" />
              <span className="font-semibold">Uploaded Image</span>
            </Dialog.Title>
            <Dialog.Close className="absolute right-3 text-zinc-500 hover:text-zinc-300 cursor-pointer transition-colors py-2.5">
              <Cross1Icon width={16} height={16} />
            </Dialog.Close>
          </div>
          <div className="flex-1 w-full rounded-md overflow-hidden flex items-center justify-center">
            <LoadingCardImage src={src} className="h-full object-contain" />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
