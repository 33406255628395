import { classNames } from "@/core/utils/classname-utils";
import * as Accordion from "@radix-ui/react-accordion";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import React from "react";
import infoBoxStyles from "./info-box.module.css";

import { Lightbulb } from "lucide-react";

interface InfoBoxProps extends Omit<Accordion.AccordionSingleProps, "type"> {
  children: React.ReactNode;
  className?: string;
}

export const InfoBox: React.FC<InfoBoxProps> = ({
  children,
  className = "",
  defaultValue = "info",
  title = "Tips",
  ...props
}) => {
  return (
    <Accordion.Root
      {...props}
      className={classNames(
        "self-start inline-block bg-lime-900/20 border border-lime-900/30 text-white p-4 py-3 rounded-lg shadow-md",
        className,
      )}
      type="single" // Ensures only one item can be open at a time
      collapsible // Allows the single open item to be collapsible
      defaultValue={defaultValue}
    >
      <Accordion.Item value="info">
        <Accordion.Header className="">
          <Accordion.Trigger className="group flex flex-row items-center gap-2 text-lime-400 text-sm font-semibold w-full">
            <Lightbulb size={16} className="flex-shrink-0" />
            <span>{title}</span>
            <ChevronDownIcon
              className={classNames(
                "ml-auto text-lime-400 transition-transform duration-300 ease-[cubic-bezier(0.87,0,0.13,1)]",
                // Tailwind's group-data-[state=open]:rotate-180 to rotate the chevron
                "group-data-[state=open]:rotate-180",
              )}
              aria-hidden
            />
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content className={classNames(infoBoxStyles.content, "mt-2")}>
          <div>{children}</div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
};
