import { AnalyticsConfig } from "@/analytics/config";
import { RequireDesktop } from "@/components/auth/require-desktop";
import { Chatbot } from "@/components/chatbot";
import { DashboardCustomModels } from "@/components/custom-model/dashboard-custom-models";
import { CreateGeneralProjectDialogProvider } from "@/components/popup/message-dialog/create-general-project-dialog";
import { ManageSubscriptionDialogProvider } from "@/components/popup/message-dialog/manage-subscription-dialog";
import { DashboardVideos } from "@/components/video/dashboard-video";
import { editorContextStore } from "@/contexts/editor-context";
import { useProjectInitSceneContext } from "@/contexts/project-init-scene-context";
import { DashboardType } from "@/core/common/types";
import { useUserApiStatusUpdateEffect } from "@/hooks/use-api-update";
import { RequireAuth } from "components/auth/require-auth";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import React from "react";
import { Outlet } from "react-router-dom";
import { ManageTeamDialogProvider } from "../popup/message-dialog/manage-team-dialog";
import { DashboardManageApi } from "./api";
import { DashboardAssets } from "./dashboard-assets";
import DashboardLeftPanel from "./dashboard-left-panel";
import { DashboardNavBar } from "./dashboard-navbar";
import { DashboardProjects } from "./dashboard-projects";
import { DashboardTemplates } from "./dashboard-templates";
import { useDashboardModelNavigateEffect } from "./use-dashboard-navigate-effect";

function DashboardRightPanelInner() {
  const dashboardType = editorContextStore((state) => state.dashboardType);

  useDashboardModelNavigateEffect();

  return (
    <>
      <DashboardTemplates visible={dashboardType === "templates"} />
      <DashboardProjects visible={dashboardType === "projects"} />
      <DashboardManageApi visible={dashboardType === "manage-api"} />
      <DashboardCustomModels visible={dashboardType === "models"} />
      <DashboardVideos visible={dashboardType === "videos"} />
      <DashboardAssets visible={dashboardType === "assets"} />
    </>
  );
}

function DashboardInner() {
  const { setInitScene } = useProjectInitSceneContext();

  React.useEffect(() => {
    setInitScene(undefined);
  }, [setInitScene]);

  React.useEffect(() => {
    editorContextStore.getState().analytics.track(AnalyticsConfig.PageOpen, {
      name: "dashboard",
      url: window.location.href,
    });
  }, []);

  useUserApiStatusUpdateEffect();

  return (
    <div className="h-screen bg-zinc-900 text-zinc-100 flex flex-col">
      <DashboardNavBar />
      <div className="grow flex flex-row overflow-hidden">
        <DashboardLeftPanel />
        <ScrollAreaContainer className="flex-1 max-h-screen overflow-x-hidden">
          <DashboardRightPanelInner />
        </ScrollAreaContainer>
      </div>
      <Outlet />
    </div>
  );
}

export const Dashboard = React.memo(function Dashboard({
  dashboardType,
}: {
  dashboardType?: DashboardType;
}) {
  React.useEffect(() => {
    if (!dashboardType) {
      return;
    }

    const { setDashboardType } = editorContextStore.getState();

    setDashboardType(dashboardType);
  }, [dashboardType]);

  return (
    <RequireAuth>
      <RequireDesktop>
        <ManageSubscriptionDialogProvider>
          <ManageTeamDialogProvider>
            <CreateGeneralProjectDialogProvider>
              <Chatbot />
              <DashboardInner />
            </CreateGeneralProjectDialogProvider>
          </ManageTeamDialogProvider>
        </ManageSubscriptionDialogProvider>
      </RequireDesktop>
    </RequireAuth>
  );
});
