import { ElementsSearchManager } from "@/backend/firebase/elements-manager";
import { MAX_NUMBER_ASSET_UPLOADS } from "@/backend/firebase/firebase-backend";
import {
  InputBoxClassName,
  SecondaryButtonClassNameInactive,
} from "@/components/constants/class-names";
import { GenerateSearchBarZIndex } from "@/components/constants/zIndex";
import StickyBox from "@/components/utils/sticky-box";
import { uploadAndAddFiles } from "@/components/utils/upload";
import { editorContextStore } from "@/contexts/editor-context";
import { AppUserSubscriptionTier, UiDisplayMessageDialogEventHandler } from "@/core/common/types";
import {
  StaticImageColorElementMetadata,
  StaticImageElementColorDisplayType,
  StaticImageElementType,
  StaticImageHedElementMetadata,
} from "@/core/common/types/elements";
import { classNames } from "@/core/utils/classname-utils";
import { capitalizeFirstLetter, concatUrls } from "@/core/utils/string-utils";
import { isStaticImageObject } from "@/core/utils/type-guards";
import { getTeamOrUserQuotas } from "@/hooks/use-user-quotas";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { ChevronLeft, UploadCloud } from "lucide-react";
import React, { useEffect, useState } from "react";
import {
  AssetLibrary,
  AssetLibraryHorizontalImageGrid,
  AssetLibraryItem,
} from "./components/assets-library";
import { LeftPanelTitle } from "./components/left-panel-title";

type AssetItemMetadata = StaticImageHedElementMetadata | StaticImageColorElementMetadata;

interface AssetItem {
  id: string;
  url:
    | string
    | {
        base: string;
        bucketId: string;
        imageId: string;
      };
  previewUrl?: string;
  name?: string;
  metadata?: any; // Specify a more detailed type if possible
  tags?: string[];
}

const filteredAssetItems: AssetItems = {};

type AssetItems = Record<string, AssetItem[]>;

const defaultAccessoryImageMetadata: AssetItemMetadata = {
  imageType: StaticImageElementType.Hed,
  colorDisplayType: StaticImageElementColorDisplayType.Alpha,
};

function parseAssetItemUrl(assetItem: AssetItem) {
  if (!assetItem || !("url" in assetItem)) {
    return {
      url: "",
      "hedUrl:": "",
      previewUrl: "",
      id: "",
    };
  }
  if (typeof assetItem.url === "string") {
    const url = assetItem.url || "";
    const hedUrl = url;
    const previewUrl = assetItem.previewUrl || url;
    const id = assetItem.id;
    return {
      url,
      hedUrl,
      previewUrl,
      id,
    };
  } else {
    const { base, bucketId, imageId } = assetItem.url;
    const url = concatUrls(base, bucketId, imageId, "public");
    const hedUrl = url;
    const previewUrl = concatUrls(base, bucketId, imageId, "128");
    const id = assetItem.id;
    return {
      url,
      hedUrl,
      previewUrl,
      id,
    };
  }
}

export function getAssetLibraryItemFromAssetItem(assetItem: AssetItem): AssetLibraryItem {
  const { url, hedUrl, previewUrl, id } = parseAssetItemUrl(assetItem);

  if (!assetItem) {
    return {
      id: "",
      url: "",
      previewUrl: "",
      name: "",
      metadata: {
        imageType: StaticImageElementType.Hed,
        colorDisplayType: StaticImageElementColorDisplayType.Alpha,
      },
    };
  }

  const metadata = assetItem.metadata as any as StaticImageHedElementMetadata;

  return {
    id,
    url,
    previewUrl,
    name: assetItem.name,
    metadata: {
      // @ts-ignore
      imageType: metadata?.imageType || StaticImageElementType.Hed,
      ...metadata,
      hedUrl,
    },
  };
}

function getAssetLibraryItemCollectionFromAssetItemCollection(
  assetItems: Record<string, AssetItem[]>,
) {
  const output: Record<string, AssetLibraryItem[]> = {};
  Object.entries(assetItems).forEach(([key, items]) => {
    output[key] = items.map(getAssetLibraryItemFromAssetItem);
  });
  return output;
}

function ElementsButtonQuotaLimit({
  className = "",
  ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) {
  return (
    <button
      {...props}
      className={classNames(
        SecondaryButtonClassNameInactive,
        "box-border flex items-center justify-center w-full",
        className,
      )}
      onClick={() => {
        editorContextStore
          .getState()
          .editor?.emit<UiDisplayMessageDialogEventHandler>(
            "ui:display-message-dialog",
            "quota-subscribe",
            {
              message:
                "No uploads left. Please subscribe to upload more images, or delete some of your existing uploaded assets.",
            },
          );
      }}
    >
      <UploadCloud className="select-none" size={18} />
      <div className="ml-2 select-none">Upload Custom Prop</div>
    </button>
  );
}

function UploadAccessoriesButton({ className = "" }: { className?: string }) {
  const userQuotas = getTeamOrUserQuotas();
  const backend = editorContextStore((state) => state.backend);
  const [isUploadAvailable, setIsUploadAvailable] = useState(true);

  const tier = userQuotas?.tier || AppUserSubscriptionTier.Free;
  useEffect(() => {
    if (!backend) {
      return;
    }
    const checkUploadAvailability = async () => {
      const numUploads = await backend!.countTotalUserAssets();
      const isAvailable =
        numUploads < MAX_NUMBER_ASSET_UPLOADS || tier !== AppUserSubscriptionTier.Free;
      setIsUploadAvailable(isAvailable);
    };

    checkUploadAvailability();
  }, [backend, tier]);

  return (
    <>
      <input
        type="file"
        id="imgupload_accessories"
        style={{
          display: "none",
        }}
        onChange={(e) => {
          e.preventDefault();
          const { editor } = editorContextStore.getState();
          if (!editor) {
            return;
          }
          const files = e.target.files;
          const editorObjects = editor.objects;
          if (!files || files.length <= 0 || !editorObjects) {
            return;
          }
          uploadAndAddFiles({
            files,
            editorObjects,
            assets: editor.assets,
            userAssetInfoType: "props",
            removeBackgroundPopup: true,
          }).then((objects) => {
            objects.forEach((object) => {
              if (!object || !isStaticImageObject(object)) {
                return;
              }

              object.metadata = {
                ...defaultAccessoryImageMetadata,
                ...(object.metadata || {}),
                colorDisplayType: StaticImageElementColorDisplayType.RGB,
              };
            });
          });
        }}
      />
      <label htmlFor="imgupload_accessories">
        {isUploadAvailable ? (
          <div
            id="left-panel-assets-upload-image-button"
            className={`${SecondaryButtonClassNameInactive} flex items-center justify-center ${className}`}
          >
            <UploadCloud className="select-none" size={18} />
            <div className="ml-2 select-none">Upload Custom Prop</div>
          </div>
        ) : (
          <ElementsButtonQuotaLimit />
        )}
      </label>
    </>
  );
}

const NUM_ELEMENTS_THRESHOLD = 12;

function ElementsSearch({
  assetLibraryItemsSearchRef,
  setShowDefault,
}: {
  assetLibraryItemsSearchRef: React.Dispatch<React.SetStateAction<AssetLibraryItem[]>>;
  setShowDefault: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const backend = editorContextStore((state) => state.backend);
  const searchManager = React.useMemo(() => {
    const manager = backend?.getElementsManager() as ElementsSearchManager;
    (async () => {
      const accessories = await manager.getAllAssets();
      const assetLibraryItems: Record<string, AssetLibraryItem[]> = {};

      for (const tag of Object.keys(accessories)) {
        assetLibraryItems[tag] = accessories[tag].map(getAssetLibraryItemFromAssetItem);
      }
    })();
    return manager;
  }, [backend]);

  if (!backend) {
    return null;
  }

  return (
    <div id="elements-search-bar" className="relative w-full pb-2 bg-zinc-900">
      <MagnifyingGlassIcon className="absolute m-2.5 pointer-events-none" width={18} height={18} />
      <input
        ref={inputRef}
        className={classNames(InputBoxClassName, "w-full pl-8 pr-2 py-2")}
        placeholder="Search by keyword ('platform', 'flower', etc)"
        onChange={async (e) => {
          const value = e.currentTarget.value;
          if (!value) {
            setShowDefault(true);
            assetLibraryItemsSearchRef([]);
            return;
          }
          setShowDefault(false);
          const manager = backend?.getElementsManager().getElementsSearchCache();
          const cacheResult = await manager.search(value);
          assetLibraryItemsSearchRef(cacheResult);
          if (cacheResult.length < NUM_ELEMENTS_THRESHOLD) {
            const constQueryNumber = Math.max(
              NUM_ELEMENTS_THRESHOLD,
              Math.round(NUM_ELEMENTS_THRESHOLD * 1.5) - cacheResult.length,
            );
            const result = await searchManager.pineconeSearch(value, constQueryNumber);
            const assetLibraryItems = result.map((item) => getAssetLibraryItemFromAssetItem(item));
            const combinedResults = [...cacheResult, ...assetLibraryItems];
            const uniqueResults = Array.from(
              new Map(combinedResults.map((item) => [item.url, item])).values(),
            );
            assetLibraryItemsSearchRef(uniqueResults);
          }
        }}
      />
    </div>
  );
}

function ViewAllButton({
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div
      className={classNames(
        "select-none text-zinc-500 hover:text-lime-500 active:text-lime-600 cursor-pointer transition-colors",
        className ?? "",
      )}
      {...props}
    >
      View all
    </div>
  );
}

export function HorizontalImageGridTitle({
  title,
  onClickViewAll,
  displayViewAll = true,
  backButton,
}: {
  title?: string;
  onClickViewAll: () => void;
  displayViewAll?: boolean;
  backButton?: React.ReactNode;
}) {
  return (
    <div className="w-full flex flex-row justify-start items-center font-semibold mb-2">
      <span className="flex-1 truncate">{title}</span>
      {displayViewAll && <ViewAllButton onClick={onClickViewAll} />}
      {!displayViewAll && backButton}
    </div>
  );
}

function ElementsLibrary({ label, assets }: { label: string; assets: AssetLibraryItem[] }) {
  const editor = editorContextStore((state) => state.editor);
  const setActiveElementType = editorContextStore((state) => state.setActiveElementType);
  return (
    <div id="left-panel-assets-container" className="flex flex-col">
      <LeftPanelTitle
        className="cursor-pointer hover:text-lime-500 active:text-lime-600 transition-colors"
        onClick={() => {
          setActiveElementType("");
        }}
      >
        <ChevronLeft size={18} />
        <span id="add-assets-left-panel-title">
          <span className="ml-1 truncate">{label}</span>
        </span>
      </LeftPanelTitle>
      <AssetLibrary editor={editor} assets={assets}>
        <div className="mb-2 text-zinc-400">Select an element to add</div>
      </AssetLibrary>
    </div>
  );
}

const humanPropTags = new Set<string>([
  "Hands",
  "Hands holding Smaller Products",
  "Casual Models",
  "Male Models",
  "Female Models",
  "Casual Female Models",
  "Corporate Female models",
  "Female Skincare and Beauty Models",
]);

function shouldDisplayTag(tag: string) {
  return humanPropTags.has(tag);
}

export function Humans() {
  const editor = editorContextStore((state) => state.editor);
  const backend = editorContextStore((state) => state.backend);

  const setActiveElementType = editorContextStore((state) => state.setActiveElementType);
  const [assetLibraryItemsSearch, setAssetLibraryItemsSearch] = React.useState<AssetLibraryItem[]>(
    [],
  );
  const [showDefault, setShowDefault] = React.useState<boolean>(true);
  const [accessories, setAccessories] = React.useState<Record<string, AssetLibraryItem[]>>({});
  const activeElementType = editorContextStore((state) => state.activeElementType);
  const [tags, setTags] = React.useState<string[]>([]);

  useEffect(() => {
    if (!backend) {
      return;
    }
    const searchManager = backend.getElementsManager() as ElementsSearchManager;

    (async () => {
      const assetItems: AssetItems = await searchManager.getAllAssets();
      const filteredAssetItems: AssetItems = {};

      Object.keys(assetItems).forEach((tag) => {
        if (shouldDisplayTag(tag)) {
          filteredAssetItems[tag] = assetItems[tag];
        }
      });

      setAccessories(getAssetLibraryItemCollectionFromAssetItemCollection(filteredAssetItems));
      const orderedTags = await searchManager.getElementTagsInOrder();
      setTags(orderedTags.filter(shouldDisplayTag));
    })();
  }, [backend]);
  const accessoryItems = activeElementType ? accessories[activeElementType] : undefined;

  if (accessoryItems) {
    return (
      <ElementsLibrary label={capitalizeFirstLetter(activeElementType)} assets={accessoryItems} />
    );
  }
  return (
    <div id="left-panel-assets-container" className="flex flex-col">
      <LeftPanelTitle>
        <span id="add-assets-left-panel-title">Drag and Drop Hands and Humans</span>
      </LeftPanelTitle>
      {/* <UploadAccessoriesButton
                className="mb-2"
            /> */}
      <StickyBox
        style={{
          zIndex: GenerateSearchBarZIndex,
        }}
      >
        <ElementsSearch
          setShowDefault={setShowDefault}
          assetLibraryItemsSearchRef={setAssetLibraryItemsSearch}
        />
      </StickyBox>

      {showDefault ? (
        tags
          .filter((tag) => accessories[tag])
          .map((tag) => (
            <div key={tag} className="mb-4">
              <HorizontalImageGridTitle
                title={capitalizeFirstLetter(tag)}
                onClickViewAll={() => {
                  setActiveElementType(tag);
                }}
              />
              <AssetLibraryHorizontalImageGrid
                editor={editor}
                assets={accessories[tag]}
                onClickViewAll={() => {
                  setActiveElementType(tag);
                }}
              />
            </div>
          ))
      ) : (
        <AssetLibrary editor={editor} assets={assetLibraryItemsSearch}></AssetLibrary>
      )}
    </div>
  );
}
