import { fabric } from "fabric";
import { MAX_CANVAS_LENGTH, RENDER_CANVAS_LEGNTH } from "./common/constants";
/* eslint-disable @typescript-eslint/no-namespace */
import { FabricCanvas } from "@/core/common/interfaces";
import {
  EditorConfig,
  ExportGenerationFrameTemplateEventHandler,
  ObjectBounds2d,
} from "@/core/common/types";
import type { Editor } from "@/core/editor";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { generateUUID } from "@/core/utils/uuid-utils";
import { downloadJson } from "components/utils/data";
import { getRenderPipelineArgs, onRenderImageResultAdded } from "components/utils/render";
import { editorContextStore } from "contexts/editor-context";
import { noop } from "lodash";
import { EditorCanvasRenderMode } from "./common/types/editor-canvas-render-mode";
import {
  GenerationFrameDataOutput,
  GetDataUrlsProps,
  RenderCanvasController,
} from "./controllers/render-canvas-controller";
import { isPointInBounds } from "./utils/bbox-utils";

const imageFilterColors = new Set(["#000000", "#ffffff", "#0000ff", "#00ff00", "#00ffff"]);

export interface GenerationFrameDataProps {
  readCanvasData?: boolean;
}

class Canvas {
  private _isDestroyed = false;
  private editor: Editor;
  public container?: HTMLDivElement;
  public canvasContainer?: HTMLDivElement;
  public canvasElement?: HTMLCanvasElement;
  public canvas: FabricCanvas<fabric.Canvas>;
  public canvasId: string;

  private unsubscribeEventUpdates = noop;

  private renderCanvasController: RenderCanvasController;

  private options = {
    width: 0,
    height: 0,
  };
  private config: EditorConfig;

  private imageFilters = Array.from(imageFilterColors).reduce<
    Record<string, fabric.IBlendImageFilter>
  >((result, color) => {
    result[color] = new fabric.Image.filters.BlendColor({
      color,
      mode: "tint",
      alpha: 1.0,
    });
    return result;
  }, {});

  get blackFilter() {
    return this.imageFilters["#000000"];
  }

  get whiteFilter() {
    return this.imageFilters["#ffffff"];
  }

  get blueFilter() {
    return this.imageFilters["#0000ff"];
  }

  constructor({ id, config, editor }: { id: string; config: EditorConfig; editor: Editor }) {
    try {
      this.config = config;
      this.editor = editor;
      this.canvasId = id;

      const canvas = new fabric.Canvas(this.canvasId, {
        backgroundColor: this.config.background,
        preserveObjectStacking: true,
        fireRightClick: true,
        height: this.config.size.height,
        width: this.config.size.width,
      });
      this.canvas = canvas as FabricCanvas<fabric.Canvas>;

      this.canvas.disableEvents = function () {
        if (this.__fire === undefined) {
          this.__fire = this.fire;
          // @ts-ignore
          this.fire = function () {};
        }
      };

      this.canvas.enableEvents = function () {
        if (this.__fire !== undefined) {
          this.fire = this.__fire;
          this.__fire = undefined;
        }
      };

      this.renderCanvasController = new RenderCanvasController({
        editor: this.editor,
        config: this.config,
      });

      this.editor.on<ExportGenerationFrameTemplateEventHandler>(
        "generation-frame:export-template",
        this.downloadGenerationFrameAsTemplate,
      );

      this.startRenderLoop();
    } catch (e) {
      debugError(e);
    }
  }

  private renderLoopInternal = () => {
    try {
      if (this._isDestroyed) {
        return;
      }

      if (this.editor.state.editorCanvasRenderMode === EditorCanvasRenderMode.Loop) {
        this.canvas.renderAll();
      }

      fabric.util.requestAnimFrame(this.renderLoopInternal);
    } catch (e) {
      debugError(e);
    }
  };

  private startRenderLoop() {
    try {
      fabric.util.requestAnimFrame(this.renderLoopInternal);
    } catch (e) {
      debugError(e);
    }
  }

  public initialize = () => {
    try {
      if (!this.canvas) {
        const canvas = new fabric.Canvas(this.canvasId, {
          backgroundColor: this.config.background,
          preserveObjectStacking: true,
          fireRightClick: true,
          height: this.config.size.height,
          width: this.config.size.width,
        });
        this.canvas = canvas as FabricCanvas<fabric.Canvas>;

        this.canvas.disableEvents = function () {
          if (this.__fire === undefined) {
            this.__fire = this.fire;
            // @ts-expect-error
            this.fire = () => {};
          }
        };

        this.canvas.enableEvents = function () {
          if (this.__fire !== undefined) {
            this.fire = this.__fire;
            this.__fire = undefined;
          }
        };
      }
    } catch (e) {
      debugError(e);
    }
  };

  public destroy = () => {
    try {
      this._isDestroyed = true;
      this.canvas.dispose();
      this.editor.off<ExportGenerationFrameTemplateEventHandler>(
        "generation-frame:export-template",
        this.downloadGenerationFrameAsTemplate,
      );
      this.unsubscribeEventUpdates?.();
    } catch (e) {
      debugError(e);
    }
  };

  public resize({ width, height }: any) {
    try {
      this.canvas?.setWidth(width).setHeight(height);
      this.canvas?.renderAll();
      const diffWidth = width / 2 - this.options.width / 2;
      const diffHeight = height / 2 - this.options.height / 2;

      this.options.width = width;
      this.options.height = height;

      const deltaPoint = new fabric.Point(diffWidth, diffHeight);
      this.canvas?.relativePan(deltaPoint);
    } catch (e) {
      debugError(e);
    }
  }

  public getBoundingClientRect() {
    try {
      const canvasEl = document.getElementById("canvas");
      const position = {
        left: canvasEl?.getBoundingClientRect().left,
        top: canvasEl?.getBoundingClientRect().top,
      };
      return position;
    } catch (e) {
      debugError(e);
      return { left: 0, top: 0 };
    }
  }

  public requestRenderAll() {
    try {
      this.canvas?.requestRenderAll();
    } catch (e) {
      debugError(e);
    }
  }

  public get backgroundColor() {
    try {
      return this.canvas?.backgroundColor;
    } catch (e) {
      debugError(e);
      return this.config.background;
    }
  }

  public setBackgroundColor(color: string) {
    try {
      this.canvas?.setBackgroundColor(color, () => {
        this.canvas?.requestRenderAll();
        this.editor.emit("canvas:updated");
      });
    } catch (e) {
      debugError(e);
    }
  }

  static getCanvasSize(
    generationFrame: fabric.GenerationFrame,
    targetLength = RENDER_CANVAS_LEGNTH,
  ) {
    try {
      const { width, height } = generationFrame;
      if (!width || !height) {
        return {
          width: targetLength,
          height: targetLength,
        };
      }

      targetLength = Math.min(targetLength, MAX_CANVAS_LENGTH);

      const scale = targetLength / Math.max(width, height);
      return {
        width: scale * width,
        height: scale * height,
      };
    } catch (e) {
      debugError(e);
      return {
        width: RENDER_CANVAS_LEGNTH,
        height: RENDER_CANVAS_LEGNTH,
      };
    }
  }

  private static alwaysUseShapeControl() {
    try {
      const { generateToolReferenceImage } = editorContextStore.getState();

      return !generateToolReferenceImage;
    } catch (e) {
      debugError(e);
      return true;
    }
  }

  private getGenerationFrameArgs() {
    try {
      const generationFramesController = this.editor.generationFrames;

      const generationFrame = generationFramesController.generationFrame;

      if (!generationFrame) {
        return null;
      }

      const generationFrameBounds: ObjectBounds2d = {
        left: generationFrame.left ?? 0,
        top: generationFrame.top ?? 0,
        width: generationFrame.width ?? 0,
        height: generationFrame.height ?? 0,
      };

      const sceneObjects = generationFramesController.updateImagesIntersectingGenerationFrame();

      const alwaysUseShapeControl = Canvas.alwaysUseShapeControl();

      return {
        alwaysUseShapeControl,
        sceneObjects,
        generationFrameBounds,
      };
    } catch (e) {
      debugError(e);
      return null;
    }
  }

  public async getDataURLsFromScene(props: GetDataUrlsProps, options?: fabric.IDataURLOptions) {
    try {
      return await this.renderCanvasController.getDataURLs(props, options);
    } catch (e) {
      debugError(e);
      return {};
    }
  }

  public async getGenerationFrameDataURLs(
    props: GenerationFrameDataProps = {},
    options?: fabric.IDataURLOptions,
  ): Promise<GenerationFrameDataOutput> {
    try {
      const generationFrameArgs = this.getGenerationFrameArgs();

      if (!generationFrameArgs) {
        return {};
      }

      const { alwaysUseShapeControl, generationFrameBounds, sceneObjects } = generationFrameArgs;

      const newOutput = await this.renderCanvasController.getDataURLs(
        {
          alwaysUseShapeControl,
          generationFrameBounds,
          readCanvasData: props.readCanvasData,
          sceneObjects,
        },
        options,
      );

      return newOutput;
    } catch (e) {
      debugError(e);
      return {};
    }
  }

  private downloadGenerationFrameAsTemplate = async () => {
    try {
      const { generateToolPromptTemplate, generateToolReferenceImage } = this.editor.state;

      const { renderPipelineArgs, sceneJSON } = await getRenderPipelineArgs({
        editor: this.editor,
        readCanvasData: true,
      });

      const generationId = generateUUID();

      debugLog(`Start uploading generation frame to ${generationId}`);

      const pastGeneration = await onRenderImageResultAdded({
        outputImage: {
          id: generateUUID(),
          generationId,
          asset: {
            type: "image-url",
            path: "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
          },
        } as any as fabric.StaticImage,
        prompt: renderPipelineArgs?.prompt ?? "",
        promptTemplate: generateToolPromptTemplate,
        sceneJSON,
        referenceImage: generateToolReferenceImage,
      });

      debugLog(`Finish uploading generation frame to ${generationId}`);

      downloadJson(
        JSON.stringify({
          pastGeneration,
          sceneJSON,
        }),
        `pastgen-${generationId}.json`,
      );
    } catch (error) {
      debugError(error);
    }
  };

  get width() {
    try {
      return this.canvas.width || this.config.size.width;
    } catch (e) {
      debugError(e);
      return this.config.size.width;
    }
  }

  get height() {
    try {
      return this.canvas.height || this.config.size.height;
    } catch (e) {
      debugError(e);
      return this.config.size.height;
    }
  }

  getCenterPoint() {
    try {
      return new fabric.Point(this.width / 2, this.height / 2);
    } catch (e) {
      debugError(e);
      return new fabric.Point(this.config.size.width / 2, this.config.size.height / 2);
    }
  }

  getViewportCenter() {
    try {
      return fabric.util.transformPoint(
        this.getCenterPoint(),
        fabric.util.invertTransform(this.canvas.viewportTransform as any[]),
      );
    } catch (e) {
      debugError(e);
      return new fabric.Point(0, 0);
    }
  }

  pointInViewport(point: fabric.Point) {
    try {
      const { tl, br } = this.canvas.calcViewportBoundaries();

      return isPointInBounds(point, tl, br, false);
    } catch (e) {
      debugError(e);
      return false;
    }
  }
}
//TODO: update to ES2015+ module syntax
declare module "fabric" {
  namespace fabric {
    interface Canvas {
      __fire: any;
      enableEvents: () => void;
      disableEvents: () => void;
    }
    interface Object {
      id: string;
      name: string;
      locked: boolean;
      duration?: {
        start?: number;
        stop?: number;
      };
      _objects?: fabric.Object[];
      metadata?: Record<string, any>;
      clipPath?: undefined | null | fabric.Object;
    }
  }
}

export default Canvas;
