import { editorContextStore } from "@/contexts/editor-context";
import { Editor } from "@/core/editor";
import { debugError } from "@/core/utils/print-utilts";
import { isDataURL, isValidHttpsUrl } from "@/core/utils/string-utils";
import { preprocessImageUrl } from "@/core/utils/url-utils";
import { forwardRef, useEffect, useState, type ForwardedRef } from "react";

export const ImageComponentPrimitive = forwardRef(
  (
    {
      editor,
      style = {},
      onLoad,
      onError,
      alt,
      src,
      isImageLoaded,
      setImageLoaded,
      ...props
    }: React.ImgHTMLAttributes<HTMLImageElement> & {
      editor?: Editor | null;
      isImageLoaded: boolean;
      setImageLoaded: (value: boolean) => void;
    },
    forwardedRef?: ForwardedRef<HTMLImageElement>,
  ) => {
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
    const storageManager = editorContextStore((state) => state.storageManager);
    const [loadError, setLoadError] = useState(false);

    useEffect(() => {
      setImageLoaded(false);
      setLoadError(false);

      if (!src) {
        debugError("ImageComponentPrimitive", "no src", src);
        return;
      }
      // Check if it's a storage path
      if (editor && storageManager && !isDataURL(src) && !isValidHttpsUrl(src)) {
        // Create full storage URL
        const fullStorageUrl = storageManager.createStorageUrl(src);
        editor.assets
          .loadAsset({
            type: "image-storage",
            path: fullStorageUrl,
            saveToMemory: true,
          })
          .then((loadedUrl) => {
            if (!loadedUrl) {
              debugError("ImageComponentPrimitive", "no loadedUrl", src);
              setLoadError(true);
              return;
            }

            setImageUrl(loadedUrl);
          })
          .catch((error) => {
            debugError("ImageComponentPrimitive", "loadAsset error", error);
            setLoadError(true);
          });
      } else {
        // Direct URL or data URL
        setImageUrl(preprocessImageUrl(src));
      }
    }, [src, editor, storageManager, setImageLoaded, setLoadError, setImageUrl]);

    const handleImageLoadError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      setImageLoaded(false);
      setLoadError(true);
      debugError("ImageComponentPrimitive", "image load error", src, imageUrl);
      onError?.(e);
    };

    if (loadError) {
      return null;
    }

    return imageUrl ? (
      <img
        {...props}
        ref={forwardedRef}
        alt={alt}
        src={imageUrl}
        style={{
          ...style,
          display: isImageLoaded ? style?.display || "block" : "none",
        }}
        onLoad={(e) => {
          setImageLoaded(true);
          onLoad?.(e);
        }}
        onError={handleImageLoadError}
      />
    ) : null;
  },
);

export const ImageComponent = forwardRef(
  (
    props: React.ImgHTMLAttributes<HTMLImageElement>,
    forwardedRef: ForwardedRef<HTMLImageElement>,
  ) => {
    const [isImageLoaded, setImageLoaded] = useState(false);
    return (
      <ImageComponentPrimitive
        {...props}
        ref={forwardedRef}
        isImageLoaded={isImageLoaded}
        setImageLoaded={setImageLoaded}
      />
    );
  },
);
