import { IShortcutsManager } from "@/core/common/interfaces";
import { TryOnClothMaskType, TryOnParsedClothImageBbox } from "@/core/common/types";
import { ShortcutsUtils } from "@/core/utils/shortcuts-utils";
import { downloadImageDataUrl } from "components/utils/data";
import { displayUiMessage } from "components/utils/display-message";
import { editorContextStore } from "contexts/editor-context";
import {
  handleParsedClothImageResult,
  handleWarpParsedClothImageResult,
} from "contexts/tryon-editor-context";
import throttle from "lodash/throttle";
import {
  Bounds,
  CanvasBrushStrokeHistory,
  CanvasBrushStrokes,
  CanvasImageData,
  getAffineTransformationMatrixForBounds,
  getBoundsFromCanvasImagePosition,
  getBoundsFromXYCoordinates,
  getDefaultBounds,
  HtmlCanvasController,
  HtmlCanvasControllerProps,
  HtmlCanvasObjectData,
  isBoundsValid,
  Matrix2x3,
  mergeBounds,
  useNumUndoRedoCanvasBrushStrokesHistory,
} from "./html-canvas-controller";

export type TryOnClothCanvasBrushStrokes = CanvasBrushStrokes;

export class TryOnClothBrushStrokesHistory extends CanvasBrushStrokeHistory {}

export function useNumUndoRedoTryOnClothBrushStrokesHistory({
  history,
}: {
  history?: TryOnClothBrushStrokesHistory;
}) {
  return useNumUndoRedoCanvasBrushStrokesHistory({
    history,
  });
}

export class TryOnClothCanvasController extends HtmlCanvasController {
  public isInitialized = false;

  private clothImageData?: CanvasImageData;

  private clothMaskImageData?: CanvasImageData;

  private background: HtmlCanvasObjectData[] = [];

  private unsubscribeToContextState?: () => void;

  private maskContext?: CanvasRenderingContext2D;

  private _isCanvasMoving = false;

  private tmpPoint0 = { x: 0, y: 0 };

  historyRef: { current: TryOnClothBrushStrokesHistory };

  constructor({
    historyRef,
    ...props
  }: HtmlCanvasControllerProps & {
    historyRef: { current: TryOnClothBrushStrokesHistory };
  }) {
    super(props);

    this.historyRef = historyRef;

    this.updateBackground();
  }

  updateBackground() {
    const { boundsWidth, boundsHeight } = this;

    this.background = [
      {
        type: "grid",
        color: "#27272a",
        gridSize: 100,
        width: boundsWidth,
        height: boundsHeight,
      },
      {
        type: "hollow-rect",
        strokeStyle: "#27272a",
        lineWidth: 1,
        x: 0,
        y: 0,
        width: boundsWidth,
        height: boundsHeight,
      },
    ];
  }

  get isCanvasMoving() {
    return this._isCanvasMoving;
  }

  set isCanvasMoving(value: boolean) {
    if (value === this._isCanvasMoving) {
      return;
    }
    this._isCanvasMoving = value;
  }

  get history() {
    return this.historyRef.current;
  }

  get currentBrushStroke() {
    return this.history.current;
  }

  setMaskContext(ctx: CanvasRenderingContext2D) {
    this.maskContext = ctx;
  }

  clearImages() {
    this.clothImageData = undefined;
    this.clothMaskImageData = undefined;
    this.dirty = true;
  }

  destroy() {
    this.clearImages();
    this.unsubscribeToContextState?.();
  }

  init() {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;

    this.center();

    this.dirty = true;

    const { tryOnClothImageElement, tryOnParsedClothImageElement, tryOnParsedClothImageBbox } =
      editorContextStore.getState();

    if (tryOnClothImageElement) {
      this.clothImageData = this.getImageData(tryOnClothImageElement);
    }

    if (tryOnParsedClothImageElement && tryOnParsedClothImageBbox) {
      this.clothMaskImageData = this.getMaskData(
        tryOnParsedClothImageElement,
        tryOnParsedClothImageBbox,
      );
    }

    const unsubscribeClothMask = editorContextStore.subscribe(
      (state) => ({
        image: state.tryOnClothImageElement,
        mask: state.tryOnParsedClothImageElement,
        bbox: state.tryOnParsedClothImageBbox,
      }),
      ({ image, mask, bbox }) => {
        if (image) {
          this.clothImageData = this.getImageData(image);
        }

        if (mask && bbox) {
          this.clothMaskImageData = this.getMaskData(mask, bbox);
        }

        this.render();
      },
    );

    this.unsubscribeToContextState = () => {
      unsubscribeClothMask();
    };

    this.historyRef.current.renderBrushStrokes = this.renderBrushStrokes;
  }

  getMaskData(
    mask: HTMLImageElement,
    bbox: TryOnParsedClothImageBbox,
  ): CanvasImageData | undefined {
    const { width, height } = mask;

    if (width <= 0 || height <= 0) {
      return;
    }

    const imageData = this.clothImageData;

    if (!imageData) {
      return;
    }

    const { target } = imageData;

    let [x0, y0, x1, y1] = bbox;

    x0 *= target.width;
    x1 *= target.width;
    y0 *= target.height;
    y1 *= target.height;

    const bboxHeight = y1 - y0;

    const bboxWidth = x1 - x0;

    return {
      type: "image",
      image: mask,
      source: {
        left: 0,
        top: 0,
        width,
        height,
      },
      target: {
        left: target.left + x0,
        top: target.top + y0,
        width: bboxWidth,
        height: bboxHeight,
      },
      alpha: 1.0,
    };
  }

  renderMaskImages() {
    const { maskContext } = this;

    if (maskContext && !this.isCanvasMoving) {
      const { tryOnParsedClothImageElement } = editorContextStore.getState();

      if (this.clothMaskImageData && tryOnParsedClothImageElement) {
        HtmlCanvasController.drawObject({
          ctx: maskContext,
          object: {
            ...this.clothMaskImageData,
            image: tryOnParsedClothImageElement,
            alpha: 1.0,
          },
        });
      }

      this.history.undos.forEach((brushStroke) => {
        HtmlCanvasController.drawBrushStroke({
          ctx: maskContext,
          object: brushStroke,
        });
      });

      if (this.history.current) {
        HtmlCanvasController.drawBrushStroke({
          ctx: maskContext,
          object: this.history.current,
        });
      }
    }
  }

  renderBrushStrokes = () => {
    if (!this.maskContext) {
      return;
    }

    const ctx = this.ctx;

    if (!ctx) {
      return;
    }

    this.canvasDefault();

    if (this.maskContext) {
      this.maskContext.clearRect(
        0,
        0,
        this.maskContext.canvas.width,
        this.maskContext.canvas.height,
      );
    }

    this.apply();

    this.renderMaskImages();

    this.canvasDefault();
  };

  renderImages(context?: CanvasRenderingContext2D | null) {
    const ctx = context || this.ctx;

    if (!ctx) {
      return;
    }

    const { tryOnClothImageElement } = editorContextStore.getState();

    if (this.clothImageData && tryOnClothImageElement) {
      HtmlCanvasController.drawObject({
        ctx,
        object: {
          ...this.clothImageData,
          image: tryOnClothImageElement,
        },
      });
    }

    this.renderMaskImages();
  }

  clearCanvas() {
    if (!this.ctx) {
      return;
    }
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    if (this.maskContext) {
      this.maskContext.clearRect(
        0,
        0,
        this.maskContext.canvas.width,
        this.maskContext.canvas.height,
      );
    }
  }

  canvasDefault(): void {
    if (!this.ctx) {
      return;
    }

    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.maskContext?.setTransform(1, 0, 0, 1, 0, 0);
  }

  apply(): void {
    if (this.dirty) {
      this.update();
    }
    this.ctx?.setTransform(...this.matrix);
    this.maskContext?.setTransform(...this.matrix);
  }

  render() {
    const ctx = this.ctx;

    if (!ctx) {
      return;
    }

    this.canvasDefault();

    this.clearCanvas();

    this.apply();

    this.background.forEach((object) => {
      HtmlCanvasController.drawObject({
        ctx,
        object,
      });
    });

    this.renderImages();

    this.canvasDefault();
  }

  private getMaskColorAtPoint({
    x,
    y,
  }: {
    x: number;
    y: number;
  }): [number, number, number, number] {
    if (!this.maskContext) {
      return [0, 0, 0, 0];
    }
    const point = this.maskContext.getImageData(x, y, 1, 1).data;
    if (!point || point.length < 4) {
      return [0, 0, 0, 0];
    }
    return [point[0], point[1], point[2], point[3]];
  }

  /**
   * Get mask type at pixel point (x, y) starting at top left corner.
   * **NOTE**: Do **NOT** call this function frequently since we are deliberately not setting `willReadFrequently` to true.
   * @param point
   * @returns
   */
  getMaskTypeAtPoint(point: { x: number; y: number }): TryOnClothMaskType {
    const [r, g, b] = this.getMaskColorAtPoint(point);

    if (r === 58 && g === 129 && b === 245) {
      // left: 58, 129, 245
      return "left-sleeve";
    }

    if (r === 131 && g === 203 && b === 22) {
      // torso: 131, 203, 22
      return "torso";
    }

    if (r === 219 && g === 38 && b === 38) {
      // right: 219, 38, 38
      return "right-sleeve";
    }

    return "empty";
  }

  getLocalCoordinateFromPixelCoordinate(point: { x: number; y: number }): {
    x: number;
    y: number;
  } {
    if (!this.maskContext) {
      return { x: 0, y: 0 };
    }
    const { x, y } = this.toWorld(point, this.tmpPoint0);
    return {
      x,
      y,
    };
  }

  startBrushStroke({
    point,
    brushType,
    color,
    lineWidth,
  }: {
    point: { x: number; y: number };
    brushType: "paint" | "erase";
    color: string;
    lineWidth: number;
  }) {
    if (!this.maskContext) {
      return;
    }
    point = this.getLocalCoordinateFromPixelCoordinate(point);
    this.history.save({
      type: "brush-stroke",
      brushType,
      color,
      lineWidth: lineWidth / this.scale,
      points: [point.x, point.y],
    });

    this.emit("brush-stroke:start");
  }

  moveBrushStroke(point: { x: number; y: number }) {
    if (!this.maskContext) {
      return;
    }
    const { currentBrushStroke } = this;
    if (!currentBrushStroke) {
      return;
    }
    const brushStroke = currentBrushStroke;
    point = this.getLocalCoordinateFromPixelCoordinate(point);
    brushStroke.points.push(point.x);
    brushStroke.points.push(point.y);
  }

  endBrushStroke() {
    // this.moveBrushStroke(point);

    this.emit("brush-stroke:end");
  }

  getMaskCanvasBoundingBox() {
    const boundingBox = getDefaultBounds();
    const maskImagePosition = this.clothMaskImageData?.target;
    if (maskImagePosition) {
      const maskImageBounds = getBoundsFromCanvasImagePosition(maskImagePosition);
      mergeBounds(boundingBox, maskImageBounds, boundingBox);
    }
    this.history.undos.forEach((brushStroke) => {
      const brushStrokeBounds = getBoundsFromXYCoordinates(
        brushStroke.points,
        brushStroke.lineWidth,
      );
      if (brushStrokeBounds) {
        mergeBounds(boundingBox, brushStrokeBounds, boundingBox);
      }
    });
    if (this.history.current) {
      const brushStrokeBounds = getBoundsFromXYCoordinates(
        this.history.current.points,
        this.history.current.lineWidth,
      );
      if (brushStrokeBounds) {
        mergeBounds(boundingBox, brushStrokeBounds, boundingBox);
      }
    }
    return boundingBox;
  }

  centerCanvasToBoundingBox(boundingBox: Bounds | null | undefined, callback: () => void) {
    if (!this.ctx || !this.maskContext) {
      return;
    }
    // Check if the bounding box is correct
    if (!boundingBox || !isBoundsValid(boundingBox)) {
      return;
    }

    const { transformMatrix } = getAffineTransformationMatrixForBounds(
      boundingBox,
      this.maskContext.canvas.width,
      this.maskContext.canvas.height,
    );

    const prevMatrix: Matrix2x3 = [...this.matrix];

    this.matrix = transformMatrix;

    this.canvasDefault();

    this.clearCanvas();

    this.apply();

    this.renderImages();

    this.canvasDefault();

    callback();

    this.matrix = prevMatrix;

    this.render();
  }

  getCanvasDataUrl(
    { getImage, getMask } = {
      getImage: true,
      getMask: true,
    },
  ) {
    if (!this.maskContext || (!getImage && !getMask)) {
      return {
        imageDataUrl: undefined,
        maskDataUrl: undefined,
      };
    }
    // Center the mask image
    const maskBounds = this.getMaskCanvasBoundingBox();
    const maskDataUrl: { current?: string } = { current: undefined };
    const imageDataUrl: { current?: string } = { current: undefined };
    this.centerCanvasToBoundingBox(maskBounds, () => {
      if (getImage) {
        imageDataUrl.current = this.ctx?.canvas.toDataURL();
      }
      if (getMask) {
        maskDataUrl.current = this.maskContext?.canvas.toDataURL();
      }
    });
    return {
      imageDataUrl: imageDataUrl.current,
      maskDataUrl: maskDataUrl.current,
    };
  }

  saveCanvas() {
    const { imageDataUrl, maskDataUrl } = this.getCanvasDataUrl();

    if (imageDataUrl) {
      downloadImageDataUrl(imageDataUrl, "tryon-cloth-image");
    }

    if (maskDataUrl) {
      downloadImageDataUrl(maskDataUrl, "tryon-cloth-mask");
    }
  }

  isMaskImageValid(lengthThreshold = 0.05) {
    if (!this.maskContext) {
      return false;
    }

    if (!this.clothImageData) {
      return;
    }

    const { target } = this.clothImageData;

    const { width, height } = target;

    if (width <= 1 || height <= 1) {
      return;
    }

    const maskBounds = this.getMaskCanvasBoundingBox();

    if (!isBoundsValid(maskBounds)) {
      return false;
    }

    const maskLengthThreshold = Math.min(width, height) * lengthThreshold;

    const maskWdith = maskBounds.right - maskBounds.left;
    const maskHeight = maskBounds.bottom - maskBounds.top;

    return maskWdith > maskLengthThreshold && maskHeight > maskLengthThreshold;
  }

  parseAndWarpClothImage = throttle(() => {
    const { backend, tryOnModelId, tryOnClothImageElement, tryOnEditorState, setTryOnEditorState } =
      editorContextStore.getState();

    if (
      !backend ||
      !tryOnModelId ||
      !tryOnClothImageElement ||
      !tryOnClothImageElement.src ||
      tryOnEditorState !== "idle"
    ) {
      return Promise.resolve();
    }

    const clothImageSrc = tryOnClothImageElement.src;

    setTryOnEditorState("warping");

    // getHtmlImageElementFromUrlAsync(clothImageSrc).then(setTryOnClothImageElement);

    try {
      if (this.isMaskImageValid()) {
        const { imageDataUrl, maskDataUrl } = this.getCanvasDataUrl();

        if (imageDataUrl && maskDataUrl) {
          return backend
            ?.warpParsedClothImage({
              clothImageUrl: imageDataUrl,
              parsedClothMaskImageUrl: maskDataUrl,
              personImageId: tryOnModelId,
            })
            .then((warpedResult) => {
              if (!warpedResult) {
                return;
              }
              return handleWarpParsedClothImageResult(warpedResult);
            })
            .catch((e) => {
              console.error(e);

              displayUiMessage("Encountered error when parsing the cloth image.", "error");
            })
            .finally(() => {
              setTryOnEditorState("idle");
            });
        }
      } else {
        return backend
          .parseClothImage({
            imageUrl: clothImageSrc,
            personImageId: tryOnModelId,
          })
          .then((parseResult) => {
            if (!parseResult) {
              displayUiMessage("Cannot parse the cloth image.");

              return;
            }

            return handleParsedClothImageResult(parseResult);
          })
          .catch((e) => {
            console.error(e);

            displayUiMessage("Encountered error when parsing the cloth image.", "error");
          })
          .finally(() => {
            setTryOnEditorState("idle");
          });
      }
    } catch (error) {
      console.error(error);
      setTryOnEditorState("idle");
    }

    return Promise.resolve();
  }, 1000);

  resetHistory() {
    this.history.reset();
  }

  handleClothImageUpdate() {
    const {
      tryOnParsedClothUpdated,
      setTryOnParsedClothImageBbox,
      setTryOnParsedClothImageElement,
      setTryOnWarpedClothImageElement,
      setTryOnWarpedHumanMaskImageElement,
    } = editorContextStore.getState();

    if (!tryOnParsedClothUpdated) {
      setTryOnParsedClothImageBbox(undefined);
      setTryOnParsedClothImageElement(undefined);
    }

    setTryOnWarpedClothImageElement(undefined);

    setTryOnWarpedHumanMaskImageElement(undefined);

    this.clothMaskImageData = undefined;

    this.resetHistory();
  }

  isUndo(event: KeyboardEvent) {
    return ShortcutsUtils.isCtrlZ(event);
  }

  isRedo(event: KeyboardEvent) {
    return ShortcutsUtils.isCtrlShiftZ(event) || ShortcutsUtils.isCtrlY(event);
  }

  isSave(event: KeyboardEvent) {
    return ShortcutsUtils.isCtrlS(event);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    let isHandled = false;
    if (this.isUndo(event)) {
      this.historyRef.current?.undo();
      isHandled = true;
    } else if (this.isRedo(event)) {
      this.historyRef.current?.redo();
      isHandled = true;
    } else if (this.isSave(event)) {
      this.saveCanvas();
      isHandled = true;
    }
    return {
      isHandled,
    };
  };
}

export class TryOnClothCanvasShortcutsManager implements IShortcutsManager {
  private controllerRef: { current?: TryOnClothCanvasController };

  private historyRef: { current?: TryOnClothBrushStrokesHistory };

  constructor({
    historyRef,
    controllerRef,
  }: {
    controllerRef: { current?: TryOnClothCanvasController };
    historyRef: { current?: TryOnClothBrushStrokesHistory };
  }) {
    this.historyRef = historyRef;
    this.controllerRef = controllerRef;
  }

  isUndo(event: KeyboardEvent) {
    return ShortcutsUtils.isCtrlZ(event);
  }

  isRedo(event: KeyboardEvent) {
    return ShortcutsUtils.isCtrlShiftZ(event) || ShortcutsUtils.isCtrlY(event);
  }

  isSave(event: KeyboardEvent) {
    return ShortcutsUtils.isCtrlS(event);
  }

  handleKeyDown(event: KeyboardEvent) {
    let isHandled = false;
    if (this.isUndo(event)) {
      this.historyRef.current?.undo();
      isHandled = true;
    } else if (this.isRedo(event)) {
      this.historyRef.current?.redo();
      isHandled = true;
    } else if (this.isSave(event)) {
      this.controllerRef.current?.saveCanvas();
      isHandled = true;
    }
    return {
      isHandled,
    };
  }
}
