import { getTeamQuotasFromContext } from "@/core/utils/team-quota-utils";
import { editorContextStore, IEditorContext } from "contexts/editor-context";

export function getUserQuotasFromEditorContext(editorContextStatee: IEditorContext) {
  const { userQuotas } = editorContextStatee;

  return getTeamQuotasFromContext(editorContextStatee) || userQuotas;
}

export function getTeamOrUserQuotas() {
  return getUserQuotasFromEditorContext(editorContextStore.getState());
}
